import React from 'react';
import BreadcrumbBar from '../components/breadcrumb/breadcrumb';
import NavigatorBar from '../components/navigator/navigator';
import ShortcutAccess from '../components/shortcutAccess/shortcutAccess';

import './defaultLayout.scss'

function DefaultLayout({ children }: { children: React.ReactNode }) {
    return (
        <div>
            <div className='layout__right__top'>
                {/* 悬浮 */}
                <div className='layout__right__breadcrumb'>
                    {/* 面包屑 */}
                    {/* <BreadcrumbBar></BreadcrumbBar> */}
                    <h3>思博达税务申报平台</h3>

                    <ShortcutAccess></ShortcutAccess>
                </div>
            </div>
            <div className='default-layout'>
                {/* 左侧界面 */}
                <div className='layout__left'>
                    {/* 导航栏 */}
                    <NavigatorBar></NavigatorBar>
                </div>
                {/* 右侧界面 */}
                <div className='layout__right'>

                    {/* 内容界面 */}
                    <div className='layout__right__children'>{children}</div>
                </div>
            </div>
        </div>
    );
}

export default DefaultLayout;