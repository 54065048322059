import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

// antd
import { Calendar, Collapse, Divider, Card, Skeleton, Button, Modal, Result, Row, Statistic, Col, Select, DatePicker } from 'antd';

// apis
import { useGetCompanyTaxRegisterListQuery, useGetHomeStatisticsMutation, useLazyGetCompanyTaxRegisterConfigDetailQuery } from '../../store/apis/tax';

// style
import './home.scss';
import { setGlobalParams } from '../../store/utils/tax';
import { useAppDispatch } from '../../store/hooks';
import { rootStore } from '../../store/store';
import { TaxCompanyRegisterConfigInfoOption } from '../../interfaces/tax/tax';


const { Option } = Select;

/**
 * 主页
 */
function Home() {
    const dispatch = useAppDispatch();

    // 搜索表单
    const [searchForm, setSearchForm] = useState<{ period?: string, djxhid?: string, bmbh?: number }>({
        period: rootStore.getState().tax.period,
    });

    // 登记序号
    const registerListResult = useGetCompanyTaxRegisterListQuery(undefined, { refetchOnMountOrArgChange: 60 });
    const handleDjxhidChange = (value: string) => {
        console.log(`selected ${value}`);
        // 缓存
        setGlobalParams({ dispatch, djxhid: value });

        // 部门列表
        departmentTrigger({ djxhid: value });

        let form = {
            ...searchForm,
            djxhid: value
        }
        setSearchForm(form);
        homeDataTrigger(form);
    }
    // 依赖登记序号初始化列表
    useEffect(() => {
        if (registerListResult.isSuccess && !(searchForm.djxhid))
            if (registerListResult.data.data.list.length > 0) {
                let djxhid = rootStore.getState().tax.djxhid
                    || registerListResult.data.data.list[0].djxhid;
                handleDjxhidChange(djxhid);
            }
    }, [registerListResult]);

    // 部门列表（筛选）
    const [departmentTrigger, departmentResult] = useLazyGetCompanyTaxRegisterConfigDetailQuery({ refetchOnReconnect: true });
    const [departmentList, setDepartmentList] = useState<TaxCompanyRegisterConfigInfoOption[]>([]);
    useEffect(() => {
        if (departmentResult.isSuccess) {
            setDepartmentList(departmentResult.data!.data.detail.options);
            if (departmentResult.data!.data.detail.options.length > 0) {
                let form = {
                    ...searchForm,
                    bmbh: departmentResult.data!.data.detail.options[0].bmbh
                }
                setSearchForm(form);
                homeDataTrigger(form);
            }
        }
    }, [departmentResult]);
    // 事件
    const handleDepartmentChange = (value: number) => {
        console.log(`selected ${value}`);
        let form = {
            ...searchForm,
            bmbh: value
        }
        setSearchForm(form);
        homeDataTrigger(form);
    };

    // 税务所属期筛选
    const handleTaxPeriodChange = (value: dayjs.Dayjs | null, dateString: string) => {
        console.log(`date: ${value}`);

        // 缓存
        setGlobalParams({ dispatch, period: value?.format('YYYY-MM') });

        let form = {
            ...searchForm,
            period: value!.format('YYYY-MM')
        }
        setSearchForm(form)
        homeDataTrigger(form);
    }

    // 数据
    const [homeDataTrigger, homeDataResult] = useGetHomeStatisticsMutation();

    return (
        <div className="home">
            <div className='home__right'>
                <div className='home__right__search'>
                    <Select
                        className="search__item"
                        placeholder="登记序号"
                        value={searchForm.djxhid}
                        onChange={handleDjxhidChange}
                        optionLabelProp="label"
                    >
                        {
                            registerListResult.data?.data.list.map(item => (
                                <Option value={item.djxhid} label={item.djxhid}>
                                    <div>{item.djxhid}</div>
                                    <div>{item.qymc}（{item.province_city_text}）</div>
                                </Option>
                            ))
                        }
                    </Select>
                    <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="选择部门筛选"
                        value={searchForm.bmbh}
                        onChange={handleDepartmentChange}
                    >
                        {departmentList.map(department => (
                            <Option key={department.bmbh} value={department.bmbh}>{department.bmmc}</Option>
                        ))}
                    </Select>
                    <DatePicker
                        className="search__item"
                        value={searchForm.period ? dayjs(searchForm.period) : null}
                        onChange={handleTaxPeriodChange}
                        placeholder='税务所属期筛选'
                        picker="month" />
                </div>
                <div className='home__right__shortcut'>
                    <div className='home__right__shortcut__title'>数据统计</div>
                    {
                        homeDataResult.isSuccess
                            ? <Row gutter={20}>
                                <Col span={8}>
                                    <Statistic title="采集成功人员" value={homeDataResult.data.data.staff_gather_success_count} />
                                </Col>
                                <Col span={8}>
                                    <Statistic title="未采集人员" value={homeDataResult.data.data.staff_un_gather_success_count} />
                                </Col>
                                <Col span={8}>
                                    <Statistic title="申报次数" value={homeDataResult.data.data.report_count} />
                                </Col>
                            </Row>
                            : <Skeleton active />
                    }
                </div>
            </div>
        </div>
    );
}

export default Home;
