type codeTypeItems = 10 | 20 | 30 | 40 | 50 | 60

// 10 注册 20 登录 30 修改密码 40 绑定 50 转移管理员 60 验证
export const codeType: {
    register: codeTypeItems,
    login: codeTypeItems,
    password: codeTypeItems,
    bind: codeTypeItems,
    transfer: codeTypeItems,
    changeMobile: codeTypeItems
} = {
    register: 10, // 注册
    login: 20, // 登录
    password: 30, // 修改密码
    bind: 40, // 绑定
    transfer: 50, // 转移管理员
    changeMobile: 60 // 验证
};

// 获取验证码表单
export interface LoginCodeForm {
    mobile: string,
    type: codeTypeItems,
};