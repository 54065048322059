import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'ahooks';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

// apis
import { useDownloadSalaryBatchTemplateMutation, useExportTaxBatchListMutation, useGetCompanyTaxRegisterListQuery, useGetTaxBatchListMutation, useLazyGetCompanyTaxRegisterConfigDetailQuery, useMergeTaxBatchMutation, useResetTaxBatchMutation, useUploadSalaryBatchDataMutation } from '../../../../store/apis/tax';

// interface
import { TaxCompanyBatchListData, TaxCompanyBatchSearchForm, TaxCompanyRegisterConfigInfoOption } from '../../../../interfaces/tax/tax';

// antd
import { Table, Dropdown, Menu, Button, Input, message, Modal, DatePicker, Form, Select } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { RangePickerProps } from 'antd/es/date-picker';
import { setGlobalParams } from '../../../../store/utils/tax';
import { rootStore } from '../../../../store/store';
import { useAppDispatch } from '../../../../store/hooks';
import { useUploadFileMutation } from '../../../../store/apis/common';
import React from 'react';

const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;

function TaxBatchListPage() {
    const dispatch = useAppDispatch();

    // 路由
    const navigate = useNavigate();

    // 请求hook
    const [listTrigger, listResult] = useGetTaxBatchListMutation();

    // 搜索表单
    const [searchForm, setSearchForm] = useState<TaxCompanyBatchSearchForm>({
        page: 1,
        size: 10,
        period: rootStore.getState().tax.period,
    });
    const searchFormRef = useRef<TaxCompanyBatchSearchForm>(searchForm);
    useEffect(() => { searchFormRef.current = searchForm; }, [searchForm]);

    // 数据填充 listResult
    useEffect(() => {
        if (listResult.isSuccess) {
            console.log('不变的数据', searchFormRef.current);

            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== listResult.data.data.total) {
                    newListData = new Array(listResult.data.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((searchFormRef.current.page! - 1) * searchFormRef.current.size!, listResult.data.data.list.length, ...listResult.data.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        }
    }, [listResult]);

    // 登记序号
    const registerListResult = useGetCompanyTaxRegisterListQuery(undefined, { refetchOnMountOrArgChange: 60 });
    const handleDjxhidChange = (value: string) => {
        console.log(`selected ${value}`);
        // 缓存
        setGlobalParams({ dispatch, djxhid: value });

        // 部门列表
        departmentTrigger({ djxhid: value });

        let form = {
            ...searchFormRef.current,
            djxhid: value
        }
        setSearchForm(form);
        listTrigger(form);
    }
    // 依赖登记序号初始化列表
    useEffect(() => {
        if (registerListResult.isSuccess && !(searchFormRef.current.djxhid))
            if (registerListResult.data.data.list.length > 0) {
                let djxhid = rootStore.getState().tax.djxhid
                    || registerListResult.data.data.list[0].djxhid;
                handleDjxhidChange(djxhid);
            }
    }, [listTrigger, registerListResult]);

    // 部门列表（筛选）
    const [departmentTrigger, departmentResult] = useLazyGetCompanyTaxRegisterConfigDetailQuery({ refetchOnReconnect: true });
    const [departmentList, setDepartmentList] = useState<TaxCompanyRegisterConfigInfoOption[]>([]);
    useEffect(() => {
        if (departmentResult.isSuccess) {
            setDepartmentList(departmentResult.data!.data.detail.options);
            if (departmentResult.data!.data.detail.options.length > 0) {
                let form = {
                    ...searchFormRef.current,
                    bmbh: departmentResult.data!.data.detail.options[0].bmbh
                }
                setSearchForm(form);
                listTrigger(form);
            }
        }
    }, [departmentResult]);
    // 事件
    const handleDepartmentChange = (value: number) => {
        console.log(`selected ${value}`);
        let form = {
            ...searchFormRef.current,
            bmbh: value
        }
        setSearchForm(form);
        listTrigger(form);
    };


    // 分页
    const [listData, setListData] = useState<TaxCompanyBatchListData[]>([])
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        setSearchForm((form) => {
            let newForm = {
                ...form,
                page,
                size: pageSize,
            };
            listTrigger(newForm);
            return newForm;
        })
    };

    // 薪酬所属期筛选
    const handleTaxPeriodChange = (value: dayjs.Dayjs | null, dateString: string) => {
        console.log(`date: ${value}`);
        let form = {
            ...searchFormRef.current,
            period: value!.format('YYYY-MM')
        }
        setSearchForm(form)
        listTrigger(form);
    }

    // 合并批次
    const [mergeForm] = Form.useForm<{ month: string }>();
    const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
    const [mergeTrigger, mergeResult] = useMergeTaxBatchMutation();
    useEffect(() => {
        if (mergeResult.isSuccess) {
            message.success(mergeResult.data.msg);
            // 跳转方法
            let nav = () => navigate(`/tax_report/report?period=${mergeResult.originalArgs?.period}`);

            if (mergeResult.data.data.error_list && mergeResult.data.data.error_list.length > 0) {
                confirm({
                    width: '50%',
                    title: '合并失败数据',
                    icon: <ExclamationCircleOutlined />,
                    content: <>{mergeResult.data.data.error_list.map(error => <div>{error}</div>)}</>,
                    cancelText: "确定",
                    okText: "跳转到税务报送",
                    onOk: nav
                });
            } else {
                nav();
            }
            listTrigger(searchFormRef.current);
        }
    }, [mergeResult, listTrigger]);
    const handleMergeOk = () => {
        mergeForm.validateFields().then(_ => {
            let values = mergeForm.getFieldsValue(true);
            let newForm = {
                djxhid: searchForm.djxhid!,
                period: searchForm.period!,
                bmbh: searchForm.bmbh
            }
            confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: `确定进行税务所属期 ${searchForm.period} 的合并吗`,
                onOk() {
                    return new Promise((resolve, reject) => {
                        mergeTrigger(newForm).then(resolve).catch(reject);
                    }).catch(() => console.log('Oops errors!')).finally(() => mergeForm.resetFields());
                },
            });
        })
    };
    const handleMergeCancel = () => {
        mergeForm.resetFields();
        setIsMergeModalOpen(false);
    };
    const toMerge = () => {
        setIsMergeModalOpen(true);
    }

    // 重置所属期
    const [resetTrigger, resetResult] = useResetTaxBatchMutation();
    useEffect(() => {
        if (resetResult.isSuccess) {
            message.success(resetResult.data.msg);
            if (resetResult.data.data.error_list && resetResult.data.data.error_list.length > 0) {
                confirm({
                    width: '50%',
                    title: '重置失败数据',
                    icon: <ExclamationCircleOutlined />,
                    content: <>{resetResult.data.data.error_list.map(error => <div>{error}</div>)}</>,
                    okCancel: false
                });
            }
            listTrigger(searchFormRef.current);
        }
    }, [resetResult, listTrigger]);
    const [form] = Form.useForm<{ period: string, new_period: string, month: string }>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
        form.validateFields().then(_ => {
            let values = form.getFieldsValue(true);
            let newForm = {
                ...values,
                month: (values.month as dayjs.Dayjs).format('YYYY-MM'),
                new_period: (values.new_period as dayjs.Dayjs).format('YYYY-MM')
            }
            confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: (
                    <div>
                        <p>确定将税务所属期 {newForm.period} 重置为 {newForm.new_period} 吗？</p>
                        <p>注意：所属期重置后，需要稍等5分钟左右，再进入对应所属期月份进行操作！谢谢！</p>
                    </div>
                ),
                onOk() {
                    setIsModalOpen(false);
                    return new Promise((resolve, reject) => {
                        resetTrigger(newForm).then(resolve).catch(reject);
                    }).catch(() => console.log('Oops errors!')).finally(() => form.resetFields());
                },
            });

        })
    };
    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
    };
    const toReset = () => {
        form.setFieldValue('period', searchFormRef.current.period);
        setIsModalOpen(true);
    }

    // 导出数据
    const [exportTrigger, exportResult] = useExportTaxBatchListMutation();
    useEffect(() => {
        if (exportResult.isSuccess) {
            window.open(exportResult.data.data.full_link, '_blank');
        }
    }, [exportResult]);
    const exportData = () => {
        exportTrigger(searchFormRef.current);
    };

    // 不可选择当前月份之后的月份
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current > dayjs().subtract(1, 'day').endOf('day');
    };

    // 下载模板
    const [templateTrigger, templateResult] = useDownloadSalaryBatchTemplateMutation();
    useEffect(() => {
        if (templateResult.isSuccess) {
            window.open(templateResult.data.data.full_link, '_blank');
        }
    }, [templateResult]);
    const download = () => {
        templateTrigger();
    };

    // 导入薪酬
    const [importTrigger, importResult] = useUploadSalaryBatchDataMutation();
    useEffect(() => {
        if (importResult.isSuccess) {
            if (importResult.data.data.fail) {
                confirm({
                    title: '导入申报工资失败',
                    icon: <ExclamationCircleOutlined />,
                    content: '是否下载失败结果文档',
                    onOk() {
                        window.open(importResult.data.data.full_link, '_blank');
                    },
                });
            } else {
                message.success(importResult.data.msg);
            }
            listTrigger(searchFormRef.current);
        }
    }, [importResult]);

    // 上传点击框ref
    const inputRef = React.createRef<HTMLInputElement>();
    // 上传文件
    const [uploadTrigger, uploadResult] = useUploadFileMutation();
    useEffect(() => {
        if (uploadResult.isSuccess) {
            // 获取文件id传递给导入接口
            importTrigger({
                file_id: uploadResult.data.data.file_id,
                djxhid: searchForm.djxhid!,
                period: searchForm.period!,
                bmbh: searchForm.bmbh
            });
        }
    }, [uploadResult, importTrigger]);
    const upload = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        // 上传到服务器
        uploadTrigger({ file: event.target.files![0], type: 'salary' });
    };

    return (
        <div className='address menu inner-page'>
            <div className='menu__title'>薪酬批次</div>

            <div className='search'>
                <Select
                    className="search__item"
                    placeholder="登记序号"
                    value={searchForm.djxhid}
                    onChange={handleDjxhidChange}
                    optionLabelProp="label"
                >
                    {
                        registerListResult.data?.data.list.map(item => (
                            <Option value={item.djxhid} label={item.djxhid}>
                                <div>{item.djxhid}</div>
                                <div>{item.qymc}（{item.province_city_text}）</div>
                            </Option>
                        ))
                    }
                </Select>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="选择部门筛选"
                    value={searchForm.bmbh}
                    onChange={handleDepartmentChange}
                >
                    {departmentList.map(department => (
                        <Option key={department.bmbh} value={department.bmbh}>{department.bmmc}</Option>
                    ))}
                </Select>
                <DatePicker
                    className="search__item"
                    value={searchForm.period ? dayjs(searchForm.period) : null}
                    onChange={handleTaxPeriodChange}
                    placeholder='税务所属期筛选'
                    picker="month" />

                <Dropdown.Button
                    trigger={['click', 'hover']}
                    className="search__last-button"
                    type="primary"
                    loading={
                        mergeResult.isLoading
                    }
                    overlay={
                        <Menu
                            items={[
                                {
                                    key: 'download',
                                    label: (
                                        <div>下载模板</div>
                                    ),
                                    onClick: () => download(),
                                },
                                {
                                    key: 'import',
                                    label: (
                                        <div>导入薪酬批次</div>
                                    ),
                                    onClick: () => {
                                        if (inputRef.current) {
                                            inputRef.current.value = '';
                                            inputRef.current.click();
                                        }
                                    },
                                },
                                {
                                    key: 'merge',
                                    label: (
                                        <div>批次合并</div>
                                    ),
                                    onClick: () => toMerge(),
                                },
                                // {
                                //     key: 'reset',
                                //     label: (
                                //         <div>重置所属期</div>
                                //     ),
                                //     onClick: () => toReset(),
                                // },
                                // {
                                //     key: 'export',
                                //     label: (
                                //         <div>导出当前列表</div>
                                //     ),
                                //     onClick: () => exportData(),
                                // },
                            ]}
                        />
                    }>
                    更多操作
                </Dropdown.Button>
            </div>
            <Table
                dataSource={listData}
                loading={listResult.isLoading}
                rowKey={(record: TaxCompanyBatchListData) => record?.id}
                pagination={{
                    position: ['bottomRight'],
                    size: "small",
                    total: listResult.data?.data.total ?? 0,
                    showTotal: total => `共 ${total} 项`,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    onChange: onPageChange
                }}
                footer={() => listResult.data?.data.tip}
            >
                <Column title="姓名" dataIndex="xm" key="name" />
                <Column title="薪资" dataIndex="sre" key="sre" />
                <Column title="税务" dataIndex="tax" key="tax" />
                {/* <Column title="薪酬所属期" dataIndex="month" key="month" /> */}
                <Column title="税务所属期" dataIndex="period" key="period" />
            </Table>

            <Modal title="选择新的税务所属期" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form}>
                    <Form.Item
                        label="薪酬所属期"
                        name="month"
                        rules={[{ required: true, message: '请选择薪酬所属期' }]}
                    >
                        <DatePicker
                            style={{ 'width': '100%' }}
                            format={'YYYY-MM'}
                            picker="month"
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                    <Form.Item
                        label="税务所属期"
                        name="new_period"
                        rules={[{ required: true, message: '请选择新的税务所属期' }]}
                    >
                        <DatePicker style={{ 'width': '100%' }} format={'YYYY-MM'} picker="month" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="批次合并" open={isMergeModalOpen} onOk={handleMergeOk} onCancel={handleMergeCancel}>
                <Form form={mergeForm}>
                    <Form.Item
                        label="薪酬所属期"
                        name="month"
                        rules={[{ required: true, message: '请选择薪酬所属期' }]}
                    >
                        <DatePicker
                            style={{ 'width': '100%' }}
                            format={'YYYY-MM'}
                            picker="month"
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            {/* 导入上传input */}
            {
                <input
                    ref={inputRef}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={upload}
                />
            }
        </div>
    );
}

export default TaxBatchListPage;