import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

// apis
import {
    useCancelPaymentVoucherMutation,
    useDownloadProofOnPaymentMutation,
    useDownloadVoucherMutation,
    useGetCompanyTaxRegisterListQuery,
    useLazyGetTaxPaymentDetailQuery,
    useGetTaxPaymentListMutation,
    usePayTaxPaymentMutation,
    useQueryPaymentVoucherResultMutation,
    useQueryPayTaxPaymentFeedbackMutation,
    useQueryPayTaxPaymentMutation,
    useQueryPayTaxPaymentResultMutation,
    useLazyGetCompanyTaxRegisterConfigDetailQuery,
} from '../../../store/apis/tax';

// interface
import { TaxCompanyPaymentForm, TaxCompanyPaymentListData, TaxCompanyPaymentSearchForm, TaxCompanyRegisterConfigInfoOption } from '../../../interfaces/tax/tax';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, message, Modal, DatePicker, Form, Spin, Descriptions } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { rootStore } from '../../../store/store';
import { setGlobalParams } from '../../../store/utils/tax';
import { useAppDispatch } from '../../../store/hooks';


const { Column } = Table;
const { Option } = Select;
const { confirm } = Modal;

const payTypeMap: Record<number, string> = { 1: '三方协议缴款', 2: '银联缴款', 3: '缴款凭证' };
const payButtonMap: Record<number, string> = { 1: '在线缴费', 2: '银联缴费', 3: '下载凭证' };

function TaxPaymentListPage() {
    const dispatch = useAppDispatch();

    // 获取参数并初始化
    const [search, setSearch] = useSearchParams();
    useEffect(() => {
        setPayType(search.get('type') ? Number(search.get('type')) : 1);
    }, [search]);
    const [payType, setPayType] = useState(search.get('type') ? Number(search.get('type')) : 1);
    useEffect(() => {
        let form = {
            ...searchFormRef.current,
            pay_type: payType,
        }
        setSearchForm(form);
        if (form.djxhid) {
            listTrigger(form);
        }
    }, [payType]);

    // 请求hook
    const [listTrigger, listResult] = useGetTaxPaymentListMutation();

    // 搜索表单
    const [searchForm, setSearchForm] = useState<TaxCompanyPaymentSearchForm>({
        page: 1,
        size: 10,
        period: rootStore.getState().tax.period,
    });
    const searchFormRef = useRef<TaxCompanyPaymentSearchForm>(searchForm);
    useEffect(() => { searchFormRef.current = searchForm; }, [searchForm]);

    // 数据填充 listResult
    useEffect(() => {
        if (listResult.isSuccess) {
            console.log('不变的数据', searchFormRef.current);

            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== listResult.data.data.total) {
                    newListData = new Array(listResult.data.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((searchFormRef.current.page! - 1) * searchFormRef.current.size!, listResult.data.data.list.length, ...listResult.data.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        }
    }, [listResult]);

    // 登记序号
    const registerListResult = useGetCompanyTaxRegisterListQuery(undefined, { refetchOnMountOrArgChange: 60 });
    const handleDjxhidChange = (value: string) => {
        console.log(`selected ${value}`);
        // 缓存
        setGlobalParams({ dispatch, djxhid: value });

        // 部门列表
        departmentTrigger({ djxhid: value });

        let form = {
            ...searchFormRef.current,
            pay_type: payType,
            djxhid: value
        }
        setSearchForm(form);
        listTrigger(form);
    }
    // 依赖登记序号初始化列表
    useEffect(() => {
        if (registerListResult.isSuccess && !(searchFormRef.current.djxhid))
            if (registerListResult.data.data.list.length > 0) {
                let djxhid = rootStore.getState().tax.djxhid
                    || registerListResult.data.data.list[0].djxhid;
                handleDjxhidChange(djxhid);
            }
    }, [listTrigger, registerListResult]);

    // 部门列表（筛选）
    const [departmentTrigger, departmentResult] = useLazyGetCompanyTaxRegisterConfigDetailQuery({ refetchOnReconnect: true });
    const [departmentList, setDepartmentList] = useState<TaxCompanyRegisterConfigInfoOption[]>([]);
    useEffect(() => {
        if (departmentResult.isSuccess) {
            setDepartmentList(departmentResult.data!.data.detail.options);
            if (departmentResult.data!.data.detail.options.length > 0) {
                let form = {
                    ...searchFormRef.current,
                    bmbh: departmentResult.data!.data.detail.options[0].bmbh
                }
                setSearchForm(form);
                listTrigger(form);
            }
        }
    }, [departmentResult]);
    // 事件
    const handleDepartmentChange = (value: number) => {
        console.log(`selected ${value}`);
        let form = {
            ...searchFormRef.current,
            bmbh: value
        }
        setSearchForm(form);
        listTrigger(form);
    };

    // 分页
    const [listData, setListData] = useState<TaxCompanyPaymentListData[]>([])
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        setSearchForm((form) => {
            let newForm = {
                ...form,
                page,
                size: pageSize,
            };
            listTrigger(newForm);
            return newForm;
        })
    };


    // 薪酬所属期筛选
    const handleTaxPeriodChange = (value: dayjs.Dayjs | null, dateString: string) => {
        console.log(`date: ${value}`);

        // 缓存
        setGlobalParams({ dispatch, period: value?.format('YYYY-MM') });

        let form = {
            ...searchFormRef.current,
            period: value!.format('YYYY-MM')
        }
        setSearchForm(form)
        listTrigger(form);
    }

    // 列表数据
    let [list, setList] = useState<TaxCompanyPaymentListData[]>([]);
    useEffect(() => {
        if (listResult.isSuccess) {
            console.log('请求结果：', listResult);
            setList(listResult.data!.data.list);
        }
    }, [listResult]);

    // 详情
    const [detailTrigger, detailResult] = useLazyGetTaxPaymentDetailQuery({ refetchOnReconnect: true });
    const toDetail = (record: TaxCompanyPaymentListData) => {
        detailTrigger({ period: record.period, djxhid: record.djxhid })
            .then(res => {
                if (res.isSuccess) {
                    let data = res.data.data.detail;
                    confirm({
                        title: '缴费信息',
                        icon: null,
                        content: (
                            <Descriptions bordered column={1}>
                                <Descriptions.Item label="企业名称">{data.company_name}</Descriptions.Item>
                                <Descriptions.Item label="报表名称">{data.report_name}</Descriptions.Item>
                                <Descriptions.Item label="税务所属期">{data.period}</Descriptions.Item>
                                <Descriptions.Item label="收入总额">{data.tax_money_total}</Descriptions.Item>
                                <Descriptions.Item label="应补退税（申报）">{data.tax_total}</Descriptions.Item>
                                <Descriptions.Item label="应补退税">{data.ybtse}</Descriptions.Item>
                                <Descriptions.Item label="滞纳金">{data.znje}</Descriptions.Item>
                                <Descriptions.Item label="合计总金额">{data.total}</Descriptions.Item>
                            </Descriptions>
                        ),
                        okCancel: false,
                    });
                }
            });
    };

    // 缴费
    const [payTrigger, payResult] = usePayTaxPaymentMutation();
    const [form] = Form.useForm<TaxCompanyPaymentForm>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sfxy, setSfxy] = useState<{ label: string, value: string, subtitle: JSX.Element }[]>([])
    const handleOk = () => {
        form.validateFields().then(values => {
            const allValues = form.getFieldsValue(true);
            payTrigger(allValues);
            setIsModalOpen(false);
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        if (payResult.isSuccess && payResult.data.data.url) {
            window.open(payResult.data.data.url, '_blank');
        }
    }, [payResult]);

    // 轮询查询异步结果
    const [queryPayFeedbackTrigger, queryPayFeedbackResult] = useQueryPayTaxPaymentFeedbackMutation();
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    useEffect(() => {
        return () => {
            clearInterval(timer);
        };
    }, [timer]);

    // 缴费前欠费查询
    const [queryPayTrigger, queryPayResult] = useQueryPayTaxPaymentMutation();
    const toQueryPay = async (record: TaxCompanyPaymentListData) => {
        if (record.can_query_arrearage) {
            // 发起查询
            let res = await queryPayTrigger({
                period: record.period,
                djxhid: record.djxhid,
                bmbh: searchForm.bmbh
            });
            if ('error' in res) return;

            let modal = Modal.info({
                icon: null,
                maskClosable: false,
                title: '正在查询缴款信息',
                content: (
                    <Spin size="large" tip="此过程可能会花费一些时间" >
                        <div style={{ width: 300, height: 200 }}></div>
                    </Spin>
                ),
                okText: '中断',
                okType: 'danger',
                onOk: () => {
                    clearInterval(timer);
                },
                onCancel: () => {
                    clearInterval(timer);
                },
            });

            // 开始轮询异步结果
            let _timer = setInterval(async () => {
                console.log('发起结果查询');
                try {
                    let res = await queryPayFeedbackTrigger({
                        period: record.period,
                        djxhid: record.djxhid,
                        bmbh: searchForm.bmbh
                    }).unwrap();
                    if (res.data.can_pay) {
                        console.log('结果查询成功');
                        modal.destroy();
                        clearInterval(_timer);
                        toPay(record);
                    } else {
                        console.log('结果查询未果，等待继续查询');
                    }
                } catch (e) {
                    console.log('结果查询失败');
                    modal.destroy();
                    clearInterval(_timer);
                }
            }, 3000);
            setTimer(_timer);
        } else {
            toPay(record);
        }
    }
    // 缴费
    const toPay = (record: TaxCompanyPaymentListData) => {
        if (payType === 1) {
            detailTrigger({ period: record.period, djxhid: record.djxhid, bmbh: searchForm.bmbh })
                .then(res => {
                    if (res.isSuccess) {
                        let data = res.data.data.detail;
                        confirm({
                            title: '核对信息',
                            icon: null,
                            content: (
                                <Descriptions bordered column={1}>
                                    <Descriptions.Item label="企业名称">{data.company_name}</Descriptions.Item>
                                    <Descriptions.Item label="收入总额">{data.tax_money_total}</Descriptions.Item>
                                    <Descriptions.Item label="应补退税（申报）">{data.tax_total}</Descriptions.Item>
                                    <Descriptions.Item label="应补退税">{data.ybtse}</Descriptions.Item>
                                    <Descriptions.Item label="滞纳金">{data.znje}</Descriptions.Item>
                                    <Descriptions.Item label="合计总金额">{data.total}</Descriptions.Item>
                                </Descriptions>
                            ),
                            onOk: () => {
                                if (data.sflb) {
                                    setSfxy(
                                        data.sflb.map(item => ({
                                            value: item.sfxyh,
                                            label: item.yhhbmc,
                                            subtitle: (
                                                <div style={{ 'color': 'grey' }}>
                                                    <div>三方协议号：{item.sfxyh}</div>
                                                    <div>账户名称：{item.jkmc}</div>
                                                    <div>缴款账户：{item.jkzh}</div>
                                                </div>
                                            )
                                        }))
                                    )
                                    form.setFieldsValue({
                                        period: record.period,
                                        djxhid: record.djxhid,
                                        pay_type: payType
                                    })
                                    setIsModalOpen(true);
                                }
                            },
                        });
                    }
                });
        } else {
            payTrigger({ period: record.period, djxhid: record.djxhid, pay_type: payType })
        }
    }

    // 缴款凭证作废
    const [cancelTrigger, cancelResult] = useCancelPaymentVoucherMutation();
    useEffect(() => {
        if (cancelResult.isSuccess) {
            message.success(cancelResult.data.msg);
        }
    }, [cancelResult]);
    const toCancel = (record: TaxCompanyPaymentListData) => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确定进行作废吗？',
            onOk() {
                return new Promise((resolve, reject) => {
                    cancelTrigger({ period: record.period, djxhid: record.djxhid, bmbh: searchForm.bmbh }).then(resolve).catch(reject);
                }).catch(() => console.log('Oops errors!'));
            },
        });
    };

    // 缴款状态查询(payType:1,2 缴款状态查询 payType:3 打印缴款凭证的缴款结果查询)
    const [queryTrigger, queryResult] = useQueryPayTaxPaymentResultMutation();
    const [query3Trigger, query3Result] = useQueryPaymentVoucherResultMutation();
    useEffect(() => {
        if (queryResult.isSuccess) {
            message.success(queryResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [queryResult]);
    useEffect(() => {
        if (query3Result.isSuccess) {
            message.success(query3Result.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [query3Result]);
    const toQuery = (record: TaxCompanyPaymentListData) => {
        if (payType === 3) {
            query3Trigger({ period: record.period, djxhid: record.djxhid, bmbh: searchForm.bmbh });
        } else {
            queryTrigger({ period: record.period, djxhid: record.djxhid, bmbh: searchForm.bmbh });
        }
    };

    // 缴费凭证
    const [voucherTrigger, voucherResult] = useDownloadVoucherMutation();
    useEffect(() => {
        if (voucherResult.isSuccess) {
            window.open(voucherResult.data.data.full_link, '_blank');
        }
    }, [voucherResult]);
    const toDownloadVoucher = (record: TaxCompanyPaymentListData) => {
        voucherTrigger({ period: record.period, djxhid: record.djxhid, bmbh: searchForm.bmbh });
    };


    // 完税证明
    const [proofTrigger, proofResult] = useDownloadProofOnPaymentMutation();
    useEffect(() => {
        if (proofResult.isSuccess) {
            window.open(proofResult.data.data.zipurl, '_blank');
        }
    }, [proofResult]);
    const toDownloadProof = (record: TaxCompanyPaymentListData) => {
        proofTrigger({ period: record.period, djxhid: record.djxhid });
    };

    return (
        <Spin
            spinning={
                listResult.isLoading ||
                detailResult.isLoading ||
                payResult.isLoading ||
                cancelResult.isLoading ||
                proofResult.isLoading ||
                voucherResult.isLoading
            }
            tip="正在进行耗时操作，请稍等"
        >
            <div className='address menu inner-page'>
                <div className='menu__title'>税务缴费 - {payTypeMap[payType]}</div>

                <div className='search'>
                    <Select
                        className="search__item"
                        placeholder="登记序号"
                        value={searchForm.djxhid}
                        onChange={handleDjxhidChange}
                        optionLabelProp="label"
                    >
                        {
                            registerListResult.data?.data.list.map(item => (
                                <Option value={item.djxhid} label={item.djxhid}>
                                    <div>{item.djxhid}</div>
                                    <div>{item.qymc}（{item.province_city_text}）</div>
                                </Option>
                            ))
                        }
                    </Select>
                    <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="选择部门筛选"
                        value={searchForm.bmbh}
                        onChange={handleDepartmentChange}
                    >
                        {departmentList.map(department => (
                            <Option key={department.bmbh} value={department.bmbh}>{department.bmmc}</Option>
                        ))}
                    </Select>
                    <DatePicker
                        className="search__item"
                        value={searchForm.period ? dayjs(searchForm.period) : null}
                        onChange={handleTaxPeriodChange}
                        placeholder='税务所属期筛选'
                        picker="month" />

                </div>
                <Table
                    dataSource={listData}
                    loading={listResult.isLoading}
                    rowKey={(record: TaxCompanyPaymentListData) => record?.id}
                    pagination={{
                        position: ['bottomRight'],
                        size: "small",
                        total: listResult.data?.data.total ?? 0,
                        showTotal: total => `共 ${total} 项`,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange: onPageChange
                    }}>
                    <Column title="税务所属期" dataIndex="period" key="period" />
                    <Column title="人数（人）" dataIndex="number" key="number" />
                    <Column title="滞纳金（元）" dataIndex="znje" key="znje" />
                    <Column title="合计金额（元）" dataIndex="tax_total" key="tax_total" />
                    <Column title="状态" dataIndex="sbztbj_text" key="sbztbj_text" />
                    <Column
                        title="操作"
                        key="action"
                        render={(_: any, record: TaxCompanyPaymentListData | undefined) => (
                            <Space>
                                <Dropdown.Button trigger={['click', 'hover']} type="primary" loading={false} overlay={
                                    <Menu
                                        items={record ? [
                                            {
                                                key: 'detail',
                                                label: (
                                                    <div>查看明细</div>
                                                ),
                                                onClick: () => toDetail(record),
                                            },
                                            {
                                                key: 'pay',
                                                disabled: !record.can_pay,
                                                label: (
                                                    <div>{payButtonMap[payType]}</div>
                                                ),
                                                onClick: () => toQueryPay(record),
                                            },
                                            {
                                                key: 'cancel',
                                                disabled: !record.can_cancel_withholding_voucher,
                                                label: (
                                                    <div>缴款凭证作废</div>
                                                ),
                                                onClick: () => toCancel(record),
                                            },
                                            {
                                                key: 'query',
                                                disabled: !record.can_check_pay,
                                                label: (
                                                    <div>缴款状态查询</div>
                                                ),
                                                onClick: () => toQuery(record),
                                            },
                                            {
                                                key: 'voucher',
                                                disabled: !record.can_download_voucher,
                                                label: (
                                                    <div>下载缴费凭证</div>
                                                ),
                                                onClick: () => toDownloadVoucher(record),
                                            },
                                            {
                                                key: 'proof',
                                                disabled: !record.can_withheld_voucher,
                                                label: (
                                                    <div>完税证明</div>
                                                ),
                                                onClick: () => toDownloadProof(record),
                                            },
                                        ] : []}
                                    />
                                }>
                                    操作
                                </Dropdown.Button>
                            </Space>
                        )}
                    />
                </Table>

                <Modal title="选择三方协议" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Form form={form}>
                        <Form.Item
                            name="sfxyh"
                            rules={[{ required: true, message: '请选择三方协议' }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder="请选择三方协议"
                                optionLabelProp="label"
                            >
                                {
                                    sfxy.map((item) => (
                                        <Option value={item.value} label={item.label}>
                                            <div>
                                                <div>{item.label}</div>
                                                <div>{item.subtitle}</div>
                                            </div>
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </Spin>
    );
}

export default TaxPaymentListPage;