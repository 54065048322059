import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// apis
import {
    useCancelCompanyTaxStaffMutation,
    useGetCompanyTaxRegisterListQuery,
    useGetStaffReportFeedbackMutation,
    useGetCompanyTaxStaffListMutation,
    useReportCompanyTaxStaffMutation,
    useChangeEntryDateMutation,
    useExportCompanyTaxStaffListMutation,
    useUploadCompanyTaxStaffMutation,
    useLazyGetCompanyTaxRegisterConfigDetailQuery,
    useStaffQuitMutation,
    useGetImportTemplateMutation
} from '../../../../store/apis/tax';
import { useLazyGetDepartmentListQuery } from '../../../../store/apis/company';
import { useGetDictListQuery, useUploadFileMutation } from '../../../../store/apis/common';

// interface
import { TaxChangeEntryDateForm, TaxChangeQuitDateForm, TaxCompanyRegisterConfigInfoOption, TaxCompanyStaffListData, TaxCompanyStaffSearchForm, TaxReportCompanyTaxStaffForm } from '../../../../interfaces/tax/tax';
import { DepartmentData } from '../../../../interfaces/company/department';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, Input, message, Modal, Form, Checkbox, DatePicker } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// assets
import { AvatarImage } from '../../../../components/image/avatar';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs from 'dayjs';
import React from 'react';
import { rootStore } from '../../../../store/store';
import { setGlobalParams } from '../../../../store/utils/tax';
import { useAppDispatch } from '../../../../store/hooks';

const { Column } = Table;
const { Option } = Select;
const { confirm } = Modal;

function TaxStaffListPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // 请求hook
    const [listTrigger, listResult] = useGetCompanyTaxStaffListMutation();

    // 搜索表单
    const [searchForm, setSearchForm] = useState<TaxCompanyStaffSearchForm>({
        page: 1,
        size: 10,
        period: rootStore.getState().tax.period || dayjs().format('YYYY-MM'),
    });
    const searchFormRef = useRef<TaxCompanyStaffSearchForm>(searchForm);
    useEffect(() => { searchFormRef.current = searchForm; }, [searchForm]);

    // 重置表单
    const resetData = () => {
        let form: TaxCompanyStaffSearchForm = {
            ...searchFormRef.current,
            page: 1,
        }
        setSearchForm(form);
        listTrigger(form);
    }

    // 登记序号
    const registerListResult = useGetCompanyTaxRegisterListQuery(undefined, { refetchOnMountOrArgChange: 60 });
    const handleDjxhidChange = (value: string) => {
        console.log(`selected ${value}`);
        // 缓存
        setGlobalParams({ dispatch, djxhid: value });

        // 部门列表
        departmentTrigger({ djxhid: value });

        let form = {
            ...searchFormRef.current,
            djxhid: value,
            bmbh: undefined
        }
        setSearchForm(form);
        listTrigger(form).unwrap().then(res => {
            if (res.data.can_pre_deduct) {
                confirm({
                    title: '提示',
                    icon: <ExclamationCircleOutlined />,
                    content: <div>
                        <p>登记序号：{value}</p>
                        <p>是否需要调整往期六万以内收入的员工计税方式为每月按整年6万？不更改，默认按每月5000预扣。</p>
                    </div>,
                    okText: '调整',
                    cancelText: '不更改',
                    onOk() {
                        navigate(`/tax_staff/preDeductSettings?djxhid=${value}`);
                    },
                })
            }
        });
    }
    // 依赖登记序号初始化列表
    useEffect(() => {
        if (registerListResult.isSuccess && !(searchFormRef.current.djxhid))
            if (registerListResult.data.data.list.length > 0) {
                let djxhid = rootStore.getState().tax.djxhid
                    || registerListResult.data.data.list[0].djxhid;
                handleDjxhidChange(djxhid);
            }
    }, [listTrigger, registerListResult]);


    // 部门列表（筛选）
    const [departmentTrigger, departmentResult] = useLazyGetCompanyTaxRegisterConfigDetailQuery({ refetchOnReconnect: true });
    const [departmentList, setDepartmentList] = useState<TaxCompanyRegisterConfigInfoOption[]>([]);
    useEffect(() => {
        if (departmentResult.isSuccess) {
            setDepartmentList(departmentResult.data!.data.detail.options);
            if (departmentResult.data!.data.detail.options.length > 0) {
                let form = {
                    ...searchFormRef.current,
                    bmbh: departmentResult.data!.data.detail.options[0].bmbh
                }
                setSearchForm(form);
                listTrigger(form);
            }
        }
    }, [departmentResult]);
    // 事件
    const handleDepartmentChange = (value: number) => {
        console.log(`selected ${value}`);

        let form = {
            ...searchFormRef.current,
            bmbh: value
        }
        setSearchForm(form);
        listTrigger(form);
    };

    // 税务所属期
    const handleTaxPeriodChange = (value: dayjs.Dayjs | null, dateString: string) => {
        console.log(`date: ${value}`);
        // 缓存
        setGlobalParams({ dispatch, period: value?.format('YYYY-MM') });

        let form = {
            ...searchFormRef.current,
            period: value!.format('YYYY-MM')
        }
        setSearchForm(form);
        listTrigger(form);
    }

    // 过滤非正常人员
    const handleFilterChange = (e: CheckboxChangeEvent) => {
        let form = {
            ...searchFormRef.current,
            show_normal: e.target.checked
        }
        setSearchForm(form);
        listTrigger(form);
    };


    // 列表数据
    let [list, setList] = useState<TaxCompanyStaffListData[]>([]);
    useEffect(() => {
        if (listResult.isSuccess) {
            console.log('请求结果：', listResult);

            setList((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== listResult.data.data.total) {
                    newListData = new Array(listResult.data.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((searchFormRef.current.page! - 1) * searchFormRef.current.size!, listResult.data.data.list.length, ...listResult.data.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        }
    }, [listResult]);

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        setSearchForm((form) => {
            let newForm = {
                ...form,
                page,
                size: pageSize,
            };
            listTrigger(newForm);
            return newForm;
        })
    }

    // table选中
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // 雇员类型
    const typeResult = useGetDictListQuery(['tax_staff_sfgy'], { refetchOnMountOrArgChange: 60 });
    // 报送
    const [reportTrigger, reportResult] = useReportCompanyTaxStaffMutation();
    const [form] = Form.useForm<TaxReportCompanyTaxStaffForm>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        if (reportResult.isSuccess) {
            message.success(reportResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [reportResult, listTrigger]);
    const handleOk = () => {
        form.validateFields().then(_ => {
            let values = form.getFieldsValue(true);
            reportTrigger({
                ...values,
                period: (values.period as dayjs.Dayjs).format('YYYY-MM')
            });
            setIsModalOpen(false);
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // 默认传递勾选的条目id
    const toReport = (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        if (ids.length === 0) {
            ids = list.map(item => item.id);
        }
        form.resetFields();
        form.setFieldValue('ids', ids);
        form.setFieldValue('period', dayjs().subtract(1, 'months'));
        setIsModalOpen(true);
    }

    // 作废
    const [cancelTrigger, cancelResult] = useCancelCompanyTaxStaffMutation();
    useEffect(() => {
        if (cancelResult.isSuccess) {
            message.success(cancelResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [cancelResult, listTrigger]);
    // 默认传递勾选的条目id
    const toCancel = async (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        let tip = '确定进行作废吗?';
        if (ids.length === 0) {
            tip = '确定全部进行作废吗？'
        }
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: tip,
            onOk() {
                return new Promise((resolve, reject) => {
                    cancelTrigger({
                        ids: ids,
                    }).then(resolve).catch(reject);
                }).catch(() => console.log('Oops errors!'));
            },
        });
    }

    // 离职
    const [quitTrigger, quitResult] = useStaffQuitMutation();
    const [isQuitModalOpen, setIsQuitModalOpen] = useState(false);
    const [quitForm] = Form.useForm<TaxChangeQuitDateForm>();
    useEffect(() => {
        if (quitResult.isSuccess) {
            message.success(quitResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [quitResult, listTrigger]);
    const handleQuitOk = () => {
        quitForm.validateFields().then(_ => {
            let values = quitForm.getFieldsValue(true);
            quitTrigger({
                ...values,
                quit_date: (values.entry_date as dayjs.Dayjs).format('YYYY-MM-DD'),
                period: (values.period as dayjs.Dayjs).format('YYYY-MM')
            });
            setIsQuitModalOpen(false);
        })
    };
    const handleQuitCancel = () => {
        setIsQuitModalOpen(false);
    };
    // 默认传递勾选的条目id
    const toQuit = (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        quitForm.resetFields();
        quitForm.setFieldValue('ids', ids);
        quitForm.setFieldValue('djxhid', searchForm.djxhid);
        quitForm.setFieldValue('bmbh', searchForm.bmbh);
        quitForm.setFieldValue('period', dayjs().subtract(1, 'months'));
        setIsQuitModalOpen(true);
    }

    const [feedbackTrigger, feedbackResult] = useGetStaffReportFeedbackMutation();
    useEffect(() => {
        if (feedbackResult.isSuccess) {
            message.success(feedbackResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [feedbackResult, listTrigger]);
    const toFeedback = () => {
        feedbackTrigger();
    }


    // 修改入职时间
    const [changeEntryDateTrigger, changeEntryDateResult] = useChangeEntryDateMutation();
    const [entryForm] = Form.useForm<TaxChangeEntryDateForm>();
    const [isEntryModalOpen, setIsEntryModalOpen] = useState(false);
    useEffect(() => {
        if (changeEntryDateResult.isSuccess) {
            message.success(changeEntryDateResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [changeEntryDateResult, listTrigger]);
    const handleEntryOk = () => {
        entryForm.validateFields().then(_ => {
            let values = entryForm.getFieldsValue(true);
            changeEntryDateTrigger({
                ...values,
                entry_date: (values.entry_date as dayjs.Dayjs).format('YYYY-MM-DD'),
                period: (values.period as dayjs.Dayjs).format('YYYY-MM')
            });
            setIsEntryModalOpen(false);
        })
    };
    const handleEntryCancel = () => {
        setIsEntryModalOpen(false);
    };
    // 默认传递勾选的条目id
    const toChangeEntry = (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        entryForm.resetFields();
        entryForm.setFieldValue('ids', ids);
        entryForm.setFieldValue('djxhid', searchForm.djxhid);
        entryForm.setFieldValue('bmbh', searchForm.bmbh);
        entryForm.setFieldValue('period', dayjs().subtract(1, 'months'));
        setIsEntryModalOpen(true);
    }

    // 导出数据
    // const [exportTrigger, exportResult] = useExportCompanyTaxStaffListMutation();
    // useEffect(() => {
    //     if (exportResult.isSuccess) {
    //         setTimeout(() => {
    //             window.open(exportResult.data.data.full_link, '_blank');
    //         }, 500);
    //     }
    // }, [exportResult]);
    // const exportData = () => {
    //     confirm({
    //         title: '提示',
    //         icon: <ExclamationCircleOutlined />,
    //         content: '确定要导出员工数据吗？',
    //         onOk() {
    //             return new Promise((resolve, reject) => {
    //                 exportTrigger(searchFormRef.current)
    //                     .then(resolve).catch(reject);
    //             }).catch(() => console.log('Oops errors!'));
    //         },
    //     });
    // };

    // 下载模板
    const [templateTrigger, templateResult] = useGetImportTemplateMutation();
    useEffect(() => {
        if (templateResult.isSuccess) {
            window.open(templateResult.data.data.full_link, '_blank');
        }
    }, [templateResult]);
    const download = () => {
        templateTrigger();
    };

    // 导入数据
    const [importTrigger, importResult] = useUploadCompanyTaxStaffMutation();
    useEffect(() => {
        if (importResult.isSuccess) {
            if (importResult.data.data.fail) {
                confirm({
                    title: '导入员工失败',
                    icon: <ExclamationCircleOutlined />,
                    content: '是否下载失败结果文档',
                    onOk() {
                        window.open(importResult.data.data.full_link, '_blank');
                    },
                });
            } else {
                message.success(importResult.data.msg);
            }
            listTrigger(searchFormRef.current);
        }
    }, [importResult]);

    // 上传点击框ref
    const inputRef = React.createRef<HTMLInputElement>();
    // 上传文件
    const [uploadTrigger, uploadResult] = useUploadFileMutation();
    useEffect(() => {
        if (uploadResult.isSuccess) {
            // 获取文件id传递给导入接口
            importTrigger({
                file_id: uploadResult.data.data.file_id,
                djxhid: searchFormRef.current.djxhid!,
                period: searchFormRef.current.period!,
                bmbh: searchFormRef.current.bmbh
            });
        }
    }, [uploadResult, importTrigger]);
    const upload = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || !searchFormRef.current.djxhid) return;
        // 多部门
        if (departmentResult.data?.data.detail.fbmba === true && !searchFormRef.current.bmbh) {
            message.error('请选择部门后再导入员工');
            return;
        }
        // 上传到服务器
        uploadTrigger({ file: event.target.files![0], type: 'salary' });
    };

    return (
        <div className='address menu inner-page'>
            <div className='menu__title'>员工采集信息</div>
            <div className='search'>
                <Select
                    className="search__item"
                    placeholder="登记序号"
                    value={searchForm.djxhid}
                    onChange={handleDjxhidChange}
                    optionLabelProp="label"
                >
                    {
                        registerListResult.data?.data.list.map(item => (
                            <Option value={item.djxhid} label={item.djxhid}>
                                <div>{item.djxhid}</div>
                                <div>{item.qymc}（{item.province_city_text}）</div>
                            </Option>
                        ))
                    }
                </Select>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="选择部门筛选"
                    value={searchForm.bmbh}
                    onChange={handleDepartmentChange}
                >
                    {departmentList.map(department => (
                        <Option key={department.bmbh} value={department.bmbh}>{department.bmmc}</Option>
                    ))}
                </Select>
                <DatePicker
                    allowClear={false}
                    className="search__item"
                    value={searchForm.period ? dayjs(searchForm.period) : null}
                    onChange={handleTaxPeriodChange}
                    placeholder='税务所属期筛选'
                    picker="month" />
                <Checkbox
                    className="search__item"
                    checked={searchForm.show_normal}
                    onChange={handleFilterChange}
                >
                    仅显示正常人员
                </Checkbox>
                <Dropdown.Button
                    trigger={['click', 'hover']}
                    className="search__last-button"
                    type="primary"
                    loading={
                        reportResult.isLoading ||
                        cancelResult.isLoading
                    }
                    overlay={
                        <Menu
                            items={selectedRowKeys.length > 0 ? [
                                {
                                    key: 'generate',
                                    label: (
                                        <div>批量上报</div>
                                    ),
                                    onClick: () => toReport(),
                                },
                                {
                                    key: 'retransfer',
                                    label: (
                                        <div>批量作废</div>
                                    ),
                                    onClick: () => toCancel(),
                                },
                                {
                                    key: 'quit',
                                    label: (
                                        <div>批量员工离职</div>
                                    ),
                                    onClick: () => toQuit(),
                                },
                                {
                                    key: 'changeEntry',
                                    label: (
                                        <div>批量修改员工入职时间</div>
                                    ),
                                    onClick: () => toChangeEntry(),
                                },
                            ] : [
                                //     {
                                //     key: 'export',
                                //     label: (
                                //         <div>导出员工数据</div>
                                //     ),
                                //     onClick: () => exportData(),
                                // },
                                {
                                    key: 'download',
                                    label: (
                                        <div>下载模板</div>
                                    ),
                                    onClick: () => download(),
                                },
                                {
                                    key: 'import',
                                    label: (
                                        <div>导入员工数据</div>
                                    ),
                                    onClick: () => {
                                        if (inputRef.current) {
                                            inputRef.current.value = '';
                                            inputRef.current.click();
                                        }
                                    },
                                }, {
                                    key: 'accounting',
                                    label: (
                                        <div>上报全部人员</div>
                                    ),
                                    onClick: () => toReport([]),
                                },
                                {
                                    key: 'cancelAccounting',
                                    label: (
                                        <div>手动反馈</div>
                                    ),
                                    onClick: () => toFeedback(),
                                },
                            ]}
                        />
                    }>
                    {selectedRowKeys.length > 0 ? `已选择${selectedRowKeys.length}项` : '批量操作'}
                </Dropdown.Button>
            </div>
            <Table
                dataSource={list}
                loading={listResult.isLoading}
                rowSelection={
                    {
                        selectedRowKeys,
                        onChange: onSelectChange
                    }
                }
                rowKey={(record: TaxCompanyStaffListData) => record?.id}
                pagination={{
                    position: ['bottomRight'],
                    size: "small",
                    total: listResult.data?.data.total ?? 0,
                    showTotal: total => `共 ${total} 项`,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    onChange: onPageChange
                }}
                footer={() => listResult.data?.data.tip}
            >
                <Column
                    title="头像"
                    dataIndex="avatar_link"
                    key="avatar_link"
                    width={60}
                    render={(avatar: string) => (
                        <AvatarImage url={avatar} />
                    )}
                />
                <Column title="姓名" dataIndex="xm" key="xm" />
                <Column title="入职日期" dataIndex="rzsgrq" key="rzsgrq" />
                <Column
                    title="报送状态"
                    dataIndex="sbzt_text"
                    key="sbzt_text"
                    render={(status: string, record: TaxCompanyStaffListData | undefined) => (
                        <span style={{ color: record?.sbzt_color_text }}>{status}</span>
                    )}
                />
                <Column title="人员状态" dataIndex="nsrzt" key="nsrzt" />
                <Column title="验证状态" dataIndex="rzzt_text" key="rzzt_text" />
                <Column title="受雇类型" dataIndex="sfgy" key="sfgy" />
                <Column title="异常信息" dataIndex="sbyy" key="sbyy" render={
                    (sbyy: string) => (<span style={{ color: 'red' }}>{sbyy}</span>)
                } />
                <Column
                    title="操作"
                    key="action"
                    render={(_: any, record: TaxCompanyStaffListData | undefined) => (
                        <Space>
                            <Dropdown.Button trigger={['click', 'hover']} type="primary" loading={false} overlay={
                                <Menu
                                    items={record ? [
                                        {
                                            key: 'confirm',
                                            label: (
                                                <div>员工报送</div>
                                            ),
                                            onClick: () => toReport([record.id]),
                                        },
                                        {
                                            key: 'cancel',
                                            label: (
                                                <div>作废人员</div>
                                            ),
                                            onClick: () => toCancel([record.id]),
                                        },
                                    ] : []}
                                />
                            }>
                                操作
                            </Dropdown.Button>
                        </Space>
                    )}
                />
            </Table>

            <Modal title="选择雇员类型" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form}>
                    <Form.Item
                        name="period"
                        rules={[{ required: true, message: '请选择税务所属期' }]}
                    >
                        <DatePicker style={{ 'width': '100%' }} format={'YYYY-MM'} picker="month" placeholder="税务所属期" />
                    </Form.Item>
                    {/* <Form.Item
                        name="sfgy"
                        rules={[{ required: true, message: '请选择雇员类型' }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="请选择雇员类型"
                            optionLabelProp="label"
                        >
                            {typeResult.data?.data.tax_staff_sfgy?.options.map(option => (
                                <Option key={option.value} label={option.name} value={option.name}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item> */}
                </Form>
            </Modal>

            <Modal title="修改员工入职时间" open={isEntryModalOpen} onOk={handleEntryOk} onCancel={handleEntryCancel}>
                <Form form={entryForm}>
                    <Form.Item
                        name="period"
                        rules={[{ required: true, message: '请选择税务所属期' }]}
                    >
                        <DatePicker style={{ 'width': '100%' }} format={'YYYY-MM'} picker="month" placeholder="税务所属期" />
                    </Form.Item>
                    <Form.Item
                        name="entry_date"
                        rules={[{ required: true, message: '请选择入职日期' }]}
                    >
                        <DatePicker style={{ 'width': '100%' }} format={'YYYY-MM-DD'} placeholder="入职日期" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="员工离职时间" open={isQuitModalOpen} onOk={handleQuitOk} onCancel={handleQuitCancel}>
                <Form form={quitForm}>
                    <Form.Item
                        name="period"
                        rules={[{ required: true, message: '请选择税务所属期' }]}
                    >
                        <DatePicker style={{ 'width': '100%' }} format={'YYYY-MM'} picker="month" placeholder="税务所属期" />
                    </Form.Item>
                    <Form.Item
                        name="quit_date"
                        rules={[{ required: true, message: '请选择离职日期' }]}
                    >
                        <DatePicker style={{ 'width': '100%' }} format={'YYYY-MM-DD'} placeholder="离职日期" />
                    </Form.Item>
                </Form>
            </Modal>


            {/* 导入上传input */}
            {
                <input
                    ref={inputRef}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={upload}
                />
            }
        </div>
    );
}

export default TaxStaffListPage;