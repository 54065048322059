import { baseApi } from "./base";

import { CommonResponse, ErrorList } from "../../interfaces/common/response";
import { ShortcutDetail, ShortcutList, ShortcutSettingsForm } from "../../interfaces/settings/shortcut";
import { FeedbackForm } from "../../interfaces/settings/feedback";

const settingsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        /**
         * 快捷方式
         */
        // 获取所有显示数据
        getShortcutDataList: builder.query<CommonResponse<ShortcutList>, void>({
            query: () => ({
                url: `/api/shares/shortcut_data`,
                method: 'get',
            }),
        }),
        // 获取所有快捷方式 
        getShortcutLnkList: builder.query<CommonResponse<ShortcutList>, void>({
            query: () => ({
                url: `/api/shares/shortcut_lnk`,
                method: 'get',
            }),
        }),
        // 获取用户快捷方式设置详情
        getUserShortcutSettingsDetail: builder.query<CommonResponse<ShortcutDetail>, void>({
            query: () => ({
                url: `/manage/shortcut/detail`,
                method: 'get',
            }),
        }),
        // 保存用户快捷方式设置
        saveShortcutSettings: builder.mutation<CommonResponse, ShortcutSettingsForm>({
            query: (form) => ({ url: `/manage/shortcut/update`, method: 'post', body: form }),
        }),
        /**
        * 意见反馈
        */
        sendFeedback: builder.mutation<CommonResponse, FeedbackForm>({
            query: (form) => ({
                url: `/manage/staff/feedback`,
                method: 'post',
                body: form
            }),
        }),
    }),
});

export const {
    useGetShortcutLnkListQuery,
    useGetUserShortcutSettingsDetailQuery,
    useLazyGetShortcutLnkListQuery,
    useLazyGetUserShortcutSettingsDetailQuery,
    useSaveShortcutSettingsMutation,
    useGetShortcutDataListQuery,
    useLazyGetShortcutDataListQuery,
    useSendFeedbackMutation
} = settingsApi;
