import { useState } from "react";

// component
import PageLayout from "../../layout/pageLayout";

// router
import { Outlet } from "react-router-dom";

// assets
import djhImg from '@assets/icons/function/tax/djh.svg';
import passwordImg from '@assets/icons/function/tax/password.svg';
import registerImg from '@assets/icons/function/tax/register.svg';

// antd
import { IMenuItem, MenuList } from "../../components/menu/menuList";

/**
 * 企业设置页
 */
function TaxRegisterSettings() {
    const left = <TaxSettingsList></TaxSettingsList>;
    const right = <Outlet />;

    return (
        <PageLayout leftComponent={left} rightComponent={right} flex={[3, 5]}></PageLayout>
    );
}


function TaxSettingsList() {
    // 动态显示内容
    const [items, setItems] = useState<IMenuItem[]>(
        [
            {
                title: '企业税务注册',
                items: [
                    {
                        title: '企业信息注册',
                        img: registerImg,
                        path: '/tax_register/register',
                        display: true,
                        canUse: true,
                    }, {
                        title: '申报密码设置',
                        img: passwordImg,
                        path: '/tax_register/password',
                        display: true,
                        canUse: true,
                    }, {
                        title: '多登记号设置',
                        img: djhImg,
                        path: '/tax_register/registryNoConfig',
                        display: true,
                        canUse: true,
                    },
                ]
            }
        ]
    );

    return (
        <div className="menu">
            <MenuList items={items} />
        </div>
    );
}

export default TaxRegisterSettings;