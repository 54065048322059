import { useState, useEffect } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { setTimestamp } from '../common';
import { rootStore } from '../store';
import dayjs from 'dayjs';

// 获取全局倒计时差
export const getGlobalCountDownSec = (sec: number, key: string): number => {
    const nowTimestamp = new Date().getTime();
    const lastTimestamp = rootStore.getState().common.timestamp[key];
    const res = sec - ((nowTimestamp - lastTimestamp) / 1000);
    console.log(nowTimestamp, lastTimestamp, sec, ((nowTimestamp - lastTimestamp) / 1000));

    return res > 0 ? Math.round(res) : 0;
};

// 全局倒计时hook
export const useGlobalCountDownSec = (sec: number, key: string): [number, typeof startGlobalCount] => {
    const dispatch = useAppDispatch();

    // 倒计时初始化
    const [count, setCount] = useState(getGlobalCountDownSec(sec, key));
    // 计数器更新count
    useEffect(() => {
        setTimeout(() => {
            console.log(count);
            if (count > 0) {
                setCount(getGlobalCountDownSec(sec, key));
            }
        }, 1000);
    }, [sec, count]);

    const startGlobalCount = () => {
        // 重置全局倒计时开始时间
        dispatch(setTimestamp([dayjs(), key]));
        // 重置count
        setCount(sec);
    };

    return [count, startGlobalCount];
}