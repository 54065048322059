import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
import {
    configureStore,
    combineReducers,
    ThunkAction,
    Action,
} from '@reduxjs/toolkit';

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist';
import localforage from 'localforage';

import logger from 'redux-logger';
import { baseApi } from './apis/base';

import { userSlice } from './user';
// import { rtkQueryErrorLogger } from './middleware/error';
import { commonSlice } from './common';
import { taxSlice } from './tax';

const persistConfig = {
    key: 'root',
    storage: localforage,
    whitelist: [commonSlice.name, userSlice.name, taxSlice.name] // 缓存
};

const rootReducer = combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
    [commonSlice.name]: commonSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [taxSlice.name]: taxSlice.reducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

// 中间件集合
const middlewareHandler = (getDefaultMiddleware: any) => {
    const middlewareList = [
        // rtkQueryErrorLogger,
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    ];
    if (process.env.NODE_ENV === 'development') {
        middlewareList.push(logger);
    }
    return middlewareList;
};

//API slice会包含自动生成的redux reducer和一个自定义中间件
export const rootStore = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        middlewareHandler(getDefaultMiddleware),
});

export const persistor = persistStore(rootStore);

setupListeners(rootStore.dispatch);

export type RootState = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
