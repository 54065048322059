import { createSlice } from '@reduxjs/toolkit';
import { CompanyApplyData } from '../interfaces/user/user';

interface UserState {
    token: string;
    userInfo?: CompanyApplyData,
}

const initialState: UserState = {
    token: '',
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearToken: (state: UserState) => {
            state.token = '';
        },
        setToken: (state: UserState, action) => {
            state.token = action.payload;
        },
        setUserInfo: (state: UserState, action) => {
            state.userInfo = action.payload;
        },
        clearUserInfo: (state: UserState) => {
            state.userInfo = undefined;
        },

    },
    extraReducers: {},
});

export const { clearToken, setToken, setUserInfo, clearUserInfo } = userSlice.actions