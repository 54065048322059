import { Cascader } from "antd";
import { DefaultOptionType } from "antd/lib/cascader";
import { useEffect, useState } from "react";
import { useLazyGetRegionDataQuery } from "../../store/apis/common";

/**
 * 省市区选择器
 * mode: text用于兼容纯文本值value
 */
export function LocationCascader({
    value = [],
    onChange = (value: number[]) => { },
    deep = 4,
    placeholder,
    mode = 'default'
}: {
    value?: number[] | string,
    deep: number,
    onChange?: (value: any) => void,
    placeholder?: string,
    mode?: 'default' | 'text'
}) {
    // 内建value（只受控于外部参数）
    const [buildInValue, setBuildInValue] = useState<number[]>([]);
    const [buildInTextValue, setBuildInTextValue] = useState<string>('');
    useEffect(() => {
        if (mode === 'default') {
            setBuildInValue(value as number[]);
            if (value.length > 0) {
                Promise.all([0, ...((value as number[]).slice(0, value.length - 1))].map(e => listTrigger({ pid: e }))).then(res => {
                    getOptions(res).then(options => {
                        setOptions(options);
                    })
                })
            }
        } else {
            setBuildInValue([0]);
            setBuildInTextValue(value as string);
        }
    }, [value]);

    // 选项
    const [options, setOptions] = useState<DefaultOptionType[]>([]);
    const [listTrigger, listResult] = useLazyGetRegionDataQuery({ refetchOnReconnect: true });

    // 初始化
    useEffect(() => {
        listTrigger({}).unwrap().then((value) => {
            setOptions(
                value.data.list.map(item => {
                    let temp: DefaultOptionType = {
                        value: item.id,
                        label: item.name,
                        isLeaf: false
                    };
                    return temp;
                })
            )
        });
    }, []);

    const getOptions = async (values: any): Promise<DefaultOptionType[]> => {
        let res = values[0];
        let arr: DefaultOptionType[] = [];
        for (let i = 0; i < res.data.data.items.length; i++) {
            const item = res.data.data.items[i];
            arr.push({
                value: item.id,
                label: item.name,
                isLeaf: values.length === 1,
                children: values.length > 1 && item.id === values[1].originalArgs.pid ? (await getOptions(values.slice(1))) : []
            });
        }
        return arr;
    }

    // 点击加载
    const loadData = (selectedOptions: DefaultOptionType[]) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        console.log('===================', targetOption);

        listTrigger({ pid: Number(targetOption.value) }).unwrap().then((value) => {
            targetOption.loading = false;
            targetOption.children = value.data.list.map(item => ({
                value: item.id,
                label: item.name,
                isLeaf: selectedOptions.length + 1 >= deep // 层数deep
            }));
            setOptions([...options]);
        }).catch(e => {
            targetOption.loading = false;
            console.error('=====================', e);
        });
    };

    // Emit
    const _onChange = (value: (string | number)[], selectedOptions: DefaultOptionType[]) => {
        if (value.length === deep) {
            if (mode === 'default') {
                onChange(value as number[]);
            } else {
                onChange(selectedOptions.map(item => item.label).join(' / '));
                console.log(selectedOptions.map(item => item.label).join(' / '));
            }
        }
    };

    return (
        <Cascader displayRender={(label) => {
            return (mode === 'default' ? label.join(' / ') : buildInTextValue);
        }} placeholder={placeholder} allowClear={false} value={buildInValue} options={options} loadData={loadData} onChange={_onChange} changeOnSelect />
    );
}