import { useState, useEffect } from 'react';

// interface
import { UploadRequestOption } from "rc-upload/lib/interface";
import { CommonResponse } from '../../../interfaces/common/response';
import { FileUpload } from '../../../interfaces/common/common';
import { Attachment } from '../../../interfaces/common/attachment';

// apis
import { useGetDictListQuery, useUploadFileMutation } from '../../../store/apis/common';

// antd
import { Modal, Button, Form, message, Select, Space, Upload, Input } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { useNavigate } from 'react-router-dom';
import { useSendFeedbackMutation } from '../../../store/apis/settings';
import { FeedbackForm } from '../../../interfaces/settings/feedback';


const { Option } = Select;
const { confirm } = Modal;

function FeedbackFormPage() {
    // 表单数据
    const [form] = Form.useForm<FeedbackForm>();

    // 反馈类型
    const typesResult = useGetDictListQuery(['feedback_type'], { refetchOnMountOrArgChange: 60 });

    // 上传附件
    const [attachments, setAttachments] = useState<UploadFile<any>[]>([]);
    const [uploadTrigger, uploadResult] = useUploadFileMutation();
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }

        return true;
    };
    const onFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
        console.log(info.fileList);
        setAttachments(info.fileList);
    }
    const customRequest = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file, onProgress } = options;
        uploadTrigger({ file: file as Blob, type: 'work_record' })
            .then(res => {
                if ('data' in res) {
                    let response: CommonResponse<FileUpload> = res.data;
                    onSuccess!(response.data);
                }
            });
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload | Attachment = file.response;
        window.open((res as FileUpload).full_link || (res as Attachment).attach_url_link, "_blank");
    }

    // 提交
    const navigate = useNavigate();
    const [submitTrigger, submitResult] = useSendFeedbackMutation();
    useEffect(() => {
        if (submitResult.isSuccess) {
            form.resetFields();
            confirm({
                title: '提交成功',
                icon: <InfoCircleOutlined />,
                content: '感谢您对我们的软件提出宝贵的意见和建议',
                okCancel: false,
            });
            navigate(-1);
        }
    }, [submitResult]);
    const submit = () => {
        form.validateFields().then(() => {
            let rawForm = form.getFieldsValue(true);
            let newForm: FeedbackForm = {
                ...rawForm,
                // 附件
                attachments: attachments
                    .filter(file => file.response)
                    .map(file => {
                        let res: FileUpload | Attachment = file.response;
                        return (res as FileUpload).file_id || (res as Attachment).id;
                    })
            }
            console.log(newForm);
            submitTrigger(newForm);
        });
    }

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>意见反馈</div>
            </div>

            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="反馈类型"
                    name="type"
                    rules={[{ required: true, message: '请选择反馈类型' }]}
                >
                    <Select
                        showArrow
                        placeholder="请选择反馈类型"
                    >
                        {typesResult.data?.data.feedback_type?.options.map(option => (
                            <Option key={option.value} value={option.value}>{option.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="标题"
                    name="title"
                    rules={[{ required: true, message: '请填写标题' }]}
                >
                    <Input placeholder='请填写标题' maxLength={50} />
                </Form.Item>

                <Form.Item
                    label="问题详情"
                    name="content"
                    rules={[{ required: true, message: '请填写问题详情' }]}
                >
                    <TextArea rows={4} placeholder="请填写问题详情" maxLength={500} />
                </Form.Item>

                <Form.Item
                    label="附件"
                >
                    <Upload
                        fileList={attachments}
                        beforeUpload={checkFileSize}
                        onChange={onFileChange}
                        customRequest={customRequest}
                        showUploadList={{ showDownloadIcon: true }}
                        onDownload={onAttachmentDownload}
                    >
                        <Button icon={<UploadOutlined />}>点击上传文件</Button>
                    </Upload>
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Button type='primary' onClick={submit}>提交表单</Button>
                    </Space>
                </Form.Item>
            </Form>
        </div >
    );
}


export default FeedbackFormPage;