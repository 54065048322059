import { useState } from "react";

// component
import PageLayout from "../../layout/pageLayout";

// router
import { Outlet } from "react-router-dom";

// assets
import paymentImg from '@assets/icons/function/tax/payment.svg';
import objectionAppealImg from '@assets/icons/function/tax/objection_appeal.svg';
import objectionReportImg from '@assets/icons/function/tax/objection_report.svg';

// antd
import { IMenuItem, MenuList } from "../../components/menu/menuList";

/**
 * 企业设置页
 */
function TaxPaySettings() {
    const left = <TaxSettingsList></TaxSettingsList>;
    const right = <Outlet />;

    return (
        <PageLayout leftComponent={left} rightComponent={right} flex={[3, 5]}></PageLayout>
    );
}


function TaxSettingsList() {
    // 动态显示内容
    const [items, setItems] = useState<IMenuItem[]>(
        [
            {
                title: '税务缴费',
                items: [
                    {
                        title: '三方协议缴款',
                        img: paymentImg,
                        path: '/tax_pay/pay?type=1',
                        display: true,
                        canUse: true,
                    }, {
                        title: '银联缴款',
                        img: paymentImg,
                        path: '/tax_pay/pay?type=2',
                        display: true,
                        canUse: true,
                    }, {
                        title: '缴款凭证',
                        img: paymentImg,
                        path: '/tax_pay/pay?type=3',
                        display: true,
                        canUse: true,
                    }, {
                        title: '缴款记录',
                        img: paymentImg,
                        path: '/tax_pay/payLogs',
                        display: true,
                        canUse: true,
                    },
                ]
            },
            // {
            //     title: '异议申诉',
            //     items: [
            //         {
            //             title: '异议申诉',
            //             img: objectionAppealImg,
            //             path: '/tax_pay/appeal',
            //             canUse: true,
            //             display: true,
            //         }, {
            //             title: '信息异常反馈',
            //             img: objectionReportImg,
            //             path: '/tax_pay/feedback',
            //             display: true,
            //             canUse: true,
            //         }
            //     ]
            // },
        ]
    );

    return (
        <div className="menu">
            <MenuList items={items} />
        </div>
    );
}

export default TaxPaySettings;