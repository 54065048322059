import { useState, useEffect, useRef } from 'react';

// antd
import { Alert, Button, Cascader, Col, DatePicker, Descriptions, Dropdown, Form, FormInstance, Input, InputNumber, Menu, message, Modal, Row, Select, Space, Switch, Table, Typography } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

// apis
import { useGetDutyFreeSelectListQuery, useGetTaxCompensationDetailQuery, useUpdateCompensationDutyFreeMutation } from '../../../../../store/apis/tax';

// interface
import { CompensationDutyFreeForm, CompensationFreeFormData, CompensationFreeSelectListData } from '../../../../../interfaces/tax/tax';

const { Column } = Table;

interface CollectionCreateFormProps {
    data: { id: number, djxhid: string, bmbh?: number, sub_company_id?: number },
    visible: boolean;
    onFinish: () => void;
    onCancel: () => void;
}

function EditCompensationDutyFreeFormModal({
    visible,
    onFinish,
    onCancel,
    data
}: CollectionCreateFormProps) {
    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 详情数据
    const detailResult = useGetTaxCompensationDetailQuery({ id: data.id }, { refetchOnMountOrArgChange: true, refetchOnReconnect: true });
    useEffect(() => {
        if (detailResult.isSuccess) {
            form.setFieldsValue({
                id: data.id,
                mssd_data: detailResult.data.data.detail.mssd_data,
            });
        }
    }, [detailResult]);

    // 表单数据
    const [form] = Form.useForm<CompensationDutyFreeForm>();

    // 详情表单
    const [subOpen, setSubOpen] = useState(false);
    const [subData, setSubData] = useState<CompensationFreeFormData>();
    const [subIndex, setSubIndex] = useState<number>();
    const onAddClick = () => {
        setSubData(undefined);
        setSubIndex(undefined);
        setSubOpen(true);
    };
    const hideUserModal = () => {
        setSubOpen(false);
    };
    const onEditClick = (record: CompensationFreeFormData, index: number) => {
        setSubIndex(index);
        setSubData({
            ...record,
        });
        setSubOpen(true);
    }
    const onDelClick = (index: number) => {
        let items: CompensationFreeFormData[] = form.getFieldValue('mssd_data');
        form.setFieldValue("mssd_data", items.filter((_, i) => index !== i));
    }

    // 编辑
    const [saveTrigger, saveResult] = useUpdateCompensationDutyFreeMutation();
    useEffect(() => {
        if (saveResult.isSuccess) {
            message.success(saveResult.data.msg);
            onFinish();
        }
    }, [saveResult]);

    // 提交
    const submit = async () => {
        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        console.log(_form);

        await saveTrigger(_form);

        setConfirmLoading(false);
    }

    return (
        <Modal
            width={1200}
            visible={visible}
            title="免税收入明细"
            okText="保存"
            cancelText="取消"
            maskClosable={false}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            onOk={() => {
                console.log(form.getFieldsValue());
                form
                    .validateFields()
                    .then(values => {
                        console.log(values);
                        submit();
                    })
            }}
        >
            {
                detailResult.isSuccess ?
                    <Form disabled layout="vertical">
                        <Row>
                            <Col span={11}>
                                <Form.Item label="姓名">
                                    <Input value={detailResult.data.data.detail.xm} />
                                </Form.Item>
                                <Form.Item label="证件号码">
                                    <Input value={detailResult.data.data.detail.zzhm} />
                                </Form.Item>
                                <Form.Item label="总免税税额">
                                    <Input addonAfter={'元'} value={detailResult.data.data.detail.mssd} />
                                </Form.Item>

                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                                <Form.Item label="证件类型">
                                    <Input value={'居民身份证'} />
                                </Form.Item>
                                <Form.Item label="所得项目">
                                    <Input value={detailResult.data.data.detail.sdxm} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : <></>
            }

            <Alert style={{ marginBottom: 20 }} message="明细数据中的[扣除金额]之和必须等于基础信息中的[总免税税额]" type="warning" />


            <Form.Provider
                onFormFinish={(name, { values, forms }) => {
                    if (name === 'dutyFreeItemForm') {
                        const { dutyFreeForm, dutyFreeItemForm } = forms;
                        const items = dutyFreeForm.getFieldValue('mssd_data') || [];
                        if (subIndex != null) {
                            items.splice(subIndex, 1, dutyFreeItemForm.getFieldsValue(true));
                            dutyFreeForm.setFieldValue('mssd_data', [...items]);
                        } else {
                            dutyFreeForm.setFieldValue('mssd_data', [...items, dutyFreeItemForm.getFieldsValue(true)]);
                        }
                        setSubOpen(false);
                    }
                }}
            > <Form
                form={form}
                name="dutyFreeForm"
                layout="vertical"
                autoComplete="off"
            >
                    <DutyFreeItemModalForm
                        open={subOpen}
                        onCancel={hideUserModal}
                        data={subData}
                        djxhid={data.djxhid}
                        bmbh={data.bmbh}
                    ></DutyFreeItemModalForm>
                    <Form.Item
                        label="明细数据"
                        required
                        shouldUpdate={(prevValues, curValues) => prevValues.mssd_data !== curValues.mssd_data}
                    >
                        {({ getFieldValue }) => {
                            const items: CompensationFreeFormData[] = getFieldValue('mssd_data') || [];
                            return items.length ? (
                                <Table size='small' dataSource={items}>
                                    <Column title="免税事项" dataIndex="jmsx" key="jmsx"></Column>
                                    <Column title="免税性质" dataIndex="jmxz" key="jmxz"></Column>
                                    <Column title="扣除金额" dataIndex="sjkcje" key="sjkcje"></Column>
                                    <Column
                                        title="操作"
                                        key="action"
                                        render={(_: any, record: CompensationFreeFormData | undefined, index: number) => (
                                            <Space>
                                                <Dropdown.Button trigger={['click', 'hover']} type="primary" overlay={
                                                    <Menu
                                                        items={record ? [
                                                            {
                                                                key: 'edit',
                                                                label: (
                                                                    <div>编辑</div>
                                                                ),
                                                                onClick: () => onEditClick(record, index),
                                                            },
                                                            {
                                                                key: 'del',
                                                                label: (
                                                                    <div>删除</div>
                                                                ),
                                                                onClick: () => onDelClick(index),
                                                            }
                                                        ] : []}
                                                    />
                                                }>
                                                    操作
                                                </Dropdown.Button>
                                            </Space>
                                        )}
                                    />
                                </Table>
                            ) : (
                                <Typography.Text className="ant-form-text" type="secondary">
                                    ( <SmileOutlined /> 暂无添加数据。 )
                                </Typography.Text>
                            );
                        }}
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="button" onClick={onAddClick}>
                            添加明细数据
                        </Button>
                    </Form.Item>
                </Form>
            </Form.Provider>
        </Modal>
    );
}

interface ModalFormProps {
    data?: CompensationFreeFormData,
    djxhid: string,
    bmbh?: number,
    open: boolean;
    onCancel: () => void;
}

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, open }: { form: FormInstance; open: boolean }) => {
    const prevOpenRef = useRef<boolean>();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;

    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const DutyFreeItemModalForm: React.FC<ModalFormProps> = ({ open, onCancel, data, djxhid, bmbh }) => {
    const selectListResult = useGetDutyFreeSelectListQuery({
        djxhid,
        bmbh
    }, { refetchOnMountOrArgChange: true, refetchOnReconnect: true });
    const [selectList, setSelectList] = useState<CompensationFreeSelectListData[]>([]);
    useEffect(() => {
        if (selectListResult.isSuccess) {
            setSelectList(selectListResult.data.data.list);
        }
    }, [selectListResult]);

    useEffect(() => {
        if (selectListResult.isError) selectListResult.refetch();
    }, [open]);

    const [form] = Form.useForm<CompensationFreeFormData>();
    useEffect(() => {
        if (open && data) {
            form.setFieldsValue(data);
        } else {
            form.resetFields();
        }
    }, [open]);

    useResetFormOnCloseModal({
        form,
        open,
    });

    const onOk = () => {
        let rawForm: CompensationFreeFormData = form.getFieldsValue(true);
        let newForm: CompensationFreeFormData = {
            ...rawForm,
        }
        form.setFieldsValue(newForm);
        form.submit();
    };

    const [secondList, setSecondList] = useState<CompensationFreeSelectListData[]>([]);
    const handleFirstChange = (value: string) => {
        setSecondList(selectList.find(item => item.value === value)?.children ?? []);
        form.resetFields(["jmxz"]);
    };

    return (
        <Modal
            title="明细数据"
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            maskClosable={false}
        >
            <Form form={form} layout="vertical" name="dutyFreeItemForm">
                <Form.Item
                    label="免税事项"
                    name="jmsx"
                    rules={[{ required: true, message: '请选择免税事项' }]}
                >
                    <Select
                        style={{ width: '100%' }}
                        onChange={handleFirstChange}
                        options={selectList}
                    />
                </Form.Item>

                <Form.Item
                    label="免税性质"
                    name="jmxz"
                    rules={[{ required: true, message: '请选择免税性质' }]}
                >
                    <Select
                        style={{ width: '100%' }}
                        options={secondList}
                    />
                </Form.Item>

                <Form.Item
                    label="扣除金额"
                    name='sjkcje'
                    rules={[{ required: true, message: '请填写扣除金额' }]}
                >
                    <InputNumber style={{ 'width': '100%' }} min={0} precision={2} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditCompensationDutyFreeFormModal;