import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

// antd
import { Button, Form, Input, message, Select } from 'antd';

// interface
import { RegisterEnterpriseForm } from '../../../interfaces/user/register';

// apis
import { useEnterpriseApplyMutation } from '../../../store/apis/user';
import { useGetDictListQuery, useLazyGetIndustryListQuery } from '../../../store/apis/common';

// style
import '../login.scss';
import { LocationCascader } from '../../../components/form/locationCascader';
import './register.scss';

const { Option } = Select;

/**
 * 注册员工页
 */
function RegisterEnterprise() {
    // 路由
    const navigate = useNavigate();

    // 表单实例
    const [form] = Form.useForm<RegisterEnterpriseForm>();

    // 表单提交
    const [submitTrigger, submitResult] = useEnterpriseApplyMutation();
    useEffect(() => {
        if (submitResult.isSuccess) {
            message.success(submitResult.data.msg);
            navigate('/login', { replace: true });
        }
    }, [submitResult]);
    const onSubmit = async (values: RegisterEnterpriseForm) => {
        let _form = form.getFieldsValue(true);
        console.log('Received values of form: ', _form);
        submitTrigger({
            ..._form,
        })
    };

    const onLocationChange = (value: number[]) => {
        form.setFieldsValue({
            province_id: value[0],
            city_id: value[1],
            area_id: value[2],
            street_id: value[3]
        });
    }

    return (
        // 流式布局
        <div className='login-form register'>
            {/* 标题 */}
            <div className='login-form__title'>企业注册</div>

            {/* 表单 */}
            <Form
                form={form}
                name="registerForm"
                className="login-form__form"
                layout='vertical'
                onFinish={onSubmit}
            >
                {/* 组织名称 */}
                <Form.Item
                    name="name"
                    label="企业名称"
                    rules={[{ required: true, message: '请输入企业名称' }]}
                >
                    <Input placeholder="请输入企业名称" maxLength={50} />
                </Form.Item>

                {/* 所在区域 */}
                <Form.Item
                    name="location_id"
                    label="所在区域"
                    rules={[{ required: true, message: '请选择所在区域' }]}
                >
                    <LocationCascader deep={4} placeholder="请选择所在区域" onChange={onLocationChange} />
                </Form.Item>

                <Form.Item
                    label="统一社会信用代码"
                    name="social_no"
                    rules={[{ required: true, message: '请填写统一社会信用代码' }]}
                >
                    <Input placeholder='统一社会信用代码' maxLength={18} />
                </Form.Item>

                {/* 登录按钮 */}
                <Form.Item>
                    <Button type="primary" htmlType="submit" block className="login-form__form__button" style={{ marginTop: 20 }}>
                        提交审核
                    </Button>
                </Form.Item>
            </Form>
        </div >
    );
}

export default RegisterEnterprise;