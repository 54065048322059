import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

// apis
import { useDownloadUndeclaredSalaryTemplateMutation, useLazyGetSalaryUndeclaredListQuery, useMergeTaxBatchMutation, useUploadUndeclaredSalaryDataMutation } from '../../../../store/apis/tax';

// interface
import { TaxCompanyImportListData, TaxCompanyImportSearchForm } from '../../../../interfaces/tax/tax';

// antd
import { Table, Dropdown, Menu, Button, Input, message, Modal, DatePicker, Form } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// assets
import { useUploadFileMutation } from '../../../../store/apis/common';
import { AvatarImage } from '../../../../components/image/avatar';

const { Column } = Table;
const { confirm } = Modal;

function TaxImportListPage() {
    // 路由
    const navigate = useNavigate();

    // 请求hook
    const [listTrigger, listResult] = useLazyGetSalaryUndeclaredListQuery({ refetchOnReconnect: true });

    // 搜索表单
    const [searchForm, setSearchForm] = useState<TaxCompanyImportSearchForm>({ page: 1, size: 10 });
    const searchFormRef = useRef<TaxCompanyImportSearchForm>(searchForm);
    useEffect(() => { searchFormRef.current = searchForm; }, [searchForm]);

    // 初始化列表
    useEffect(() => {
        listTrigger(searchFormRef.current);
    }, [listTrigger]);

    // 数据填充 listResult
    useEffect(() => {
        if (listResult.isSuccess) {
            console.log('不变的数据', searchFormRef.current);

            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== listResult.data.data.total) {
                    newListData = new Array(listResult.data.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((searchFormRef.current.page! - 1) * searchFormRef.current.size!, listResult.data.data.list.length, ...listResult.data.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        }
    }, [listResult]);

    // 重置表单
    const resetData = () => {
        let form: TaxCompanyImportSearchForm = {
            ...searchFormRef.current,
            period: undefined,
            month: undefined,
        }
        setSearchForm(form);
        listTrigger(form);
    }

    // 分页
    const [listData, setListData] = useState<TaxCompanyImportListData[]>([])
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        setSearchForm((form) => {
            let newForm = {
                ...form,
                page,
                size: pageSize,
            };
            listTrigger(newForm);
            return newForm;
        })
    };


    // 月份筛选
    const handleMonthChange = (value: dayjs.Dayjs | null, dateString: string) => {
        console.log(`date: ${value}`);
        let form = {
            ...searchFormRef.current,
            month: value!.format('YYYY-MM')
        }
        setSearchForm(form)
        listTrigger(form);
    }

    // 薪酬所属期筛选
    const handleTaxPeriodChange = (value: dayjs.Dayjs | null, dateString: string) => {
        console.log(`date: ${value}`);
        let form = {
            ...searchFormRef.current,
            period: value!.format('YYYY-MM')
        }
        setSearchForm(form)
        listTrigger(form);
    }

    // 下载模板
    const [templateTrigger, templateResult] = useDownloadUndeclaredSalaryTemplateMutation();
    useEffect(() => {
        if (templateResult.isSuccess) {
            window.open(templateResult.data.data.full_link, '_blank');
        }
    }, [templateResult]);
    const download = () => {
        templateTrigger();
    };

    // 导入薪酬
    const [importTrigger, importResult] = useUploadUndeclaredSalaryDataMutation();
    useEffect(() => {
        if (importResult.isSuccess) {
            if (importResult.data.data.fail) {
                confirm({
                    title: '导入申报工资失败',
                    icon: <ExclamationCircleOutlined />,
                    content: '是否下载失败结果文档',
                    onOk() {
                        window.open(importResult.data.data.full_link, '_blank');
                    },
                });
            } else {
                message.success(importResult.data.msg);
            }
            listTrigger(searchFormRef.current);
        }
    }, [importResult]);

    // 上传点击框ref
    const inputRef = React.createRef<HTMLInputElement>();
    // 上传文件
    const [uploadTrigger, uploadResult] = useUploadFileMutation();
    useEffect(() => {
        if (uploadResult.isSuccess) {
            // 获取文件id传递给导入接口
            importTrigger({ file_id: uploadResult.data.data.file_id });
        }
    }, [uploadResult, importTrigger]);
    const upload = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        // 上传到服务器
        uploadTrigger({ file: event.target.files![0], type: 'salary' });
    };


    return (
        <div className='address menu inner-page'>
            <div className='menu__title'>导入申报工资列表</div>

            <div className='search'>
                <DatePicker
                    className="search__item"
                    value={searchForm.period ? dayjs(searchForm.period) : null}
                    onChange={handleTaxPeriodChange}
                    placeholder='税务所属期筛选'
                    picker="month" />
                <DatePicker
                    allowClear={false}
                    className="search__item"
                    value={searchForm.month ? dayjs(searchForm.month) : null}
                    onChange={handleMonthChange}
                    placeholder='薪酬所属期筛选'
                    picker="month" />
                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={listResult.isLoading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>
                <Dropdown.Button
                 trigger={['click', 'hover']}
                    className="search__last-button"
                    type="primary"
                    overlay={
                        <Menu
                            items={[
                                {
                                    key: 'download',
                                    label: (
                                        <div>下载模板</div>
                                    ),
                                    onClick: () => download(),
                                },
                                {
                                    key: 'import',
                                    label: (
                                        <div>导入薪酬</div>
                                    ),
                                    onClick: () => {
                                        if (inputRef.current) {
                                            inputRef.current.value = '';
                                            inputRef.current.click();
                                        }
                                    },
                                },
                            ]}
                        />
                    }>
                    更多操作
                </Dropdown.Button>
            </div>
            <Table
                dataSource={listData}
                loading={listResult.isLoading}
                rowKey={(record: TaxCompanyImportListData) => record?.id}
                pagination={{
                    position: ['bottomRight'],
                    size: "small",
                    total: listResult.data?.data.total ?? 0,
                    showTotal: total => `共 ${total} 项`,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    onChange: onPageChange
                }}>
                <Column
                    title="头像"
                    dataIndex={['staff', 'avatar_link']}
                    key="avatar_link"
                    width={60}
                    render={(avatar: string) => (
                        <AvatarImage url={avatar} />
                    )}
                />
                <Column title="姓名" dataIndex={['staff', 'name']} key="name" />
                <Column title="薪资" dataIndex="actual_pay" key="actual_pay" />
                <Column title="薪酬所属期" dataIndex="month" key="month" />
                <Column title="税务所属期" dataIndex="period" key="period" />
            </Table>

            {/* 导入上传input */}
            {
                <input
                    ref={inputRef}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={upload}
                />
            }
        </div>
    );
}

export default TaxImportListPage;