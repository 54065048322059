import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

// antd
import { Button, message, Modal } from 'antd';
import { ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// interface
import { RegisterStaffForm } from '../../../interfaces/user/register';

// apis
import { useRevokeEnterpriseApplyMutation, useRevokeEntryApplyMutation } from '../../../store/apis/user';

const { confirm } = Modal;

/**
 * 企业注册等待审核页
 */
function RegisterEnterprisePending() {
    // 路由
    const navigate = useNavigate();

    // 提交撤销
    const [submitTrigger, submitResult] = useRevokeEnterpriseApplyMutation();
    useEffect(() => {
        if (submitResult.isSuccess) {
            message.success(submitResult.data.msg);
            navigate('/login', { replace: true });
        } else if (submitResult.isError) {
            navigate('/login', { replace: true });
        }
    }, [submitResult]);
    const onSubmit = () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确定要撤销审核吗？',
            onOk() {
                submitTrigger();
            },
        });
    };

    return (
        // 流式布局
        <div style={{ color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: 150 }}>
                <ClockCircleOutlined size={200} />
            </div>
            <div style={{ fontSize: 24 }}>等待审核中</div>
            <div style={{ marginTop: 10, color: '#cccccc' }}>审核通过后需<Button type="link" size='small' onClick={() => navigate('/login', { replace: true })}>重新登陆</Button></div>
            <div style={{ marginTop: 10, color: '#cccccc' }}>客服电话：0757-82587070</div>
            <Button type="primary" danger size='large' style={{ marginTop: 200 }} onClick={onSubmit}>
                撤销审核
            </Button>
        </div>
    );
}

export default RegisterEnterprisePending;