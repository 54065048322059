import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// antd
import { Badge } from 'antd';

// assets
import logo from "@assets/logo2.png";
import indexIcon from "@assets/navigator/index.svg";
import addressIcon from "@assets/navigator/address.svg";
import functionIcon from "@assets/navigator/function.svg";
import mineIcon from "@assets/navigator/mine.svg";
import messageIcon from "@assets/navigator/message.svg";
import indexBlueIcon from "@assets/navigator/index_blue.svg";
import addressBlueIcon from "@assets/navigator/address_blue.svg";
import functionBlueIcon from "@assets/navigator/function_blue.svg";
import mineBlueIcon from "@assets/navigator/mine_blue.svg";
import messageBlueIcon from "@assets/navigator/message_blue.svg";

// scss
import './navigator.scss';
import { useGetUnreadMsgNumMutation } from '../../store/apis/home';



/**
 * 侧边导航条组件
 */
function NavigatorBar() {
    // 获取当前路径并激活对应模块
    const location = useLocation();
    const path = `/${location.pathname.split('/')[1]}`;
    console.log(`当前路径: ${path}`);

    // 导航栏
    const [navList, setNavList] = useState([
        {
            name: '首页',
            path: '/home',
            icon: indexIcon,
            activeIcon: indexBlueIcon,
            badge: 0
        },
        {
            name: '企业税务注册',
            path: '/tax_register',
            icon: functionIcon,
            activeIcon: functionBlueIcon,
            badge: 0
        },
        {
            name: '人员信息采集',
            path: '/tax_staff',
            icon: addressIcon,
            activeIcon: addressBlueIcon,
            badge: 0
        },
        {
            name: '综合所得申报',
            path: '/tax_report',
            icon: mineIcon,
            activeIcon: mineBlueIcon,
            badge: 0
        },
        {
            name: '税务缴费',
            path: '/tax_pay',
            icon: messageIcon,
            activeIcon: messageBlueIcon,
            badge: 0
        },
    ]);

    // 获取未读消息条目
    const [msgNumTrigger, msgNumResult] = useGetUnreadMsgNumMutation()
    useEffect(() => {
        // msgNumTrigger({});

        // // 轮询获取数据
        // let timer = setInterval(() => {
        //     msgNumTrig ger({});
        // }, 30000);
        // return () => {
        //     clearInterval(timer);
        // }
    }, [msgNumTrigger]);
    // 设置到导航栏的badge
    useEffect(() => {
        if (msgNumResult.isSuccess) {
            setNavList((navList) => {
                let newNavList = [...navList];
                newNavList[0].badge = msgNumResult.data.data.count;
                return newNavList;
            })
        }
    }, [msgNumResult]);

    // 组装
    const navItems: JSX.Element[] = navList.map(e => {
        return (
            <Link className={`navigator__list__item ${path === e.path ? 'active' : ''}`} to={e.path} key={e.path}>
                <Badge offset={[-10, 10]} count={e.badge} overflowCount={99}>
                    <img className='navigator__list__item__img' src={path === e.path ? e.activeIcon : e.icon} alt={e.name} />
                </Badge>
                <div className='navigator__list__item__title'>{e.name}</div>
            </Link>
        )
    });

    return (
        <div className='navigator'>
            {/* 列表 */}
            <div className='navigator__list'>{navItems}</div>
        </div>
    );
}
export default NavigatorBar;