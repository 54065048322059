// redux
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearToken, clearUserInfo } from '../../store/user';

// antd
import { Dropdown, Menu, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// style
import './shortcutAccess.scss'

// router
import { Link, useNavigate } from 'react-router-dom';

// icons
import commonIcon from '@assets/icons/mine/common.svg';
import logoutIcon from '@assets/icons/mine/logout.svg';

// assets
import { AvatarImage } from '../image/avatar';

// 确认弹框
const { confirm } = Modal;

const dropdownItemStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px'
}

function ShortcutAccess() {
    // redux方法
    const dispatch = useAppDispatch();
    // 路由
    const navigate = useNavigate();

    const userInfo = useAppSelector((state) => state.user.userInfo);

    const logout = () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确定要退出登录吗？',
            onOk() {
                dispatch(clearToken());
                dispatch(clearUserInfo());
                navigate("/login", { replace: true });
            },
        });
    }

    const menu = (
        <Menu
            items={[
                // {
                //     key: '1',
                //     label: (
                //         <Link to='/mine/common' style={dropdownItemStyle}>
                //             <img src={commonIcon} alt="通用" />
                //             <div style={{ marginLeft: '10px' }}>通用</div>
                //         </Link>
                //     ),
                // },
                {
                    key: '2',
                    label: (
                        <a style={dropdownItemStyle} onClick={logout}>
                            <img src={logoutIcon} alt="退出登录" />
                            <div style={{ marginLeft: '10px' }}>退出登录</div>
                        </a>
                    ),
                },
            ]}
        />
    );

    return (
        <Dropdown overlay={menu}>
            <a className='shortcut'>
                <div className='shortcut__name'>{userInfo?.name ?? '未登录'}</div>
                <AvatarImage />
            </a>
        </Dropdown>
    );
}

export default ShortcutAccess;