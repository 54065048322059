import { useState } from "react";

// component
import PageLayout from "../../layout/pageLayout";

// router
import { Outlet } from "react-router-dom";

// assets
import batchImg from '@assets/icons/function/tax/batch.svg';
import lastMonthImportImg from '@assets/icons/function/tax/last_month_import.svg';
import reportImg from '@assets/icons/function/tax/report.svg';
import deductImg from '@assets/icons/function/salary/deduct.svg';

// antd
import { IMenuItem, MenuList } from "../../components/menu/menuList";

/**
 * 企业设置页
 */
function TaxReportSettings() {
    const left = <TaxSettingsList></TaxSettingsList>;
    const right = <Outlet />;

    return (
        <PageLayout leftComponent={left} rightComponent={right} flex={[3, 5]}></PageLayout>
    );
}


function TaxSettingsList() {
    // 动态显示内容
    const [items, setItems] = useState<IMenuItem[]>(
        [
            {
                title: '综合所得申报',
                items: [
                    {
                        title: '一次性补偿金',
                        img: deductImg,
                        path: '/tax_report/compensation',
                        display: true,
                        canUse: true,
                    }, {
                        title: '全年一次性奖金',
                        img: deductImg,
                        path: '/tax_report/annualBonus',
                        display: true,
                        canUse: true,
                    }, {
                        title: '薪酬批次列表',
                        img: batchImg,
                        path: '/tax_report/batch',
                        display: true,
                        canUse: true,
                    }, {
                        title: '税务报送列表',
                        img: reportImg,
                        path: '/tax_report/report',
                        display: true,
                        canUse: true,
                    }, 
                    // {
                    //     title: '上月未申报薪资导入',
                    //     img: lastMonthImportImg,
                    //     path: '/tax_report/lastMonthImport',
                    //     display: true,
                    //     canUse: true,
                    // }
                ]
            },
        ]
    );

    return (
        <div className="menu">
            <MenuList items={items} />
        </div>
    );
}

export default TaxReportSettings;