import { createBrowserRouter, Navigate, Route, RouteObject } from "react-router-dom";
import DefaultLayout from "../layout/defaultLayout";
import { NothingPage } from "../view/common/nothing";
import { WaitingPage } from "../view/common/waiting";
import Home from "../view/home/home";
import Login from "../view/login/login";
import Mine from "../view/mine/mine";
import { NotFoundPage } from "../view/common/404";
import TaxRegisterPage from "../view/tax/register/register";
import TaxPasswordPage from "../view/tax/register/password";
import TaxRegistryNoConfigPage from "../view/tax/register/registryNoConfig/registryNoConfig";
import TaxConfirmationListPage from "../view/tax/report/confirmation/taxConfirmation";
import TaxStaffListPage from "../view/tax/report/staff/taxStaff";
import TaxBatchListPage from "../view/tax/salary/batch/taxBatch";
import TaxReportListPage from "../view/tax/salary/report/salaryReport";
import TaxImportListPage from "../view/tax/report/import/taxImport";
import TaxPaymentListPage from "../view/tax/payment/taxPayment";
import RegisterWizard from "../view/login/register/wizard";
import RegisterEnterprise from "../view/login/register/registerEnterprise";
import RegisterEnterprisePending from "../view/login/register/registerEnterprisePending";
import TaxPaymentLogListPage from "../view/tax/payment/taxPaymentLogs";
import TaxCompensationListPage from "../view/tax/salary/compensation/compensation";
import ChangePasswordForm from "../view/mine/account/changePassword";
import FeedbackFormPage from "../view/mine/feedback/feedback";
import TaxAnnualBonusListPage from "../view/tax/salary/annualBonus/annualBonus";
import { WarnPage } from "../view/common/warn";
import TaxStaffSettings from "../view/tax/taxStaffSettings";
import TaxRegisterSettings from "../view/tax/taxRegisterSettings";
import TaxReportSettings from "../view/tax/taxReportSettings";
import TaxPaySettings from "../view/tax/taxPaySettings";
import TaxStaffAdjustListPage from "../view/tax/report/staff/taxStaffAdjust";
interface IRoute {
    path: string | undefined;
    name: string;
    element: JSX.Element;
    children?: IRoute[];
}

/**
 * 路由统一配置
 */
export const routes: IRoute[] = [
    {
        path: '/',
        name: '首页',
        element: <Navigate to={'/home'}></Navigate>,
    }, {
        path: '/home',
        name: '首页',
        element: <DefaultLayout><Home /></DefaultLayout>,

    }, {
        path: '/login',
        name: '登录',
        element: <Login />,
    }, {
        path: '/register',
        name: '注册',
        element: <RegisterWizard />,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <Navigate to={'/register/enterprise'}></Navigate>,
            },
            {
                path: 'enterprise',
                name: '企业注册',
                element: <RegisterEnterprise />,
            },
            {
                path: 'enterprisePending',
                name: '企业注册等待审批',
                element: <RegisterEnterprisePending />,
            }
        ]
    }, {
        path: '/mine',
        name: '我的',
        element: <DefaultLayout><Mine /></DefaultLayout>,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <WaitingPage />,
            },
            {
                path: 'changePassword',
                name: '修改密码',
                element: <ChangePasswordForm />,
            },
            {
                path: 'common',
                name: '通用',
                element: <NothingPage />,
            },
            {
                path: 'help',
                name: '帮助',
                element: <NothingPage />,
            },
            {
                path: 'feedback',
                name: '意见反馈',
                element: <FeedbackFormPage />,
            },
        ]
    }, {
        path: '/tax_register',
        name: '企业税务注册',
        element: <DefaultLayout><TaxRegisterSettings /></DefaultLayout>,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <WaitingPage />,
            },
            {
                path: 'register',
                name: '企业注册',
                element: <TaxRegisterPage />
            }, {
                path: 'password',
                name: '申报密码设置',
                element: <TaxPasswordPage />
            }, {
                path: 'registryNoConfig',
                name: '登记号配置',
                element: <TaxRegistryNoConfigPage />
            }
        ]
    }, {
        path: '/tax_staff',
        name: '人员信息采集',
        element: <DefaultLayout><TaxStaffSettings /></DefaultLayout>,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <WaitingPage />,
            },
            {
                path: 'confirmation',
                name: '报税确认表',
                element: <TaxConfirmationListPage />
            }, {
                path: 'staff',
                name: '员工采集信息',
                element: <TaxStaffListPage />
            }, {
                path: 'preDeductSettings',
                name: '预扣预缴税款设置',
                element: <TaxStaffAdjustListPage />
            },
        ]
    }, {
        path: '/tax_report',
        name: '综合所得申报',
        element: <DefaultLayout><TaxReportSettings /></DefaultLayout>,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <WaitingPage />,
            },
            {
                path: 'batch',
                name: '薪酬批次列表',
                element: <TaxBatchListPage />
            }, {
                path: 'report',
                name: '税务报送列表',
                element: <TaxReportListPage />
            }, {
                path: 'lastMonthImport',
                name: '上月未申报薪资导入',
                element: <TaxImportListPage />
            }, {
                path: 'compensation',
                name: '一次性补偿金',
                element: <TaxCompensationListPage />
            }, {
                path: 'annualBonus',
                name: '全年一次性奖金',
                element: <TaxAnnualBonusListPage />
            }
        ]
    }, {
        path: '/tax_pay',
        name: '税务缴费',
        element: <DefaultLayout><TaxPaySettings /></DefaultLayout>,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <WaitingPage />,
            }, {
                path: 'pay',
                name: '税务缴费',
                element: <TaxPaymentListPage />
            }, {
                path: 'payLogs',
                name: '缴费记录',
                element: <TaxPaymentLogListPage />
            }
        ]
    }, {
        path: 'warn',
        name: '浏览器版本过低',
        element: <WarnPage />
    }, {
        path: '*',
        name: '无页面',
        element: <NotFoundPage />
    }
]

// 遍历路由树生成route列表
function getRouteList(routes: IRoute[] | undefined): RouteObject[] {
    if (!routes) return [];

    return routes.map(route => {
        let item: any = {
            path: route.path,
            element: route.element,
        };
        if (route.name === 'index') {
            item.index = true;
        } else {
            item.children = getRouteList(route.children);
        }
        return item;
    });
}

// 构建router
export const router = createBrowserRouter(getRouteList(routes));