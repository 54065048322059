import { Grid, Modal } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

export interface IMenuItemContent {
    title: React.ReactNode,
    img: string,
    path: string,
    display: boolean,
    canUse: boolean,
    ref?: React.MutableRefObject<any>,
}

export interface IMenuItem {
    title: string,
    items: IMenuItemContent[],
};

const { useBreakpoint } = Grid;

export function MenuList({ items, action }: { items?: IMenuItem[], action?: React.ReactNode[] }) {
    // 响应式
    const screens = useBreakpoint();
    // 地址栏
    const location = useLocation();

    const navigate = useNavigate();
    const toNav = (item: IMenuItemContent) => {
        if (item.canUse) {
            navigate(item.path);
        } else {
            Modal.info({
                title: '无法使用',
                content: '暂无使用权限，请联系市场部人员进行开通！谢谢！',
                onOk() { },
            });
        }
    }

    return (
        <>
            {
                items?.filter(block => block.items.some(item => item.display)).map((block, index) => (
                    <>
                        <div className="menu__title">
                            {block.title}
                            {action && action.length > index && action[index]}
                        </div>
                        {
                            <div className={`menu__list ${screens.xxl ? '' : 'sm'}`}>
                                {
                                    block.items.filter(item => item.display).map(item => (
                                        <div ref={item.ref} onClick={() => toNav(item)} className={`menu__item ${location.pathname === item.path ? 'active' : ''}`} >
                                            <img className="menu__item__img" src={item.img} />
                                            <div className="menu__item__name">{item.title}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </>
                ))
            }
        </>
    );
}