import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import { message } from "antd";
import { router } from "../../routes/routes";
import { RootState } from "../store";
import { setToken } from "../user";

// 拦截器
const baseQueryWithIntercept: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // 获取token
    const token = (api.getState() as RootState).user.token;
    console.log(`token: ${token}`);

    // if (!token) {
    //     router.navigate('/login', { replace: true });
    //     return Promise.reject('Token Not Found');
    // }

    // 执行基础查询
    const result: QueryReturnValue<
        any,
        FetchBaseQueryError,
        FetchBaseQueryMeta
    > = await fetchBaseQuery({
        // 请求域名
        baseUrl: process.env.REACT_APP_BASE_URL,
        // 请求头
        prepareHeaders: async (headers, api) => {
            // 平台
            headers.set('Platform', 'web');
            // 版本号
            headers.set('Version', process.env.REACT_APP_VERSION!);
            // token
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    })(args, api, extraOptions);

    // 后处理
    console.log(result, '查询结果');
    const { data, error, meta } = result;
    const { request } = meta as FetchBaseQueryMeta;
    // const url: string = request.url;

    // 状态码错误
    // 可能的情况为：服务器问题 请求有误 网络问题 
    if (error) {
        message.error(`网络错误：${meta?.response?.status}`);
        return Promise.reject(result);
    }

    // code错误
    // 一般是要做特殊处理的问题，例如：token失效，版本号错误，参数错误等
    if (data?.code !== 200) {
        console.log({ request: request, data: data });

        // 根据条件判断
        // token过期返回登录界面
        if (data?.code === 401) {
            // 失效跳转至登录页
            message.error(data?.msg ?? '登录状态失效，请重新登录');
            router.navigate('/login', { replace: true });
        }


        if (data?.code === 402) {
            // 被禁止访问跳转至登录页
            message.error(data?.msg ?? '被禁止访问');
            router.navigate('/login', { replace: true });
        }

        // 未注册入职
        else if (data?.code === 450) {
            // 跳转至登录页
            message.error('暂未入职或已离职，请重新申请入职');
            router.navigate('/login', { replace: true });
        }

        // 过时的版本请求被拦截
        else if (data?.code === 433) {
            // 这个状态不应该在web端出现，若见此code请联系后端处理
            message.error(data.msg);
            console.error('不应出现的code:433');
        }

        // 全局提示
        else {
            message.error(data.msg);
        }

        return Promise.reject(result);
    }

    // token自动更新
    const newToken = meta?.response?.headers.get('Token');
    if (newToken) {
        api.dispatch(setToken(newToken));
        console.log(`更新了新的token：${newToken}`);
    }

    return result;
};

export const baseApi = createApi({
    baseQuery: baseQueryWithIntercept,
    reducerPath: 'baseApi',
    endpoints: () => ({}),
});
