import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'ahooks';

// apis
import { useAddCompanyTaxStaffConfirmMutation, useCancelCompanyTaxStaffConfirmMutation, useLazyGetCompanyTaxRegisterConfigDetailQuery, useLazyGetCompanyTaxStaffCheckListQuery } from '../../../../store/apis/tax';
import { useLazyGetDepartmentListQuery } from '../../../../store/apis/company';

// interface
import { TaxCompanyRegisterConfigInfoOption, TaxCompanyStaffConfirmationListData, TaxCompanyStaffConfirmationListSearchForm } from '../../../../interfaces/tax/tax';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, Input, message, Modal } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// assets
import { DepartmentData } from '../../../../interfaces/company/department';
import { AvatarImage } from '../../../../components/image/avatar';

const { Column } = Table;
const { Option } = Select;
const { confirm } = Modal;

function TaxConfirmationListPage() {
    // 请求hook
    const [listTrigger, listResult] = useLazyGetCompanyTaxStaffCheckListQuery({ refetchOnReconnect: true });

    // 搜索表单
    const [searchForm, setSearchForm] = useState<TaxCompanyStaffConfirmationListSearchForm>({});
    const searchFormRef = useRef<TaxCompanyStaffConfirmationListSearchForm>(searchForm);
    useEffect(() => { searchFormRef.current = searchForm; }, [searchForm]);

    // 初始化列表
    useEffect(() => {
        listTrigger(searchFormRef.current);
    }, [listTrigger]);

    // 重置表单
    const resetData = () => {
        let form: TaxCompanyStaffConfirmationListSearchForm = {
            bmbh: undefined,
            keywords: undefined,
        }
        setSearchForm(form);
        listTrigger(form);
    }

    // 部门列表（筛选）
    const [departmentTrigger, departmentResult] = useLazyGetCompanyTaxRegisterConfigDetailQuery({ refetchOnReconnect: true });
    const [departmentList, setDepartmentList] = useState<TaxCompanyRegisterConfigInfoOption[]>([]);
    useEffect(() => {
        if (departmentResult.isSuccess) {
            setDepartmentList(departmentResult.data!.data.detail.options);
            if (departmentResult.data!.data.detail.options.length > 0) {
                let form = {
                    ...searchFormRef.current,
                    bmbh: departmentResult.data!.data.detail.options[0].bmbh
                }
                setSearchForm(form);
                listTrigger(form);
            }
        }
    }, [departmentResult]);
    // 事件
    const handleDepartmentChange = (value: number) => {
        console.log(`selected ${value}`);

        let form = {
            ...searchFormRef.current,
            bmbh: value
        }
        setSearchForm(form);
        listTrigger(form);
    };


    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(searchForm.keywords, { wait: 500 });
    useEffect(() => {
        listTrigger(searchForm);
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let form = {
            ...searchFormRef.current,
            keywords: value.target.value
        };
        setSearchForm(form);
    }

    // 列表数据
    let [list, setList] = useState<TaxCompanyStaffConfirmationListData[]>([]);
    useEffect(() => {
        if (listResult.isSuccess) {
            console.log('请求结果：', listResult);
            setList(listResult.data!.data.list);
        }
    }, [listResult]);

    // table选中
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // 确认
    const [confirmTrigger, confirmResult] = useAddCompanyTaxStaffConfirmMutation();
    useEffect(() => {
        if (confirmResult.isSuccess) {
            message.success(confirmResult.data.msg);
            if (confirmResult.data.data.error_list && confirmResult.data.data.error_list.length > 0) {
                confirm({
                    width: '50%',
                    title: '确认失败数据',
                    icon: <ExclamationCircleOutlined />,
                    content: <>{confirmResult.data.data.error_list.map(error => <div>{error}</div>)}</>,
                    okCancel: false,
                });
            }
            listTrigger(searchFormRef.current);
        }
    }, [confirmResult, listTrigger]);
    // 默认传递勾选的条目id
    const toConfirm = (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        let tip = '确定进行确认吗?';
        if (ids.length === 0) {
            tip = '确定全部进行确认吗？'
        }
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: tip,
            onOk() {
                return new Promise((resolve, reject) => {
                    confirmTrigger({
                        staff_ids: ids,
                    }).then(resolve).catch(reject);
                }).catch(() => console.log('Oops errors!'));
            },
        });
    }

    // 取消
    const [cancelTrigger, cancelResult] = useCancelCompanyTaxStaffConfirmMutation();
    useEffect(() => {
        if (cancelResult.isSuccess) {
            if (cancelResult.originalArgs?.step === 1) {
                if (cancelResult.data.data.error_list && cancelResult.data.data.error_list.length > 0) {
                    confirm({
                        width: '50%',
                        title: '风险操作提醒',
                        icon: <ExclamationCircleOutlined />,
                        content: <>{cancelResult.data.data.error_list.map(error => <div>{error}</div>)}</>,
                        okText: '继续',
                        cancelText: '放弃操作',
                        onOk: () => cancelTrigger({ step: 2, staff_ids: cancelResult.originalArgs!.staff_ids })
                    });
                } else {
                    cancelTrigger({ step: 2, staff_ids: cancelResult.originalArgs!.staff_ids });
                }
            } else {
                message.success(cancelResult.data.msg);
                if (cancelResult.data.data.error_list && cancelResult.data.data.error_list.length > 0) {
                    confirm({
                        width: '50%',
                        title: '取消失败数据',
                        icon: <ExclamationCircleOutlined />,
                        content: <>{cancelResult.data.data.error_list.map(error => <div>{error}</div>)}</>,
                        okCancel: false,
                    });
                }
                listTrigger(searchFormRef.current);
            }
        }
    }, [cancelResult]);
    // 默认传递勾选的条目id
    const toCancel = async (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        let tip = '确定进行取消吗?';
        if (ids.length === 0) {
            tip = '确定全部进行取消吗？'
        }
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: tip,
            onOk() {
                return new Promise((resolve, reject) => {
                    cancelTrigger({
                        step: 1,
                        staff_ids: ids,
                    }).then(resolve).catch(reject);
                }).catch(() => console.log('Oops errors!'));
            },
        });
    }

    return (
        <div className='address menu inner-page'>
            <div className='menu__title'>报税确认表</div>

            <div className='search'>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="选择部门筛选"
                    value={searchForm.bmbh}
                    onChange={handleDepartmentChange}
                >
                    {departmentList.map(department => (
                        <Option key={department.bmbh} value={department.bmbh}>{department.bmmc}</Option>
                    ))}
                </Select>
                <Input
                    className="search__item"
                    value={searchForm.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="关键词搜索"
                />
                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={listResult.isLoading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>
                <Dropdown.Button
                    trigger={['click', 'hover']}
                    className="search__last-button"
                    type="primary"
                    loading={
                        confirmResult.isLoading ||
                        cancelResult.isLoading
                    }
                    overlay={
                        <Menu
                            items={selectedRowKeys.length > 0 ? [
                                {
                                    key: 'generate',
                                    label: (
                                        <div>批量确认</div>
                                    ),
                                    onClick: () => toConfirm(),
                                },
                                {
                                    key: 'retransfer',
                                    label: (
                                        <div>批量取消确认</div>
                                    ),
                                    onClick: () => toCancel(),
                                },
                            ] : [{
                                key: 'accounting',
                                label: (
                                    <div>全部确认</div>
                                ),
                                onClick: () => toConfirm([]),
                            },
                            {
                                key: 'cancelAccounting',
                                label: (
                                    <div>全部取消确认</div>
                                ),
                                onClick: () => toCancel([]),
                            },
                            ]}
                        />
                    }>
                    {selectedRowKeys.length > 0 ? `已选择${selectedRowKeys.length}项` : '批量操作'}
                </Dropdown.Button>
            </div>
            <Table
                dataSource={list}
                loading={listResult.isLoading}
                rowSelection={
                    {
                        selectedRowKeys,
                        onChange: onSelectChange
                    }
                }
                rowKey={(record: TaxCompanyStaffConfirmationListData) => record?.id}
                pagination={{
                    position: ['bottomRight'],
                    size: "small",
                    total: listResult.data?.data.total ?? 0,
                    showTotal: total => `共 ${total} 项`,
                    showSizeChanger: true,
                    showQuickJumper: true,
                }}
                footer={() => listResult.data?.data.tip}
            >
                <Column
                    title="头像"
                    dataIndex="avatar_link"
                    key="avatar_link"
                    width={60}
                    render={(avatar: string) => (
                        <AvatarImage url={avatar} />
                    )}
                />
                <Column title="姓名" dataIndex="name" key="name" />
                <Column title="部门" dataIndex="dept_text" key="dept_text" />
                <Column title="岗位" dataIndex="post_text" key="post_text" />
                <Column title="状态" dataIndex="tax_status_exist_text" key="tax_status_exist_text" />
                <Column
                    title="操作"
                    key="action"
                    render={(_: any, record: TaxCompanyStaffConfirmationListData | undefined) => (
                        <Space>
                            <Dropdown.Button trigger={['click', 'hover']} type="primary" loading={false} overlay={
                                <Menu
                                    items={record ? [
                                        {
                                            key: 'confirm',
                                            label: (
                                                <div>员工确认</div>
                                            ),
                                            onClick: () => toConfirm([record.id]),
                                        },
                                        {
                                            key: 'cancel',
                                            label: (
                                                <div>取消确认</div>
                                            ),
                                            onClick: () => toCancel([record.id]),
                                        },
                                    ] : []}
                                />
                            }>
                                操作
                            </Dropdown.Button>
                        </Space>
                    )}
                />
            </Table>
        </div>
    );
}

export default TaxConfirmationListPage;