import React from "react";

// css
import './pageLayout.scss'

function PageLayout({ leftComponent, rightComponent, flex }
    : {
        leftComponent: React.ReactNode,
        rightComponent: React.ReactNode,
        flex: [number, number]
    }) {
    return (
        <div className="page-layout">
            <div className="layout__left" style={{'flex': flex[0]}}>{leftComponent}</div>
            <div className="layout__right" style={{'flex': flex[1]}}>{rightComponent}</div>
        </div>
    );
}

export default PageLayout;