import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'ahooks';
import dayjs from 'dayjs';

// apis
import {
    useExportTaxReportListMutation,
    useGetCompanyTaxRegisterListQuery,
    useGetTaxReportListMutation,
    useLazyGetCompanyTaxRegisterConfigDetailQuery,
    useTaxReportCancelMutation,
    useTaxReportCorrectMutation,
    useTaxReportDelMutation,
    useTaxReportMutation
} from '../../../../store/apis/tax';

// interface
import { TaxCompanyRegisterConfigInfoOption, TaxCompanyReportListData, TaxCompanyReportSearchForm } from '../../../../interfaces/tax/tax';

// antd
import { Table, Dropdown, Menu, Select, Button, Input, message, Modal, DatePicker, Form } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// assets
import { useNavigate, useSearchParams } from 'react-router-dom';
import { rootStore } from '../../../../store/store';
import { setGlobalParams } from '../../../../store/utils/tax';
import { useAppDispatch } from '../../../../store/hooks';

const { Column } = Table;
const { Option } = Select;
const { confirm } = Modal;

function TaxReportListPage() {
    const dispatch = useAppDispatch();

    // 请求hook
    const [listTrigger, listResult] = useGetTaxReportListMutation();

    // 搜索表单
    const [searchForm, setSearchForm] = useState<TaxCompanyReportSearchForm>({
        page: 1,
        size: 10,
        period: rootStore.getState().tax.period,
    });
    const searchFormRef = useRef<TaxCompanyReportSearchForm>(searchForm);
    useEffect(() => { searchFormRef.current = searchForm; }, [searchForm]);

    // 登记序号
    const registerListResult = useGetCompanyTaxRegisterListQuery(undefined, { refetchOnMountOrArgChange: 60 });
    const handleDjxhidChange = (value: string) => {
        console.log(`selected ${value}`);

        // 缓存
        setGlobalParams({ dispatch, djxhid: value });

        // 部门列表
        departmentTrigger({ djxhid: value });

        let form = {
            ...searchFormRef.current,
            djxhid: value
        }
        setSearchForm(form);
        listTrigger(form);
    }
    // 依赖登记序号初始化列表
    useEffect(() => {
        if (registerListResult.isSuccess && !(searchFormRef.current.djxhid))
            if (registerListResult.data.data.list.length > 0) {
                let djxhid = rootStore.getState().tax.djxhid
                    || registerListResult.data.data.list[0].djxhid;
                handleDjxhidChange(djxhid);
            }
    }, [registerListResult]);

    // 数据填充 listResult
    useEffect(() => {
        if (listResult.isSuccess) {
            console.log('不变的数据', searchFormRef.current);

            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== listResult.data.data.total) {
                    newListData = new Array(listResult.data.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((searchFormRef.current.page! - 1) * searchFormRef.current.size!, listResult.data.data.list.length, ...listResult.data.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        }
    }, [listResult]);

    // 部门列表（筛选）
    const [departmentTrigger, departmentResult] = useLazyGetCompanyTaxRegisterConfigDetailQuery({ refetchOnReconnect: true });
    const [departmentList, setDepartmentList] = useState<TaxCompanyRegisterConfigInfoOption[]>([]);
    useEffect(() => {
        if (departmentResult.isSuccess) {
            setDepartmentList(departmentResult.data!.data.detail.options);
            if (departmentResult.data!.data.detail.options.length > 0) {
                let form = {
                    ...searchFormRef.current,
                    bmbh: departmentResult.data!.data.detail.options[0].bmbh
                }
                setSearchForm(form);
                listTrigger(form);
            }
        }
    }, [departmentResult]);
    // 事件
    const handleDepartmentChange = (value: number) => {
        console.log(`selected ${value}`);
        let form = {
            ...searchFormRef.current,
            bmbh: value
        }
        setSearchForm(form);
        listTrigger(form);
    };


    // 分页
    const [listData, setListData] = useState<TaxCompanyReportListData[]>([])
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        setSearchForm((form) => {
            let newForm = {
                ...form,
                page,
                size: pageSize,
            };
            listTrigger(newForm);
            return newForm;
        })
    };

    // 薪酬所属期筛选
    const handleTaxPeriodChange = (value: dayjs.Dayjs | null) => {
        console.log(`date: ${value}`);
        let form = {
            ...searchFormRef.current,
            period: value!.format('YYYY-MM')
        }
        setSearchForm(form)
        listTrigger(form);
    }

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(searchForm.keywords, { wait: 500 });
    useEffect(() => {
        if (searchFormRef.current.djxhid) {
            listTrigger(searchFormRef.current);
        }
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let form = {
            ...searchFormRef.current,
            keywords: value.target.value
        };
        setSearchForm(form);
    }

    // 申报
    const [reportTrigger, reportResult] = useTaxReportMutation();
    useEffect(() => {
        if (reportResult.isSuccess) {
            message.success(reportResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [reportResult]);
    const toReport = () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: (
                <div>
                    <p>登记序号：{searchFormRef.current.djxhid}</p>
                    <p>税务所属期：{searchFormRef.current.period}</p>
                    <p>申报人数：{listResult.data?.data.report_num}</p>
                    <p>申报金额：{listResult.data?.data.report_money}</p>
                    <p>申报税费：{listResult.data?.data.report_tax}</p>
                    <p>确定进行申报吗？</p>
                </div>
            ),
            onOk() {
                return new Promise((resolve, reject) => {
                    reportTrigger({
                        djxhid: searchForm.djxhid!,
                        period: searchForm.period!,
                        bmbh: searchForm.bmbh
                    }).then(resolve).catch(reject);
                }).catch(() => console.log('Oops errors!'));
            },
        });
    }

    // 作废
    const [cancelTrigger, cancelResult] = useTaxReportCancelMutation();
    useEffect(() => {
        if (cancelResult.isSuccess) {
            message.success(cancelResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [cancelResult]);
    const toCancel = () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: (
                <div>
                    <p>登记序号：{searchFormRef.current.djxhid}</p>
                    <p>税务所属期：{searchFormRef.current.period}</p>
                    <p>确定进行作废吗？</p>
                </div>
            ),
            onOk() {
                return new Promise((resolve, reject) => {
                    cancelTrigger({
                        djxhid: searchForm.djxhid!,
                        period: searchForm.period!,
                        bmbh: searchForm.bmbh
                    }).then(resolve).catch(reject);
                }).catch(() => console.log('Oops errors!'));
            },
        });
    }

    // 更正
    const [correctTrigger, correctResult] = useTaxReportCorrectMutation();
    useEffect(() => {
        if (correctResult.isSuccess) {
            message.success(correctResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [correctResult]);
    const toCorrect = () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: (
                <div>
                    <p>登记序号：{searchFormRef.current.djxhid}</p>
                    <p>税务所属期：{searchFormRef.current.period}</p>
                    <p>确定进行更正吗？</p>
                </div>
            ),
            onOk() {
                return new Promise((resolve, reject) => {
                    correctTrigger({
                        djxhid: searchForm.djxhid!,
                        period: searchForm.period!,
                        bmbh: searchForm.bmbh
                    }).then(resolve).catch(reject);
                }).catch(() => console.log('Oops errors!'));
            },
        });
    }

    // 删除
    const [deleteTrigger, deleteResult] = useTaxReportDelMutation();
    useEffect(() => {
        if (deleteResult.isSuccess) {
            message.success(deleteResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [deleteResult]);
    const toDel = () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: (
                <div>
                    <p>登记序号：{searchFormRef.current.djxhid}</p>
                    <p>税务所属期：{searchFormRef.current.period}</p>
                    <p>确定删除未申报数据吗？</p>
                </div>
            ),
            onOk() {
                return new Promise((resolve, reject) => {
                    deleteTrigger({
                        djxhid: searchForm.djxhid!,
                        period: searchForm.period!,
                        bmbh: searchForm.bmbh
                    }).then(resolve).catch(reject);
                }).catch(() => console.log('Oops errors!'));
            },
        });
    }

    // 导出数据
    const [exportTrigger, exportResult] = useExportTaxReportListMutation();
    useEffect(() => {
        if (exportResult.isSuccess) {
            window.open(exportResult.data.data.full_link, '_blank');
        }
    }, [exportResult]);
    const exportData = () => {
        exportTrigger(searchFormRef.current);
    };

    return (
        <div className='address menu inner-page'>
            <div className='menu__title'>税务报送</div>

            <div className='search'>
                <Select
                    className="search__item"
                    placeholder="登记序号"
                    value={searchForm.djxhid}
                    onChange={handleDjxhidChange}
                    optionLabelProp="label"
                >
                    {
                        registerListResult.data?.data.list.map(item => (
                            <Option value={item.djxhid} label={item.djxhid}>
                                <div>{item.djxhid}</div>
                                <div>{item.qymc}（{item.province_city_text}）</div>
                            </Option>
                        ))
                    }
                </Select>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="选择部门筛选"
                    value={searchForm.bmbh}
                    onChange={handleDepartmentChange}
                >
                    {departmentList.map(department => (
                        <Option key={department.bmbh} value={department.bmbh}>{department.bmmc}</Option>
                    ))}
                </Select>
                <DatePicker
                    className="search__item"
                    value={searchForm.period ? dayjs(searchForm.period) : null}
                    onChange={handleTaxPeriodChange}
                    placeholder='税务所属期筛选'
                    picker="month" />
                <Input
                    className="search__item"
                    value={searchForm.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="关键词搜索"
                />
                <Dropdown.Button
                    trigger={['click', 'hover']}
                    className="search__last-button"
                    type="primary"
                    loading={
                        reportResult.isLoading ||
                        cancelResult.isLoading
                    }
                    overlay={
                        <Menu
                            items={[
                                {
                                    key: 'report',
                                    label: (
                                        <div>全部申报</div>
                                    ),
                                    onClick: () => toReport(),
                                },
                                {
                                    key: 'cancel',
                                    label: (
                                        <div>全部作废</div>
                                    ),
                                    onClick: () => toCancel(),
                                },
                                {
                                    key: 'delete',
                                    label: (
                                        <div>删除未申报</div>
                                    ),
                                    onClick: () => toDel(),
                                },
                                {
                                    key: 'correct',
                                    label: (
                                        <div>更正申报</div>
                                    ),
                                    onClick: () => toCorrect(),
                                },
                                // {
                                //     key: 'export',
                                //     label: (
                                //         <div>导出当前列表</div>
                                //     ),
                                //     onClick: () => exportData(),
                                // },
                            ]}
                        />
                    }>
                    更多操作
                </Dropdown.Button>
            </div>
            <Table
                dataSource={listData}
                loading={listResult.isLoading}
                rowKey={(record: TaxCompanyReportListData) => record?.id}
                pagination={{
                    position: ['bottomRight'],
                    size: "small",
                    total: listResult.data?.data.total ?? 0,
                    showTotal: total => `共 ${total} 项`,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    onChange: onPageChange
                }}
                title={listResult.data?.data.tax_company_report_detail
                    ? () => <>
                        <div>{listResult.data!.data.tax_company_report_detail!.sbztbj_text}</div>
                        {
                            listResult.data!.data.tax_company_report_detail!.remark
                                ? <div>{listResult.data!.data.tax_company_report_detail!.remark}</div>
                                : <></>
                        }
                    </>
                    : undefined}
                footer={() => listResult.data?.data.tip}
            >
                <Column title="姓名" dataIndex="xm" key="xm" />
                <Column title="薪资" dataIndex="tax_money" key="tax_money" />
                <Column title="税务" dataIndex="tax" key="tax" />
                <Column title="备注" dataIndex="remark" key="remark" />
                <Column
                    title="状态"
                    dataIndex="sbztbj_text"
                    key="sbztbj_text"
                    render={(value: string, record: TaxCompanyReportListData) => (
                        <span style={{ color: record?.sbztbj >= 5 ? 'green' : record?.sbztbj >= 3 ? 'red' : 'grey' }}>{value}</span>
                    )} />
                {/* <Column
                    title="操作"
                    key="action"
                    render={(_: any, record: TaxCompanyReportListData | undefined) => (
                        <Space>
                            <Dropdown.Button  trigger={['click', 'hover']} type="primary" loading={false} overlay={
                                <Menu
                                    items={record ? [
                                        {
                                            key: 'confirm',
                                            label: (
                                                <div>员工确认</div>
                                            ),
                                            // onClick: () => toGenerate([record.id]),
                                        },
                                        {
                                            key: 'cancel',
                                            label: (
                                                <div>取消确认</div>
                                            ),
                                            // onClick: () => toRetransfer([record.id]),
                                        },
                                    ] : []}
                                />
                            }>
                                操作
                            </Dropdown.Button>
                        </Space>
                    )}
                /> */}
            </Table>
        </div>
    );
}

export default TaxReportListPage;