type loginTypeItems = 1 | 2;

export const loginType: {
    code: loginTypeItems,
    password: loginTypeItems,
} = {
    code: 1, // 验证码
    password: 2, // 密码
};

// 登录结果
export interface LoginResult {
    token: string,
    is_new: boolean
};

// 登录表单
export interface LoginForm {
    mobile: string,
    login_type: loginTypeItems,
    code?: string,
    password?: string,

    // 非表单字段
    agreement: boolean,
};

