import { baseApi } from "./base";

import { CommonResponse } from "../../interfaces/common/response";
import { HomeData, MessageData } from "../../interfaces/home/home";


const homeApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // 主页信息
        getHomeData: builder.mutation<CommonResponse<HomeData>, void>({
            query: () => ({ url: `/manage/workbench/home`, method: 'get' }),
        }),
        // 查询未读消息条目数量
        getUnreadMsgNum: builder.mutation<CommonResponse<MessageData>, {}>({
            query: () => ({ url: '/manage/notify/unread_count', method: 'get' }),
        }),
    }),
});

export const {
    useGetHomeDataMutation,
    useGetUnreadMsgNumMutation,
} = homeApi;
