// assets
import warnImg from '@assets/warn.svg';

export function WarnPage() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img width={500} src={warnImg} alt="浏览器核心版本过低" />
            <h1>当前浏览器内核版本过低！</h1>
            <h2>浏览器版本过低会导致页面显示错误，请升级到最新内核版本。</h2>
            <h2>推荐使用最新版本的
                <a href='https://www.google.cn/intl/zh-CN/chrome/'>谷歌浏览器</a>、
                <a href='http://www.firefox.com.cn/'>火狐浏览器</a>、
                <a href='https://www.microsoft.com/zh-cn/edge/download'>Edge浏览器</a>。
            </h2>
        </div>
    );
}