import { useEffect, useState } from 'react';

// apis
import {
    useGetCompanyTaxRegisterListQuery,
    useLazyGetCompanyTaxRegisterConfigDetailQuery,
    usePullCompanyTaxRegisterConfigMutation,
} from '../../../../store/apis/tax';

// antd
import { Form, Select, Table, Modal, Button, message } from 'antd';
import Column from 'antd/lib/table/Column';
import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { rootStore } from '../../../../store/store';
import { setGlobalParams } from '../../../../store/utils/tax';
import { useAppDispatch } from '../../../../store/hooks';

const { Option } = Select;
const { confirm } = Modal;

function TaxRegistryNoConfigPage() {
    const dispatch = useAppDispatch();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单
    const [form] = Form.useForm();

    // 初始化
    const [detailTrigger, detailResult] = useLazyGetCompanyTaxRegisterConfigDetailQuery({ refetchOnReconnect: true });
    const registerListResult = useGetCompanyTaxRegisterListQuery(undefined, { refetchOnMountOrArgChange: 60 });
    useEffect(() => {
        if (registerListResult.isSuccess && registerListResult.data.data.list.length > 0) {
            // 先读缓存
            let djxhid = rootStore.getState().tax.djxhid
                || registerListResult.data.data.list[0].djxhid;
            // 缓存
            setGlobalParams({ dispatch, djxhid: djxhid });

            form.setFieldValue('djxhid', djxhid);
            detailTrigger({ djxhid: djxhid });
        }
    }, [registerListResult]);

    // 切换登记号
    const handleChange = (value: string) => {
        // 缓存
        setGlobalParams({ dispatch, djxhid: value });

        console.log(`selected ${value}`);
        form.resetFields(['sbmm', 'xsbmm', 'rexsbmm']);
        detailTrigger({ djxhid: value });
    };

    const [isLoading, setIsLoading] = useState(false);
    const [pullTrigger, pullResult] = usePullCompanyTaxRegisterConfigMutation();
    const toPull = () => {
        if (detailResult.data?.data.detail.fbmba === undefined) {
            confirm({
                title: '提示',
                icon: <ExclamationCircleFilled />,
                content: '请完成前面步骤，再进行登记号操作',
                okCancel: false,
            });
        } else {
            setIsLoading(true);
            let djxhid = form.getFieldValue('djxhid');
            pullTrigger({ djxhid }).unwrap().then(res => {
                message.success(res.msg);
                detailTrigger({ djxhid });
            }).finally(() => {
                setIsLoading(false);
            });
        }
    };

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>登记号设置</div>
            </div>

            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="登记号"
                    name='djxhid'
                    rules={[{ required: true, message: '请填写选择一个区域' }]}
                >
                    <Select
                        style={{ width: '100%' }}
                        placeholder="登记号"
                        onChange={handleChange}
                        optionLabelProp="label"
                    >
                        {
                            registerListResult.data?.data.list.map(item => (
                                <Option value={item.djxhid} label={item.djxhid}>
                                    <div>{item.djxhid}</div>
                                    <div>{item.qymc}（{item.province_city_text}）</div>
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Form>

            {/* 顶部工作栏 */}
            <div className='search'>
                <Button
                    type="primary"
                    className="search__button"
                    icon={<PlusOutlined />}
                    disabled={detailResult.data && !detailResult.data.data.detail.fbmba && detailResult.data.data.detail.options.length > 0}
                    onClick={toPull}
                    loading={isLoading}
                >
                    拉取部门信息
                </Button>
            </div>

            {
                <Table
                    dataSource={detailResult.data?.data.detail.options}
                    loading={detailResult.isLoading}
                >
                    {/* {
                        detailResult.data?.data.detail.fbmba ? <>
                            <Column title="部门名称" dataIndex="bmmc" key="bmmc" />
                            <Column title="部门码" dataIndex="bmbh" key="bmbh" />
                        </> : <></>
                    } */}
                    <Column title="部门名称" dataIndex="bmmc" key="bmmc" />
                    <Column title="部门码" dataIndex="bmbh" key="bmbh" />
                    <Column title="申报人数（人）" dataIndex="count_staff_ids" key="count_staff_ids" />
                    {/* <Column
                        width={110}
                        fixed={'right'}
                        title="操作"
                        key="action"
                        render={(_: any, record: TaxCompanyRegisterConfigInfoOption | undefined, index: number) => (
                            <Space>
                                <Dropdown.Button type="primary" loading={false} overlay={
                                    <Menu
                                        items={record ? [
                                            {
                                                key: 'detail',
                                                label: (
                                                    <div>编辑</div>
                                                ),
                                                onClick: () => toEdit(record, index),
                                            },
                                            {
                                                key: 'generate',
                                                label: (
                                                    <div>删除</div>
                                                ),
                                                onClick: () => toDel(index),
                                            },
                                        ] : []}
                                    />
                                }>
                                    操作
                                </Dropdown.Button>
                            </Space>
                        )}
                    /> */}
                </Table>
            }
        </div >
    );
}


export default TaxRegistryNoConfigPage;