import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'ahooks';

// apis
import {
    useGetCompanyTaxRegisterListQuery,
    useGetPreDeductStaffListMutation,
    useLazyGetCompanyTaxRegisterConfigDetailQuery,
    useUpdatePreDeductMutation
} from '../../../../store/apis/tax';
import { useLazyGetDepartmentListQuery } from '../../../../store/apis/company';

// interface
import { TaxCompanyRegisterConfigInfoOption, TaxPreDeductStaffListData, TaxPreDeductStaffSearchForm, UpdatePreDeductForm } from '../../../../interfaces/tax/tax';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, message, Modal, Form, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

// assets
import { useGetDictListQuery } from '../../../../store/apis/common';
import { DepartmentData } from '../../../../interfaces/company/department';
import { useSearchParams } from 'react-router-dom';
import { AvatarImage } from '../../../../components/image/avatar';
import { setGlobalParams } from '../../../../store/utils/tax';
import { rootStore } from '../../../../store/store';
import { useAppDispatch } from '../../../../store/hooks';

const { Column } = Table;
const { Option } = Select;

function TaxStaffAdjustListPage() {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useSearchParams();

    // 请求hook
    const [listTrigger, listResult] = useGetPreDeductStaffListMutation();

    // 搜索表单
    const [searchForm, setSearchForm] = useState<TaxPreDeductStaffSearchForm>({});
    const searchFormRef = useRef<TaxPreDeductStaffSearchForm>(searchForm);
    useEffect(() => { searchFormRef.current = searchForm; }, [searchForm]);

    // 登记序号
    const registerListResult = useGetCompanyTaxRegisterListQuery(undefined, { refetchOnMountOrArgChange: 60 });
    const handleDjxhidChange = (value: string) => {
        console.log(`selected ${value}`);
        // 缓存
        setGlobalParams({ dispatch, djxhid: value });

        // 部门列表
        departmentTrigger({ djxhid: value });

        let form = {
            ...searchFormRef.current,
            djxhid: value,
            bmbh: undefined
        }
        setSearchForm(form);
        listTrigger(form);
    }
    // 依赖登记序号初始化列表
    useEffect(() => {
        if (registerListResult.isSuccess && !(searchFormRef.current.djxhid)) {
            let djxhid = search.get('djxhid');
            if (djxhid) {
                handleDjxhidChange(djxhid);
            } else {
                if (registerListResult.data.data.list.length > 0) {
                    let djxhid = rootStore.getState().tax.djxhid
                        || registerListResult.data.data.list[0].djxhid;
                    handleDjxhidChange(djxhid);
                }
            }
        }
    }, [listTrigger, registerListResult]);


    // 部门列表（筛选）
    const [departmentTrigger, departmentResult] = useLazyGetCompanyTaxRegisterConfigDetailQuery({ refetchOnReconnect: true });
    const [departmentList, setDepartmentList] = useState<TaxCompanyRegisterConfigInfoOption[]>([]);
    useEffect(() => {
        if (departmentResult.isSuccess) {
            setDepartmentList(departmentResult.data!.data.detail.options);
            if (departmentResult.data!.data.detail.options.length > 0) {
                let form = {
                    ...searchFormRef.current,
                    bmbh: departmentResult.data!.data.detail.options[0].bmbh
                }
                setSearchForm(form);
                listTrigger(form);
            }
        }
    }, [departmentResult]);
    // 事件
    const handleDepartmentChange = (value: number) => {
        console.log(`selected ${value}`);
        let form = {
            ...searchFormRef.current,
            bmbh: value
        }
        setSearchForm(form);
        listTrigger(form);
    };

    // 标题搜索
    const titleDebouncedValue = useDebounce(searchForm.keywords, { wait: 500 });
    useEffect(() => {
        if (searchForm.djxhid) listTrigger(searchForm);
    }, [titleDebouncedValue]);
    const handleTitleChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let form = {
            ...searchFormRef.current,
            keywords: value.target.value
        };
        setSearchForm(form);
    }

    // 列表数据
    let [list, setList] = useState<TaxPreDeductStaffListData[]>([]);
    useEffect(() => {
        if (listResult.isSuccess) {
            console.log('请求结果：', listResult);
            setList(listResult.data.data.list);
        }
    }, [listResult]);

    // table选中
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // 预扣款类型
    const typeResult = useGetDictListQuery(['tax_staff_pre_deduct_kcbs'], { refetchOnMountOrArgChange: 60 });
    // 修改配置
    const [reportTrigger, reportResult] = useUpdatePreDeductMutation();
    const [form] = Form.useForm<UpdatePreDeductForm>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        if (reportResult.isSuccess) {
            message.success(reportResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [reportResult, listTrigger]);
    const handleOk = () => {
        form.validateFields().then(_ => {
            let values = form.getFieldsValue(true);
            reportTrigger(values);
            setIsModalOpen(false);
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // 默认传递勾选的条目id
    const toReport = (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        form.setFieldsValue({
            ...searchFormRef.current,
            'tax_staff_ids': ids,
        });
        setIsModalOpen(true);
    }

    return (
        <div className='address menu inner-page'>
            <div className='menu__title'>预扣预缴税款设置</div>
            <div className='search'>
                <Select
                    className="search__item"
                    placeholder="登记序号"
                    value={searchForm.djxhid}
                    onChange={handleDjxhidChange}
                    optionLabelProp="label"
                >
                    {
                        registerListResult.data?.data.list.map(item => (
                            <Option value={item.djxhid} label={item.djxhid}>
                                <div>{item.djxhid}</div>
                                <div>{item.qymc}（{item.province_city_text}）</div>
                            </Option>
                        ))
                    }
                </Select>
                <Select
                    mode="multiple"
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="选择部门筛选"
                    value={searchForm.bmbh}
                    onChange={handleDepartmentChange}
                >
                    {departmentList.map(department => (
                        <Option key={department.bmbh} value={department.bmbh}>{department.bmmc}</Option>
                    ))}
                </Select>
                <Input
                    className="search__item"
                    value={searchForm.keywords}
                    onChange={handleTitleChange}
                    placeholder="关键词搜索"
                />
                {/* <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={listResult.isLoading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button> */}
                <Dropdown.Button
                    trigger={['click', 'hover']}
                    className="search__last-button"
                    type="primary"
                    loading={
                        reportResult.isLoading
                    }
                    overlay={
                        <Menu
                            items={selectedRowKeys.length > 0 ? [
                                {
                                    key: 'generate',
                                    label: (
                                        <div>批量调整</div>
                                    ),
                                    onClick: () => toReport(),
                                },
                            ] : [
                                {
                                    key: 'accounting',
                                    label: (
                                        <div>全部调整</div>
                                    ),
                                    onClick: () => toReport([]),
                                },
                            ]}
                        />
                    }>
                    {selectedRowKeys.length > 0 ? `已选择${selectedRowKeys.length}项` : '批量操作'}
                </Dropdown.Button>
            </div>
            <Table
                dataSource={list}
                loading={listResult.isLoading}
                rowSelection={
                    {
                        selectedRowKeys,
                        onChange: onSelectChange
                    }
                }
                rowKey={(record: TaxPreDeductStaffListData) => record?.id}
                pagination={{
                    position: ['bottomRight'],
                    size: "small",
                    showSizeChanger: true,
                    showQuickJumper: true,
                }}>
                <Column
                    title="头像"
                    dataIndex="avatar_link"
                    key="avatar_link"
                    width={60}
                    render={(avatar: string) => (
                        <AvatarImage url={avatar} />
                    )}
                />
                <Column title="姓名" dataIndex="name" key="name" />
                <Column title="部门" dataIndex="dept_text" key="dept_text" />
                <Column title="岗位" dataIndex="post_text" key="post_text" />
                <Column title="设置" dataIndex="kcbs_text" key="kcbs_text" />
                <Column
                    title="操作"
                    key="action"
                    render={(_: any, record: TaxPreDeductStaffListData | undefined) => (
                        <Space>
                            <Dropdown.Button trigger={['click', 'hover']} type="primary" loading={false} overlay={
                                <Menu
                                    items={record ? [
                                        {
                                            key: 'confirm',
                                            label: (
                                                <div>修改设置</div>
                                            ),
                                            onClick: () => toReport([record.id]),
                                        },
                                    ] : []}
                                />
                            }>
                                操作
                            </Dropdown.Button>
                        </Space>
                    )}
                />
            </Table>

            <Modal title="选择预扣预缴税款设置" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form}>
                    <Form.Item
                        name="kcbs"
                        rules={[{ required: true, message: '请选择预扣预缴税款设置' }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="请选择预扣预缴税款设置"
                            optionLabelProp="label"
                        >
                            {typeResult.data?.data.tax_staff_pre_deduct_kcbs?.options.map(option => (
                                <Option key={option.value} label={option.name}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>

            </Modal>
        </div>
    );
}

export default TaxStaffAdjustListPage;