import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface TaxState {
    djxhid?: string;
    period?: string,
    deptId?: number
}

const initialState: TaxState = {
    djxhid: undefined,
    period: dayjs().format('YYYY-MM'),
    deptId: undefined,
};

export const taxSlice = createSlice({
    name: 'tax',
    initialState,
    reducers: {
        setDjxhid: (state: TaxState, action) => {
            state.djxhid = action.payload;
        },
        setPeriod: (state: TaxState, action) => {
            state.period = action.payload;
        },
        setDeptId: (state: TaxState, action) => {
            state.deptId = action.payload;
        }
    },
    extraReducers: {},
});

export const { setDjxhid, setPeriod, setDeptId } = taxSlice.actions