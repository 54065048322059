import { useEffect, useRef, useState } from 'react';

// apis
import { useGetCompanyTaxRegisterListQuery, useLazyGetTaxPaymentDetailQuery, useGetTaxPaymentLogListMutation, useLazyGetCompanyTaxRegisterConfigDetailQuery } from '../../../store/apis/tax';

// interface
import { TaxCompanyPaymentLogListData, TaxCompanyPaymentLogListSearchForm, TaxCompanyRegisterConfigInfoOption } from '../../../interfaces/tax/tax';

// antd
import { Space, Table, Dropdown, Menu, Select, Modal, Spin, Descriptions } from 'antd';
import { rootStore } from '../../../store/store';
import { setGlobalParams } from '../../../store/utils/tax';
import { useAppDispatch } from '../../../store/hooks';


const { Column } = Table;
const { Option } = Select;
const { confirm } = Modal;

function TaxPaymentLogListPage() {
    const dispatch = useAppDispatch();
    
    // 请求hook
    const [listTrigger, listResult] = useGetTaxPaymentLogListMutation();

    // 搜索表单
    const [searchForm, setSearchForm] = useState<TaxCompanyPaymentLogListSearchForm>({ page: 1, size: 10 });
    const searchFormRef = useRef<TaxCompanyPaymentLogListSearchForm>(searchForm);
    useEffect(() => { searchFormRef.current = searchForm; }, [searchForm]);

    // 数据填充 listResult
    useEffect(() => {
        if (listResult.isSuccess) {
            console.log('不变的数据', searchFormRef.current);

            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== listResult.data.data.total) {
                    newListData = new Array(listResult.data.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((searchFormRef.current.page! - 1) * searchFormRef.current.size!, listResult.data.data.list.length, ...listResult.data.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        }
    }, [listResult]);

    // 登记序号
    const registerListResult = useGetCompanyTaxRegisterListQuery(undefined, { refetchOnMountOrArgChange: 60 });
    const handleDjxhidChange = (value: string) => {
        console.log(`selected ${value}`);

        // 缓存
        setGlobalParams({ dispatch, djxhid: value });

        // 部门列表
        departmentTrigger({ djxhid: value });

        let form = {
            ...searchFormRef.current,
            djxhid: value
        }
        setSearchForm(form);
        listTrigger(form);
    }
    // 依赖登记序号初始化列表
    useEffect(() => {
        if (registerListResult.isSuccess && !(searchFormRef.current.djxhid))
            if (registerListResult.data.data.list.length > 0) {
                let djxhid = rootStore.getState().tax.djxhid
                    || registerListResult.data.data.list[0].djxhid;
                handleDjxhidChange(djxhid);
            }
    }, [listTrigger, registerListResult]);

    // 部门列表（筛选）
    const [departmentTrigger, departmentResult] = useLazyGetCompanyTaxRegisterConfigDetailQuery({ refetchOnReconnect: true });
    const [departmentList, setDepartmentList] = useState<TaxCompanyRegisterConfigInfoOption[]>([]);
    useEffect(() => {
        if (departmentResult.isSuccess) {
            setDepartmentList(departmentResult.data!.data.detail.options);
            if (departmentResult.data!.data.detail.options.length > 0) {
                let form = {
                    ...searchFormRef.current,
                    bmbh: departmentResult.data!.data.detail.options[0].bmbh
                }
                setSearchForm(form);
                listTrigger(form);
            }
        }
    }, [departmentResult]);
    // 事件
    const handleDepartmentChange = (value: number) => {
        console.log(`selected ${value}`);
        let form = {
            ...searchFormRef.current,
            bmbh: value
        }
        setSearchForm(form);
        listTrigger(form);
    };


    // 分页
    const [listData, setListData] = useState<TaxCompanyPaymentLogListData[]>([])
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        setSearchForm((form) => {
            let newForm = {
                ...form,
                page,
                size: pageSize,
            };
            listTrigger(newForm);
            return newForm;
        })
    };

    // 详情
    const [detailTrigger, detailResult] = useLazyGetTaxPaymentDetailQuery({ refetchOnReconnect: true });
    const toDetail = (record: TaxCompanyPaymentLogListData) => {
        detailTrigger({ period: record.period, djxhid: record.djxhid })
            .then(res => {
                if (res.isSuccess) {
                    let data = res.data.data.detail;
                    confirm({
                        title: '缴费信息',
                        icon: null,
                        content: (
                            <Descriptions bordered column={1}>
                                <Descriptions.Item label="企业名称">{data.company_name}</Descriptions.Item>
                                <Descriptions.Item label="报表名称">{data.report_name}</Descriptions.Item>
                                <Descriptions.Item label="税务所属期">{data.period}</Descriptions.Item>
                                <Descriptions.Item label="收入总额">{data.tax_money_total}</Descriptions.Item>
                                <Descriptions.Item label="应补退税（申报）">{data.tax_total}</Descriptions.Item>
                                <Descriptions.Item label="应补退税">{data.ybtse}</Descriptions.Item>
                                <Descriptions.Item label="滞纳金">{data.znje}</Descriptions.Item>
                                <Descriptions.Item label="合计总金额">{data.total}</Descriptions.Item>
                            </Descriptions>
                        ),
                        okCancel: false,
                    });
                }
            });
    };


    return (
        <Spin
            spinning={
                listResult.isLoading ||
                detailResult.isLoading
            }
            tip="正在进行耗时操作，请稍等"
        >
            <div className='address menu inner-page'>
                <div className='menu__title'>缴费记录</div>

                <div className='search'>
                    <Select
                        className="search__item"
                        placeholder="登记序号"
                        value={searchForm.djxhid}
                        onChange={handleDjxhidChange}
                        optionLabelProp="label"
                    >
                        {
                            registerListResult.data?.data.list.map(item => (
                                <Option value={item.djxhid} label={item.djxhid}>
                                    <div>{item.djxhid}</div>
                                    <div>{item.qymc}（{item.province_city_text}）</div>
                                </Option>
                            ))
                        }
                    </Select>
                    <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="选择部门筛选"
                        value={searchForm.bmbh}
                        onChange={handleDepartmentChange}
                    >
                        {departmentList.map(department => (
                            <Option key={department.bmbh} value={department.bmbh}>{department.bmmc}</Option>
                        ))}
                    </Select>
                </div>
                <Table
                    dataSource={listData}
                    loading={listResult.isLoading}
                    rowKey={(record: TaxCompanyPaymentLogListData) => record?.id}
                    pagination={{
                        position: ['bottomRight'],
                        size: "small",
                        total: listResult.data?.data.total ?? 0,
                        showTotal: total => `共 ${total} 项`,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange: onPageChange
                    }}>
                    <Column title="税务所属期" dataIndex="period" key="period" />
                    <Column title="人数（人）" dataIndex="number" key="number" />
                    <Column title="滞纳金（元）" dataIndex="znje" key="znje" />
                    <Column title="合计金额（元）" dataIndex="tax_total" key="tax_total" />
                    <Column title="缴款方式" dataIndex="pay_type_text" key="pay_type_text" />
                    <Column title="状态" dataIndex="sbztbj_text" key="sbztbj_text" />
                    <Column
                        title="操作"
                        key="action"
                        render={(_: any, record: TaxCompanyPaymentLogListData | undefined) => (
                            <Space>
                                <Dropdown.Button trigger={['click', 'hover']} type="primary" loading={false} overlay={
                                    <Menu
                                        items={record ? [
                                            {
                                                key: 'detail',
                                                label: (
                                                    <div>查看明细</div>
                                                ),
                                                onClick: () => toDetail(record),
                                            },
                                        ] : []}
                                    />
                                }>
                                    操作
                                </Dropdown.Button>
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </Spin>
    );
}

export default TaxPaymentLogListPage;