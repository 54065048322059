import { useState, useEffect, useRef } from 'react';

// antd
import { Alert, Button, Col, DatePicker, Descriptions, Dropdown, Form, FormInstance, Input, InputNumber, Menu, message, Modal, Row, Select, Space, Switch, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CloseCircleOutlined, SmileOutlined } from '@ant-design/icons';

// apis
import { useGetTaxCompensationDetailQuery, useUpdateCompensationDonationsMutation } from '../../../../../store/apis/tax';

// interface
import { CompensationDonationsForm, CompensationDonationsFormData, TaxCompensationDetailData } from '../../../../../interfaces/tax/tax';
import dayjs from 'dayjs';

const { Option } = Select;
const { Column } = Table;

interface CollectionCreateFormProps {
    data: { id: number, sub_company_id?: number },
    visible: boolean;
    onFinish: () => void;
    onCancel: () => void;
}

function EditCompensationDonationsFormModal({
    visible,
    onFinish,
    onCancel,
    data
}: CollectionCreateFormProps) {
    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 详情数据
    const detailResult = useGetTaxCompensationDetailQuery({ id: data.id }, { refetchOnMountOrArgChange: true, refetchOnReconnect: true });
    useEffect(() => {
        if (detailResult.isSuccess) {
            form.setFieldsValue({
                id: data.id,
                zykcjze_data: detailResult.data.data.detail.zykcjze_data,
            });
        }
    }, [detailResult]);

    // 表单数据
    const [form] = Form.useForm<CompensationDonationsForm>();

    // 详情表单
    const [subOpen, setSubOpen] = useState(false);
    const [subData, setSubData] = useState<CompensationDonationsFormData>();
    const [subIndex, setSubIndex] = useState<number>();
    const onAddClick = () => {
        setSubData(undefined);
        setSubIndex(undefined);
        setSubOpen(true);
    };
    const hideUserModal = () => {
        setSubOpen(false);
    };
    const onEditClick = (record: CompensationDonationsFormData, index: number) => {
        setSubIndex(index);
        setSubData({
            ...record,
            jzrq: dayjs(record.jzrq) as any
        });
        setSubOpen(true);
    }
    const onDelClick = (index: number) => {
        let items: CompensationDonationsFormData[] = form.getFieldValue('zykcjze_data');
        form.setFieldValue("zykcjze_data", items.filter((_, i) => index !== i));
    }

    // 编辑
    const [saveTrigger, saveResult] = useUpdateCompensationDonationsMutation();
    useEffect(() => {
        if (saveResult.isSuccess) {
            message.success(saveResult.data.msg);
            onFinish();
        }
    }, [saveResult]);

    // 提交
    const submit = async () => {
        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        console.log(_form);

        await saveTrigger(_form);

        setConfirmLoading(false);
    }

    return (
        <Modal
            width={1200}
            visible={visible}
            title="准予扣除的捐赠额"
            okText="保存"
            cancelText="取消"
            maskClosable={false}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            onOk={() => {
                console.log(form.getFieldsValue());
                form
                    .validateFields()
                    .then(values => {
                        console.log(values);
                        submit();
                    })
            }}
        >
            {
                detailResult.isSuccess ?
                    <Form disabled layout="vertical">
                        <Row>
                            <Col span={11}>
                                <Form.Item label="姓名">
                                    <Input value={detailResult.data.data.detail.xm} />
                                </Form.Item>
                                <Form.Item label="证件号码">
                                    <Input value={detailResult.data.data.detail.zzhm} />
                                </Form.Item>
                                <Form.Item label="扣除捐赠前的应纳税所得额">
                                    <Input addonAfter={'元'} value={detailResult.data.data.detail.ynssde} />
                                </Form.Item>

                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                                <Form.Item label="证件类型">
                                    <Input value={'居民身份证'} />
                                </Form.Item>
                                <Form.Item label="所得项目">
                                    <Input value={detailResult.data.data.detail.sdxm} />
                                </Form.Item>
                                <Form.Item label="准予扣除的捐赠额">
                                    <Input addonAfter={'元'} value={detailResult.data.data.detail.zykcjze} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : <></>
            }

            <Alert style={{ marginBottom: 20 }} message="明细数据中的[减免税额]之和必须等于基础信息中的[总减免税额]" type="warning" />


            <Form.Provider
                onFormFinish={(name, { values, forms }) => {
                    if (name === 'donationsItemForm') {
                        const { donationsForm, donationsItemForm } = forms;
                        const items = donationsForm.getFieldValue('zykcjze_data') || [];
                        if (subIndex != null) {
                            items.splice(subIndex, 1, donationsItemForm.getFieldsValue(true));
                            donationsForm.setFieldValue('zykcjze_data', [...items]);
                        } else {
                            donationsForm.setFieldValue('zykcjze_data', [...items, donationsItemForm.getFieldsValue(true)]);
                        }
                        setSubOpen(false);
                    }
                }}
            > <Form
                form={form}
                name="donationsForm"
                layout="vertical"
                autoComplete="off"
            >
                    <DonationsItemModalForm open={subOpen} onCancel={hideUserModal} data={subData}></DonationsItemModalForm>
                    <Form.Item
                        label="明细数据"
                        required
                        shouldUpdate={(prevValues, curValues) => prevValues.zykcjze_data !== curValues.zykcjze_data}
                    >
                        {({ getFieldValue }) => {
                            const items: CompensationDonationsFormData[] = getFieldValue('zykcjze_data') || [];
                            return items.length ? (
                                <Table size='small' dataSource={items}>
                                    <Column title="受赠单位名称" dataIndex="szdwmc" key="szdwmc"></Column>
                                    <Column title="受赠单位纳税人识别号(统一社会信用代码)" dataIndex="szdwnsrsbh" key="szdwnsrsbh"></Column>
                                    <Column title="捐赠凭证号" dataIndex="jzpzh" key="jzpzh"></Column>
                                    <Column title="捐赠日期" dataIndex="jzrq" key="jzrq"></Column>
                                    <Column title="捐赠金额" dataIndex="jzje" key="jzje"></Column>
                                    <Column title="扣除比例" dataIndex="kcbl" key="kcbl" render={
                                        (value: number) => `${value * 100}%`
                                    }></Column>
                                    <Column title="实际扣除金额" dataIndex="sjkcje" key="sjkcje"></Column>
                                    <Column title="备注" dataIndex="remark" key="remark"></Column>
                                    <Column
                                        title="操作"
                                        key="action"
                                        render={(_: any, record: CompensationDonationsFormData | undefined, index: number) => (
                                            <Space>
                                                <Dropdown.Button  trigger={['click', 'hover']} type="primary" overlay={
                                                    <Menu
                                                        items={record ? [
                                                            {
                                                                key: 'edit',
                                                                label: (
                                                                    <div>编辑</div>
                                                                ),
                                                                onClick: () => onEditClick(record, index),
                                                            },
                                                            {
                                                                key: 'del',
                                                                label: (
                                                                    <div>删除</div>
                                                                ),
                                                                onClick: () => onDelClick(index),
                                                            }
                                                        ] : []}
                                                    />
                                                }>
                                                    操作
                                                </Dropdown.Button>
                                            </Space>
                                        )}
                                    />
                                </Table>
                            ) : (
                                <Typography.Text className="ant-form-text" type="secondary">
                                    ( <SmileOutlined /> 暂无添加数据。 )
                                </Typography.Text>
                            );
                        }}
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="button" onClick={onAddClick}>
                            添加明细数据
                        </Button>
                    </Form.Item>
                </Form>
            </Form.Provider>
        </Modal>
    );
}

interface ModalFormProps {
    data?: CompensationDonationsFormData,
    open: boolean;
    onCancel: () => void;
}

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, open }: { form: FormInstance; open: boolean }) => {
    const prevOpenRef = useRef<boolean>();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;

    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const DonationsItemModalForm: React.FC<ModalFormProps> = ({ open, onCancel, data }) => {
    const [form] = Form.useForm<CompensationDonationsFormData>();
    useEffect(() => {
        if (open && data) {
            form.setFieldsValue(data);
        } else {
            form.resetFields();
        }
    }, [open]);

    useResetFormOnCloseModal({
        form,
        open,
    });

    const onOk = () => {
        let rawForm: CompensationDonationsFormData = form.getFieldsValue(true);
        let newForm: CompensationDonationsFormData = {
            ...rawForm,
            jzrq: (rawForm.jzrq as unknown as dayjs.Dayjs).format('YYYY-MM-DD'),
        }
        form.setFieldsValue(newForm);
        form.submit();
    };

    return (
        <Modal
            title="明细数据"
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            maskClosable={false}
        >
            <Form form={form} layout="vertical" name="donationsItemForm">
                <Form.Item
                    label="受赠单位名称"
                    name="szdwmc"
                >
                    <Input placeholder='请填写受赠单位名称' />
                </Form.Item>

                <Form.Item
                    label="受赠单位纳税人识别号(统一社会信用代码)"
                    name="szdwnsrsbh"
                >
                    <Input placeholder='请填写受赠单位纳税人识别号(统一社会信用代码)' />
                </Form.Item>

                <Form.Item
                    label="捐赠凭证号"
                    name="jzpzh"
                >
                    <Input placeholder='请填写捐赠凭证号' />
                </Form.Item>

                <Form.Item
                    label="捐赠日期"
                    name='jzrq'
                >
                    <DatePicker
                        style={{ 'width': '100%' }}
                        format="YYYY-MM-DD"
                    />
                </Form.Item>

                <Form.Item
                    label="捐赠金额"
                    name='jzje'
                    rules={[{ required: true, message: '请填写捐赠金额' }]}
                >
                    <InputNumber style={{ 'width': '100%' }} precision={2} />
                </Form.Item>

                <Form.Item
                    label="扣除比例"
                    name="kcbl"
                    rules={[{ required: true, message: '请选择扣除比例' }]}
                >
                    <Select
                        allowClear
                        showArrow
                        placeholder="请选择类型"
                    >
                        <Option key={1} value={0.3}>30%</Option>
                        <Option key={2} value={1}>100%</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="实际扣除金额"
                    name='sjkcje'
                    rules={[{ required: true, message: '请填写实际扣除金额' }]}
                >
                    <InputNumber style={{ 'width': '100%' }} precision={2} />
                </Form.Item>


                <Form.Item
                    label="备注"
                    name='remark'
                >
                    <TextArea rows={4} placeholder="请填写备注" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditCompensationDonationsFormModal;