import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { useAppDispatch } from "../hooks";
import { setDeptId, setDjxhid, setPeriod } from "../tax";

export const setGlobalParams = ({ dispatch, djxhid, period, deptId }: {
    dispatch: Dispatch<AnyAction>,
    djxhid?: string;
    period?: string,
    deptId?: number
}) => {

    if (djxhid) dispatch(setDjxhid(djxhid));
    if (period) dispatch(setPeriod(period));
    if (deptId) dispatch(setDeptId(deptId));
}