import { baseApi } from "./base";

import { CommonResponse } from "../../interfaces/common/response";
import { DictList, DictName, FileUpload, IAllCompanyList, IAllDepartmentStaffTree, ICommonData, IGroupDepartmentList, IGroupStaffList, IIconLibraryList, IndustryList, RegionList, uploadTypeValues } from "../../interfaces/common/common";
import { BankCardOcr, BusinessLicenseOcr, IdcardOcr } from "../../interfaces/common/ocr";


const commonApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // 获取服务器字典列表
        getDictList: builder.query<CommonResponse<DictList>, DictName[]>({
            query: (dictNames) => ({
                url: `/api/shares/options`,
                method: 'post',
                body: {
                    types: dictNames
                },
            }),
        }),
        // 上传文件到服务器并获取id
        uploadFile: builder.mutation<CommonResponse<FileUpload>, { file: Blob, type: uploadTypeValues }>({
            query: ({ file, type }) => {
                let form = new FormData();
                form.append('file', file);
                form.append('type', type);
                return {
                    url: `/api/shares/file_upload`,
                    method: 'post',
                    body: form
                }
            },
        }),
        // 加载省市区
        getRegionData: builder.query<CommonResponse<RegionList>, { pid?: number }>({
            query: (form) => ({
                url: `/api/shares/region`,
                method: 'get',
                params: form,
            }),
        }),
        // 身份证识别
        getIdcardOcrData: builder.mutation<CommonResponse<IdcardOcr>, { fid: number }>({
            query: (form) => ({ url: `/manage/ocr/idcard`, method: 'get', params: form }),
        }),
        // 银行卡识别
        getBankCardOcrData: builder.mutation<CommonResponse<BankCardOcr>, { file_id: number }>({
            query: (form) => ({
                url: `/manage/ocr/scan`, method: 'get', params: {
                    ...form,
                    "type": "bankCard"
                }
            }),
        }),
        // 营业执照识别
        getBizLicenseOcrData: builder.mutation<CommonResponse<BusinessLicenseOcr>, { file_id: number }>({
            query: (form) => ({
                url: `/manage/ocr/scan`, method: 'get', params: {
                    ...form,
                    "type": "bizLicense"
                }
            }),
        }),
        // 获取产业集群列表
        getIndustryList: builder.query<CommonResponse<IndustryList>, {
            province_id: number,
            city_id: number,
            area_id: number,
            street_id: number
        }>({
            query: (form) => ({
                url: `/api/shares/industry`, method: 'post', body: form
            }),
        }),
        // 获取系统图标库
        getIconLibrary: builder.query<CommonResponse<IIconLibraryList>, undefined>({
            query: () => ({
                url: `/manage/icon/all_list`, method: 'get'
            }),
        }),
        // 选择员工
        getGroupStaffList: builder.query<CommonResponse<IGroupStaffList>, undefined>({
            query: () => ({
                url: `/manage/staff/select`, method: 'post'
            }),
        }),
        // 选择部门
        getGroupDepartmentList: builder.query<CommonResponse<IGroupDepartmentList>, undefined>({
            query: () => ({
                url: `/manage/dept/select`, method: 'post'
            }),
        }),
        // 获取企业及子公司列表
        getAllCompanyList: builder.query<CommonResponse<IAllCompanyList>, undefined>({
            query: () => ({
                url: `/manage/companys/group_all_list`, method: 'get'
            }),
        }),
        // 获取所有部门所有员工树
        getAllDepartmentStaffTree: builder.query<CommonResponse<IAllDepartmentStaffTree>, { include_sub_company?: number, sub_company_id?: number }>({
            query: (form) => ({
                url: `/manage/dept/all_staff`, method: 'post', body: form
            }),
        }),
        // 获取一些页面参数
        getCommonData: builder.query<CommonResponse<ICommonData>, void>({
            query: () => ({
                url: `/manage/common/data`, method: 'get'
            }),
        }),
    }),
});

export const {
    useGetDictListQuery,
    useLazyGetDictListQuery,
    useUploadFileMutation,
    useGetRegionDataQuery,
    useLazyGetRegionDataQuery,
    useGetBankCardOcrDataMutation,
    useGetIdcardOcrDataMutation,
    useGetBizLicenseOcrDataMutation,
    useGetIndustryListQuery,
    useLazyGetIndustryListQuery,
    useGetIconLibraryQuery,
    useLazyGetIconLibraryQuery,
    useGetGroupDepartmentListQuery,
    useGetGroupStaffListQuery,
    useLazyGetGroupDepartmentListQuery,
    useLazyGetGroupStaffListQuery,
    useGetAllCompanyListQuery,
    useLazyGetAllCompanyListQuery,
    useGetAllDepartmentStaffTreeQuery,
    useLazyGetAllDepartmentStaffTreeQuery,
    useGetCommonDataQuery,
    useLazyGetCommonDataQuery
} = commonApi;