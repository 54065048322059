import { useEffect, useState } from 'react';

// apis
import {
    useCheckCompanyTaxRegisterPasswordMutation,
    useCompanyTaxRegisterPasswordResetMutation,
    useGetCompanyTaxRegisterListQuery,
    useGetCompanyTaxRegisterDetailMutation,
} from '../../../store/apis/tax';

// antd
import { Button, Col, Descriptions, Form, Input, message, Row, Select, Skeleton } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { rootStore } from '../../../store/store';
import { useAppDispatch } from '../../../store/hooks';
import { setDeptId, setDjxhid, setPeriod } from '../../../store/tax';
import { setGlobalParams } from '../../../store/utils/tax';

const { Option } = Select;

function TaxPasswordPage() {
    const dispatch = useAppDispatch();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单
    const [form] = Form.useForm();

    // 初始化
    const [detailTrigger, detailResult] = useGetCompanyTaxRegisterDetailMutation();
    const registerListResult = useGetCompanyTaxRegisterListQuery(undefined, { refetchOnMountOrArgChange: 60 });
    useEffect(() => {
        if (registerListResult.isSuccess && registerListResult.data.data.list.length > 0) {
            // 先读缓存
            let djxhid = rootStore.getState().tax.djxhid
                || registerListResult.data.data.list[0].djxhid;
            // 缓存
            setGlobalParams({ dispatch, djxhid: djxhid });

            form.setFieldValue('djxhid', djxhid);
            detailTrigger({ djxhid: djxhid });
        }
    }, [registerListResult]);

    // 切换登记号
    const handleChange = (value: string) => {
        // 缓存
        setGlobalParams({ dispatch, djxhid: value });

        console.log(`selected ${value}`);
        form.resetFields(['sbmm', 'xsbmm', 'rexsbmm']);
        detailTrigger({ djxhid: value });
    };

    // 申报核对
    const [checkTrigger, checkResult] = useCheckCompanyTaxRegisterPasswordMutation();
    useEffect(() => {
        if (checkResult.isSuccess) {
            message.success(checkResult.data.msg);
            if (detailResult.originalArgs) {
                detailTrigger(detailResult.originalArgs);
            }
        }
    }, [checkResult]);

    // 修改密码
    const [resetTrigger, resetResult] = useCompanyTaxRegisterPasswordResetMutation();
    useEffect(() => {
        if (resetResult.isSuccess) {
            message.success(resetResult.data.msg);
            if (detailResult.originalArgs) {
                detailTrigger(detailResult.originalArgs);
            }
        }
    }, [resetResult]);

    // 提交
    const onFinish = async (values: any) => {
        setConfirmLoading(true);
        let _form = form.getFieldsValue();
        console.log(_form);
        if (detailResult.data?.data.detail.is_check_password) {
            await resetTrigger(_form);
        } else {
            await checkTrigger(_form);
        }
        setConfirmLoading(false);
    };


    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>申报密码设置</div>
            </div>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
            >
                <Form.Item
                    label="登记号"
                    name='djxhid'
                    rules={[{ required: true, message: '请选择一个登记号' }]}
                >
                    <Select
                        style={{ width: '100%' }}
                        placeholder="登记号"
                        onChange={handleChange}
                        optionLabelProp="label"
                    >
                        {
                            registerListResult.data?.data.list.map(item => (
                                <Option value={item.djxhid} label={item.djxhid}>
                                    <div>{item.djxhid}</div>
                                    <div>{item.qymc}（{item.province_city_text}）</div>
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                {
                    detailResult.isSuccess && (!detailResult.isLoading) ? (
                        <Descriptions column={1} bordered>
                            <Descriptions.Item label="企业名称">{detailResult.data.data.detail.qymc}</Descriptions.Item>
                            <Descriptions.Item label="登记序号">{detailResult.data.data.detail.djxhid}</Descriptions.Item>
                            <Descriptions.Item label="主管税务机关">{detailResult.data.data.detail.zgswjgmc}</Descriptions.Item>
                            <Descriptions.Item label="生产经营地址">{detailResult.data.data.detail.scjydz}</Descriptions.Item>
                            <Descriptions.Item label="行业名称">{detailResult.data.data.detail.hymc}</Descriptions.Item>
                            <Descriptions.Item label="法人名称">{detailResult.data.data.detail.frxm}</Descriptions.Item>
                            <Descriptions.Item label="联系电话">{detailResult.data.data.detail.lxdh}</Descriptions.Item>
                            <Descriptions.Item label="状态">{detailResult.data.data.detail.is_check_password ? <span style={{ color: 'green' }}>验证成功</span> : <span style={{ color: 'red' }}>未验证</span>}</Descriptions.Item>
                        </Descriptions>
                    ) : <Skeleton active />
                }

                <div style={{ height: 20 }}></div>

                <Form.Item
                    label="原密码"
                    name="sbmm"
                    rules={[{ required: true, message: '请输入原密码' }]}
                >
                    <Input.Password
                        placeholder="请输入原密码"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                {
                    detailResult.data?.data.detail.is_check_password ?
                        (
                            <Row>
                                <Col span={11}>
                                    <Form.Item
                                        label="新密码"
                                        name="xsbmm"
                                        rules={[{ required: true, message: '请输入新密码' }]}
                                    >
                                        <Input.Password
                                            placeholder="请输入新密码"
                                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="重复新密码"
                                        name="rexsbmm"
                                        rules={[
                                            { required: true, message: '请重复输入新密码' },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('xsbmm') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('两次输入的密码不一致'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            placeholder="请重复输入新密码"
                                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : <></>
                }

                <Form.Item>
                    <Button style={{ height: 50, fontSize: 16 }} block loading={confirmLoading} type="primary" htmlType="submit">
                        {
                            detailResult.data?.data.detail.is_check_password ? '修改密码' : '点击申报'
                        }
                    </Button>
                </Form.Item>
            </Form>
        </div >
    );
}


export default TaxPasswordPage;