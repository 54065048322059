import { useState } from "react";

// component
import PageLayout from "../../layout/pageLayout";

// router
import { Outlet } from "react-router-dom";

// assets
import reportImg from '@assets/icons/function/tax/report.svg';
import staffImg from '@assets/icons/function/tax/staff.svg';

// antd
import { IMenuItem, MenuList } from "../../components/menu/menuList";

/**
 * 企业设置页
 */
function TaxStaffSettings() {
    const left = <TaxSettingsList></TaxSettingsList>;
    const right = <Outlet />;

    return (
        <PageLayout leftComponent={left} rightComponent={right} flex={[3, 5]}></PageLayout>
    );
}


function TaxSettingsList() {
    // 动态显示内容
    const [items, setItems] = useState<IMenuItem[]>(
        [
            {
                title: '人员信息采集',
                items: [
                    // {
                    //     title: '确定报税员工',
                    //     img: reportImg,
                    //     path: '/tax_staff/confirmation',
                    //     display: true,
                    //     canUse: true,
                    // }, 
                    {
                        title: '员工采集信息',
                        img: staffImg,
                        path: '/tax_staff/staff',
                        display: true,
                        canUse: true,
                    },
                    //  {
                    //     title: '外包员工采集',
                    //     img: staffImg,
                    //     path: '/tax_staff/outsourcedStaff',
                    //     display: true,
                    //     canUse: true,
                    // },
                    // {
                    //     title: '预扣预缴税款设置',
                    //     img: staffImg,
                    //     path: '/tax_staff/preDeductSettings',
                    //     display: true,
                    //     canUse: true,
                    // },
                ]
            },
        ]
    );


    return (
        <div className="menu">
            <MenuList items={items} />
        </div>
    );
}

export default TaxStaffSettings;