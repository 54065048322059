import { useEffect } from 'react';

// apis
import { useGetCompanyTaxInfoQuery, useRegisterCompanyTaxMutation } from '../../../store/apis/tax';

// antd
import { Button, Descriptions, message, Skeleton } from 'antd';

function TaxRegisterPage() {
    const detailResult = useGetCompanyTaxInfoQuery(undefined, { refetchOnMountOrArgChange: 60, refetchOnReconnect: true })

    const [registerTrigger, registerResult] = useRegisterCompanyTaxMutation();
    useEffect(() => {
        if (registerResult.isSuccess) {
            message.success(registerResult.data.msg);
            detailResult.refetch();
        }
    }, [registerResult])
    const onRegisterClick = () => {
        registerTrigger();
    };
    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>企业注册</div>
            </div>

            {
                detailResult.isSuccess ? (
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="企业名称">{detailResult.data.data.detail.name}</Descriptions.Item>
                        <Descriptions.Item label="纳税人识别号">{detailResult.data.data.detail.social_no}</Descriptions.Item>
                        <Descriptions.Item label="报税所属区域">{detailResult.data.data.detail.province_name}{detailResult.data.data.detail.city_name}</Descriptions.Item>
                    </Descriptions>
                ) : <Skeleton active />
            }

            {
                detailResult.isSuccess ? (
                    <Button
                        block
                        size='large'
                        type="primary"
                        loading={registerResult.isLoading}
                        disabled={detailResult.data.data.detail.is_register}
                        style={{ marginTop: 20 }}
                        onClick={onRegisterClick}
                    >
                        {detailResult.data.data.detail.is_register ? '已注册' : '点击注册'}
                    </Button>
                ) : <Skeleton.Button style={{ marginTop: 20 }} active block />
            }

        </div>
    );
}


export default TaxRegisterPage;