import { baseApi } from "./base";

import { CommonResponse, ErrorList } from "../../interfaces/common/response";
import { CompensationDonationsForm, CompensationDutyFreeForm, CompensationFreeSelectList, CompensationReductionForm, ITaxAnnualBonusImportForm, ITaxAnnualBonusList, ITaxCompanyBatchList, ITaxCompanyImportList, ITaxCompanyPaymentList, ITaxCompanyPaymentLogList, ITaxCompanyReportList, ITaxCompanyStaffConfirmationList, ITaxCompanyStaffList, ITaxCompensationList, ITaxHomeStatistics, ITaxOutsourcedCompanyStaffList, ITaxOutsourcedReportCompanyTaxStaffForm, TaxAnnualBonusListSearchForm, TaxCancelCompanyTaxStaffForm, TaxChangeEntryDateForm, TaxChangeQuitDateForm, TaxCompanyBatchSearchForm, TaxCompanyImportSearchForm, TaxCompanyInfo, TaxCompanyPaymentDetail, TaxCompanyPaymentForm, TaxCompanyPaymentLogListSearchForm, TaxCompanyPaymentSearchForm, TaxCompanyRegisterConfig, TaxCompanyRegisterConfigForm, TaxCompanyRegisterDetail, TaxCompanyRegisterList, TaxCompanyReportSearchForm, TaxCompanyStaffConfirmationListSearchForm, TaxCompanyStaffSearchForm, TaxCompensationDetail, TaxCompensationListSearchForm, TaxPreDeductStaffList, TaxPreDeductStaffSearchForm, TaxReportCompanyTaxStaffForm, UpdatePreDeductForm } from "../../interfaces/tax/tax";
import { DownloadTemplate, DownloadZip, UploadTemplate } from "../../interfaces/common/download";


const taxApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // 首页统计数据
        getHomeStatistics: builder.mutation<CommonResponse<ITaxHomeStatistics>, { period?: string, djxhid?: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_statistics/home`,
                method: 'post',
                body: form
            }),
        }),


        // 企业税务详情
        getCompanyTaxInfo: builder.query<CommonResponse<TaxCompanyInfo>, void>({
            query: () => ({
                url: `/web/tax_company/detail`,
                method: 'get',
            }),
        }),
        // 企业税务注册
        registerCompanyTax: builder.mutation<CommonResponse, void>({
            query: () => ({
                url: `/web/tax_company/register`,
                method: 'get',
            }),
        }),
        // 企业税务注册详情
        getCompanyTaxRegisterDetail: builder.mutation<CommonResponse<TaxCompanyRegisterDetail>, { djxhid: string }>({
            query: (form) => ({
                url: `/web/tax_company/register_detail`,
                method: 'get',
                params: form
            }),
        }),
        // 登记列表
        getCompanyTaxRegisterList: builder.query<CommonResponse<TaxCompanyRegisterList>, void>({
            query: () => ({
                url: `/web/tax_company/register_list`,
                method: 'get',
            }),
        }),
        // 申报密码校验
        checkCompanyTaxRegisterPassword: builder.mutation<CommonResponse, { djxhid: string, sbmm: string }>({
            query: (form) => ({
                url: `/web/tax_company/check_password`,
                method: 'post',
                body: form
            }),
        }),
        // 申报密码修改
        companyTaxRegisterPasswordReset: builder.mutation<CommonResponse, { djxhid: string, sbmm: string, xsbmm: string, rexsbmm: string }>({
            query: (form) => ({
                url: `/web/tax_company/update_password`,
                method: 'post',
                body: form
            }),
        }),
        // 登记号配置详情
        // getCompanyTaxRegisterConfigDetail: builder.query<CommonResponse<TaxCompanyRegisterConfig>, { djxhid: string }>({
        //     query: (form) => ({
        //         url: `/web/tax_company/get_djxhid_info`,
        //         method: 'get',
        //         params: form
        //     }),
        // }),
        getCompanyTaxRegisterConfigDetail: builder.query<CommonResponse<TaxCompanyRegisterConfig>, { djxhid: string }>({
            query: (form) => ({
                url: `/web/tax_company/dept_list`,
                method: 'post',
                body: form
            }),
        }),
        // 拉取部门
        pullCompanyTaxRegisterConfig: builder.mutation<CommonResponse, { djxhid: string }>({
            query: (form) => ({
                url: `/web/tax_company/pull_dept_list`,
                method: 'post',
                body: form
            }),
        }),
        // 登记号配置修改
        // submitCompanyTaxRegisterConfig: builder.mutation<CommonResponse, TaxCompanyRegisterConfigForm>({
        //     query: (form) => ({
        //         url: `/web/tax_company/save_djxhid_info`,
        //         method: 'post',
        //         body: form
        //     }),
        // }),


        /**
         * 报税确认表
         */
        // 报税确认表
        getCompanyTaxStaffCheckList: builder.query<CommonResponse<ITaxCompanyStaffConfirmationList>, TaxCompanyStaffConfirmationListSearchForm>({
            query: (form) => ({
                url: `/web/tax_staff/confirm_staff_list`,
                method: 'post',
                body: form
            }),
        }),
        // 确认信息添加
        addCompanyTaxStaffConfirm: builder.mutation<CommonResponse<ErrorList>, { staff_ids: number[] }>({
            query: (form) => ({
                url: `/web/tax_staff/confirm_staff_add`,
                method: 'post',
                body: form
            }),
        }),
        // 确认信息取消
        cancelCompanyTaxStaffConfirm: builder.mutation<CommonResponse<ErrorList>, { staff_ids: number[], step: number }>({
            query: (form) => ({
                url: `/web/tax_staff/confirm_staff_del`,
                method: 'post',
                body: form
            }),
        }),

        /**
         * 员工采集信息
         */
        // 员工人员信息
        getCompanyTaxStaffList: builder.mutation<CommonResponse<ITaxCompanyStaffList>, TaxCompanyStaffSearchForm>({
            query: (form) => ({
                url: `/web/tax_staff/staff_list`,
                method: 'post',
                body: form
            }),
        }),
        // 导出员工人员信息
        exportCompanyTaxStaffList: builder.mutation<CommonResponse<DownloadTemplate>, TaxCompanyStaffSearchForm>({
            query: (form) => ({
                url: `/web/tax_staff/staff_list`,
                method: 'post',
                body: {
                    ...form,
                    is_export: true
                }
            }),
        }),
        // 导入员工人员信息
        uploadCompanyTaxStaff: builder.mutation<CommonResponse<UploadTemplate>, {
            djxhid: string,
            period: string,
            file_id: number,
            bmbh?: number
        }>({
            query: (form) => ({ url: '/web/tax_staff/import', method: 'post', body: form }),
        }),
        // 员工报送
        reportCompanyTaxStaff: builder.mutation<CommonResponse, TaxReportCompanyTaxStaffForm>({
            query: (form) => ({
                url: `/web/tax_staff/send`,
                method: 'post',
                body: form
            }),
        }),
        // 作废人员信息
        cancelCompanyTaxStaff: builder.mutation<CommonResponse, TaxCancelCompanyTaxStaffForm>({
            query: (form) => ({
                url: `/web/tax_staff/batch_invalid`,
                method: 'post',
                body: form
            }),
        }),
        // 人员上报手动反馈
        getStaffReportFeedback: builder.mutation<CommonResponse, void>({
            query: () => ({
                url: `/web/tax_staff/manual_feedback`,
                method: 'get',
            }),
        }),
        // 员工扣除名单
        getPreDeductStaffList: builder.mutation<CommonResponse<TaxPreDeductStaffList>, TaxPreDeductStaffSearchForm>({
            query: (form) => ({
                url: `/web/tax_staff_pre_deduct/list`,
                method: 'post',
                body: form
            }),
        }),
        // 扣除名单确认
        updatePreDeduct: builder.mutation<CommonResponse, UpdatePreDeductForm>({
            query: (form) => ({
                url: `/web/tax_staff_pre_deduct/confirm_pre_deduct`,
                method: 'post',
                body: form
            }),
        }),
        // 调整员工入职时间
        changeEntryDate: builder.mutation<CommonResponse, TaxChangeEntryDateForm>({
            query: (form) => ({
                url: `/web/tax_staff/change_entry_date`,
                method: 'post',
                body: form
            }),
        }),
        // 批量离职
        staffQuit: builder.mutation<CommonResponse, TaxChangeQuitDateForm>({
            query: (form) => ({
                url: `/web/tax_staff/batch_quit`,
                method: 'post',
                body: form
            }),
        }),


        /**
         * 薪酬批次
         */
        // 薪酬批次列表
        getTaxBatchList: builder.mutation<CommonResponse<ITaxCompanyBatchList>, TaxCompanyBatchSearchForm>({
            query: (form) => ({
                url: `/web/tax_sheet/list`,
                method: 'post',
                body: form
            }),
        }),
        // 导出薪酬批次列表
        exportTaxBatchList: builder.mutation<CommonResponse<DownloadTemplate>, Partial<TaxCompanyBatchSearchForm>>({
            query: (form) => ({
                url: `/web/tax_sheet/list`,
                method: 'post',
                body: {
                    ...form,
                    is_export: 1
                }
            }),
        }),
        // 薪酬批次合并
        mergeTaxBatch: builder.mutation<CommonResponse<ErrorList>, {
            djxhid: string,
            period: string,
            bmbh?: number
        }>({
            query: (form) => ({
                url: `/web/tax_sheet/batch_merge`,
                method: 'post',
                body: form
            }),
        }),
        // 薪酬批次重置税务所属期
        resetTaxBatch: builder.mutation<CommonResponse<ErrorList>, {
            period: string,
            new_period: string,
            month: string
        }>({
            query: (form) => ({
                url: `/web/tax_sheet/reset_period`,
                method: 'post',
                body: form
            }),
        }),
        // 导入薪酬批次模板
        uploadSalaryBatchData: builder.mutation<CommonResponse<UploadTemplate>, {
            file_id: number,
            djxhid: string,
            period: string,
            bmbh?: number
        }>({
            query: (form) => ({ url: '/web/tax_sheet/import', method: 'post', body: form }),
        }),
        // 下载薪酬批次模板
        downloadSalaryBatchTemplate: builder.mutation<CommonResponse<DownloadTemplate>, void>({
            query: () => ({ url: '/web/tax_sheet/download', method: 'post' }),
        }),

        /**
         * 导入申报工资
         */
        // 导入申报工资列表
        getSalaryUndeclaredList: builder.query<CommonResponse<ITaxCompanyImportList>, TaxCompanyImportSearchForm>({
            query: (form) => ({
                url: `/web/tax_sheet/undeclare_list`,
                method: 'post',
                body: form
            }),
        }),
        // 下载未申报月份薪资模板
        downloadUndeclaredSalaryTemplate: builder.mutation<CommonResponse<DownloadTemplate>, void>({
            query: () => ({ url: '/api/shares/download_sheet', method: 'get', params: { name: "tax_sheet_undeclare" } }),
        }),
        // 导入员工上个月未申报工资
        uploadUndeclaredSalaryData: builder.mutation<CommonResponse<UploadTemplate>, { file_id: number }>({
            query: (form) => ({ url: '/web/tax_sheet/undeclare_import', method: 'get', params: form }),
        }),

        /**
         * 报送税务
         */
        // 报送税务列表
        getTaxReportList: builder.mutation<CommonResponse<ITaxCompanyReportList>, TaxCompanyReportSearchForm>({
            query: (form) => ({
                url: `/web/tax_report/list`,
                method: 'post',
                body: form
            }),
        }),
        // 导出报送税务列表
        exportTaxReportList: builder.mutation<CommonResponse<DownloadTemplate>, Partial<TaxCompanyReportSearchForm>>({
            query: (form) => ({
                url: `/web/tax_report/list`,
                method: 'post',
                body: {
                    ...form,
                    is_export: 1
                }
            }),
        }),
        // 申报数据报送
        taxReport: builder.mutation<CommonResponse, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_report/send`,
                method: 'post',
                body: form
            }),
        }),
        // 申报作废
        taxReportCancel: builder.mutation<CommonResponse, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_report/cancel`,
                method: 'post',
                body: form
            }),
        }),
        // 更正申报
        taxReportCorrect: builder.mutation<CommonResponse, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_report/correct`,
                method: 'post',
                body: form
            }),
        }),
        // 删除报送列表数据
        taxReportDel: builder.mutation<CommonResponse, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_report/del`,
                method: 'post',
                body: form
            }),
        }),

        /**
         * 一次性补偿金
         */
        // 一次性补偿金列表
        getTaxCompensationList: builder.mutation<CommonResponse<ITaxCompensationList>, TaxCompensationListSearchForm>({
            query: (form) => ({
                url: `/web/tax_compensation/index`,
                method: 'post',
                body: form
            }),
        }),
        // 一次性补偿金模板
        downloadCompensationTemplate: builder.mutation<CommonResponse<DownloadTemplate>, void>({
            query: () => ({ url: '/web/tax_compensation/download', method: 'post' }),
        }),
        // 一次性补偿金导入
        uploadCompensationData: builder.mutation<CommonResponse<UploadTemplate>, { file_id: number, djxhid: string, bmbh?: number, period: string }>({
            query: (form) => ({ url: '/web/tax_compensation/import', method: 'post', body: form }),
        }),
        // 编辑准予扣除的捐赠额
        updateCompensationDonations: builder.mutation<CommonResponse, CompensationDonationsForm>({
            query: (form) => ({ url: '/web/tax_compensation/schedule_update', method: 'post', body: form }),
        }),
        // 减免收入明细编辑
        updateCompensationReduction: builder.mutation<CommonResponse, CompensationReductionForm>({
            query: (form) => ({ url: '/web/tax_compensation/schedule_update', method: 'post', body: form }),
        }),
        // 获取减免收入明细选项
        getReductionSelectList: builder.query<CommonResponse<CompensationFreeSelectList>, {
            djxhid: string,
            bmbh?: number
        }>({
            query: (form) => ({
                url: `/web/tax_compensation/secondary_query_jmse`,
                method: 'post',
                body: form
            }),
        }),
        // 免税收入明细编辑
        updateCompensationDutyFree: builder.mutation<CommonResponse, CompensationDutyFreeForm>({
            query: (form) => ({ url: '/web/tax_compensation/schedule_update', method: 'post', body: form }),
        }),
        // 获取免税收入明细选项
        getDutyFreeSelectList: builder.query<CommonResponse<CompensationFreeSelectList>, {
            djxhid: string,
            bmbh?: number
        }>({
            query: (form) => ({
                url: `/web/tax_compensation/secondary_query_mssd`,
                method: 'post',
                body: form
            }),
        }),
        // 一次性补偿金详情
        getTaxCompensationDetail: builder.query<CommonResponse<TaxCompensationDetail>, { id: number }>({
            query: (form) => ({
                url: `/web/tax_compensation/detail`,
                method: 'post',
                body: form
            }),
        }),
        // 一次性补偿金删除
        delCompensation: builder.mutation<CommonResponse, { ids: number[] }>({
            query: (form) => ({ url: '/web/tax_compensation/del', method: 'post', body: form }),
        }),

        /**
         * 全年一次性奖金
         */
        // 全年一次性奖金列表
        getTaxAnnualBonusList: builder.mutation<CommonResponse<ITaxAnnualBonusList>, TaxAnnualBonusListSearchForm>({
            query: (form) => ({
                url: `/web/tax_annual_bonus/index`,
                method: 'post',
                body: form
            }),
        }),
        // 全年一次性奖金模板
        downloadAnnualBonusTemplate: builder.mutation<CommonResponse<DownloadTemplate>, void>({
            query: () => ({ url: '/web/tax_annual_bonus/download', method: 'post' }),
        }),
        // 全年一次性奖金导入
        uploadAnnualBonusData: builder.mutation<CommonResponse<UploadTemplate>, ITaxAnnualBonusImportForm>({
            query: (form) => ({ url: '/web/tax_annual_bonus/import', method: 'post', body: form }),
        }),
        // 减免收入明细编辑
        updateAnnualBonusReduction: builder.mutation<CommonResponse, CompensationReductionForm>({
            query: (form) => ({ url: '/web/tax_annual_bonus/schedule_update', method: 'post', body: form }),
        }),
        // 获取减免收入明细选项
        getAnnualBonusReductionSelectList: builder.query<CommonResponse<CompensationFreeSelectList>, {
            djxhid: string,
            bmbh?: number
        }>({
            query: (form) => ({
                url: `/web/tax_annual_bonus/secondary_query_jmse`,
                method: 'post',
                body: form
            }),
        }),
        // 免税收入明细编辑
        updateAnnualBonusDutyFree: builder.mutation<CommonResponse, CompensationDutyFreeForm>({
            query: (form) => ({ url: '/web/tax_annual_bonus/schedule_update', method: 'post', body: form }),
        }),
        // 获取免税收入明细选项
        getAnnualBonusDutyFreeSelectList: builder.query<CommonResponse<CompensationFreeSelectList>, {
            djxhid: string,
            bmbh?: number
        }>({
            query: (form) => ({
                url: `/web/tax_annual_bonus/secondary_query_mssd`,
                method: 'post',
                body: form
            }),
        }),
        // 全年一次性奖金详情
        getTaxAnnualBonusDetail: builder.query<CommonResponse<TaxCompensationDetail>, { id: number }>({
            query: (form) => ({
                url: `/web/tax_annual_bonus/detail`,
                method: 'post',
                body: form
            }),
        }),
        // 全年一次性奖金删除
        delAnnualBonus: builder.mutation<CommonResponse, { ids: number[] }>({
            query: (form) => ({ url: '/web/tax_annual_bonus/del', method: 'post', body: form }),
        }),

        /**
         * 税务缴费
         */
        // 缴费列表
        getTaxPaymentList: builder.mutation<CommonResponse<ITaxCompanyPaymentList>, TaxCompanyPaymentSearchForm>({
            query: (form) => ({
                url: `/web/tax_payment/list`,
                method: 'post',
                body: form
            }),
        }),
        // 缴费详情
        getTaxPaymentDetail: builder.query<CommonResponse<TaxCompanyPaymentDetail>, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_payment/detail`,
                method: 'post',
                body: form
            }),
        }),
        // 缴费前欠费查询
        queryPayTaxPayment: builder.mutation<CommonResponse, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_payment/query_arrearage`,
                method: 'post',
                body: form
            }),
        }),
        // 缴费前欠费查询反馈
        queryPayTaxPaymentFeedback: builder.mutation<CommonResponse<{ can_pay: boolean }>, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_payment/query_arrearage_feedback`,
                method: 'post',
                body: form
            }),
        }),
        // 缴费
        payTaxPayment: builder.mutation<CommonResponse<{ url?: string }>, TaxCompanyPaymentForm>({
            query: (form) => ({
                url: `/web/tax_payment/pay`,
                method: 'post',
                body: form
            }),
        }),
        // 缴款状态查询
        queryPayTaxPaymentResult: builder.mutation<CommonResponse, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_payment/check_pay`,
                method: 'post',
                body: form
            }),
        }),
        // 下载完税证明
        downloadProofOnPayment: builder.mutation<CommonResponse<DownloadZip>, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_payment/withheld_voucher`,
                method: 'post',
                body: form
            }),
        }),
        // 缴款凭证作废
        cancelPaymentVoucher: builder.mutation<CommonResponse, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_payment/cancel_withholding_voucher`,
                method: 'post',
                body: form
            }),
        }),
        // 打印缴款凭证的缴款结果查询
        queryPaymentVoucherResult: builder.mutation<CommonResponse, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_payment/get_withholding_vouchers_payment_result`,
                method: 'post',
                body: form
            }),
        }),
        // 下载缴费凭证
        downloadVoucher: builder.mutation<CommonResponse<DownloadTemplate>, { period: string, djxhid: string, bmbh?: number }>({
            query: (form) => ({
                url: `/web/tax_payment/download_voucher`,
                method: 'post',
                body: form
            }),
        }),
        // 缴费日志
        getTaxPaymentLogList: builder.mutation<CommonResponse<ITaxCompanyPaymentLogList>, TaxCompanyPaymentLogListSearchForm>({
            query: (form) => ({
                url: `/web/tax_payment/pay_log`,
                method: 'post',
                body: form
            }),
        }),

        /**
         * 外包人员采集
         */
        // 下载导入模板
        getImportTemplate: builder.mutation<CommonResponse<DownloadTemplate>, void>({
            query: () => ({ url: '/web/tax_staff/download', method: 'get' }),
        }),
        // 外包企业登记列表
        getOutsourcedCompanyTaxRegisterList: builder.query<CommonResponse<TaxCompanyRegisterList>, void>({
            query: () => ({
                url: `/web/tax_company_outsource/register_list`,
                method: 'get',
            }),
        }),
        // 外包员工人员信息
        getOutsourcedCompanyTaxStaffList: builder.mutation<CommonResponse<ITaxOutsourcedCompanyStaffList>, TaxCompanyStaffSearchForm>({
            query: (form) => ({
                url: `/web/tax_staff_outsource/staff_list`,
                method: 'post',
                body: form
            }),
        }),
        // 导出外包员工人员信息
        exportOutsourcedCompanyTaxStaffList: builder.mutation<CommonResponse<DownloadTemplate>, TaxCompanyStaffSearchForm>({
            query: (form) => ({
                url: `/web/tax_staff_outsource/staff_list`,
                method: 'post',
                body: {
                    ...form,
                    is_export: true
                }
            }),
        }),
        // 导入拆分薪酬
        importOutsourcedCompanyTaxStaff: builder.mutation<CommonResponse<UploadTemplate>, { file_id: number, djxhid: string }>({
            query: (form) => ({ url: '/web/tax_staff_outsource/handle_import', method: 'post', body: form }),
        }),
        // 员工报送
        reportOutsourcedCompanyTaxStaff: builder.mutation<CommonResponse, ITaxOutsourcedReportCompanyTaxStaffForm>({
            query: (form) => ({
                url: `/web/tax_staff_outsource/send`,
                method: 'post',
                body: form
            }),
        }),
        // 作废人员信息
        cancelOutsourcedCompanyTaxStaff: builder.mutation<CommonResponse, { staff_ids: number[] }>({
            query: (form) => ({
                url: `/web/tax_staff_outsource/batch_invalid`,
                method: 'post',
                body: form
            }),
        }),
    }),
});

export const {
    useGetImportTemplateMutation,
    useGetCompanyTaxInfoQuery,
    useLazyGetCompanyTaxInfoQuery,
    useRegisterCompanyTaxMutation,
    useGetCompanyTaxRegisterDetailMutation,
    useGetCompanyTaxRegisterListQuery,
    useLazyGetCompanyTaxRegisterListQuery,
    useCheckCompanyTaxRegisterPasswordMutation,
    useCompanyTaxRegisterPasswordResetMutation,
    useGetCompanyTaxRegisterConfigDetailQuery,
    useLazyGetCompanyTaxRegisterConfigDetailQuery,
    usePullCompanyTaxRegisterConfigMutation,
    useGetCompanyTaxStaffCheckListQuery,
    useLazyGetCompanyTaxStaffCheckListQuery,
    useAddCompanyTaxStaffConfirmMutation,
    useCancelCompanyTaxStaffConfirmMutation,
    useGetCompanyTaxStaffListMutation,
    useReportCompanyTaxStaffMutation,
    useCancelCompanyTaxStaffMutation,
    useGetStaffReportFeedbackMutation,
    useGetTaxBatchListMutation,
    useExportTaxBatchListMutation,
    useMergeTaxBatchMutation,
    useResetTaxBatchMutation,
    useGetTaxReportListMutation,
    useTaxReportMutation,
    useTaxReportCancelMutation,
    useTaxReportCorrectMutation,
    useTaxReportDelMutation,
    useDownloadUndeclaredSalaryTemplateMutation,
    useGetSalaryUndeclaredListQuery,
    useLazyGetSalaryUndeclaredListQuery,
    useUploadUndeclaredSalaryDataMutation,
    useCancelPaymentVoucherMutation,
    useDownloadProofOnPaymentMutation,
    useDownloadVoucherMutation,
    useGetTaxPaymentDetailQuery,
    useGetTaxPaymentListMutation,
    useLazyGetTaxPaymentDetailQuery,
    usePayTaxPaymentMutation,
    useQueryPayTaxPaymentResultMutation,
    useQueryPaymentVoucherResultMutation,
    useGetPreDeductStaffListMutation,
    useUpdatePreDeductMutation,
    useExportTaxReportListMutation,
    useQueryPayTaxPaymentFeedbackMutation,
    useQueryPayTaxPaymentMutation,
    useGetTaxPaymentLogListMutation,
    useDownloadCompensationTemplateMutation,
    useGetTaxCompensationListMutation,
    useUploadCompensationDataMutation,
    useUpdateCompensationDonationsMutation,
    useUpdateCompensationDutyFreeMutation,
    useUpdateCompensationReductionMutation,
    useGetDutyFreeSelectListQuery,
    useGetReductionSelectListQuery,
    useLazyGetDutyFreeSelectListQuery,
    useLazyGetReductionSelectListQuery,
    useGetTaxCompensationDetailQuery,
    useLazyGetTaxCompensationDetailQuery,
    useDelCompensationMutation,
    useChangeEntryDateMutation,
    useGetOutsourcedCompanyTaxRegisterListQuery,
    useLazyGetOutsourcedCompanyTaxRegisterListQuery,
    useCancelOutsourcedCompanyTaxStaffMutation,
    useGetOutsourcedCompanyTaxStaffListMutation,
    useReportOutsourcedCompanyTaxStaffMutation,
    useExportOutsourcedCompanyTaxStaffListMutation,
    useImportOutsourcedCompanyTaxStaffMutation,
    useExportCompanyTaxStaffListMutation,
    useUploadCompanyTaxStaffMutation,
    useDownloadAnnualBonusTemplateMutation,
    useGetTaxAnnualBonusListMutation,
    useUploadAnnualBonusDataMutation,
    useDelAnnualBonusMutation,
    useGetAnnualBonusDutyFreeSelectListQuery,
    useGetAnnualBonusReductionSelectListQuery,
    useGetTaxAnnualBonusDetailQuery,
    useLazyGetAnnualBonusDutyFreeSelectListQuery,
    useLazyGetAnnualBonusReductionSelectListQuery,
    useLazyGetTaxAnnualBonusDetailQuery,
    useUpdateAnnualBonusDutyFreeMutation,
    useUpdateAnnualBonusReductionMutation,
    useGetHomeStatisticsMutation,
    useStaffQuitMutation,
    useDownloadSalaryBatchTemplateMutation,
    useUploadSalaryBatchDataMutation
} = taxApi;
