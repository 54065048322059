import { ChangeEvent, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

// apis
import {
    useDelCompensationMutation,
    useDownloadCompensationTemplateMutation,
    useGetCompanyTaxRegisterListQuery,
    useGetTaxCompensationListMutation,
    useLazyGetCompanyTaxRegisterConfigDetailQuery,
    useUploadCompensationDataMutation
} from '../../../../store/apis/tax';

// interface
import { TaxCompanyRegisterConfigInfoOption, TaxCompensationListData, TaxCompensationListSearchForm } from '../../../../interfaces/tax/tax';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, message, Modal, DatePicker, Form, Spin, Descriptions } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { useUploadFileMutation } from '../../../../store/apis/common';
import EditCompensationDonationsFormModal from './actions/editCompensationDonations';
import EditCompensationReductionFormModal from './actions/editCompensationReduction';
import EditCompensationDutyFreeFormModal from './actions/editCompensationDutyFree';
import { rootStore } from '../../../../store/store';
import { setGlobalParams } from '../../../../store/utils/tax';
import { useAppDispatch } from '../../../../store/hooks';


const { Column } = Table;
const { Option } = Select;
const { confirm } = Modal;


function TaxCompensationListPage() {
    const dispatch = useAppDispatch();

    // 请求hook
    const [listTrigger, listResult] = useGetTaxCompensationListMutation();

    // 搜索表单
    const [searchForm, setSearchForm] = useState<TaxCompensationListSearchForm>({
        page: 1,
        size: 10,
        period: rootStore.getState().tax.period,
    });
    const searchFormRef = useRef<TaxCompensationListSearchForm>(searchForm);
    useEffect(() => { searchFormRef.current = searchForm; }, [searchForm]);

    // 数据填充 listResult
    useEffect(() => {
        if (listResult.isSuccess) {
            console.log('不变的数据', searchFormRef.current);

            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== listResult.data.data.total) {
                    newListData = new Array(listResult.data.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((searchFormRef.current.page! - 1) * searchFormRef.current.size!, listResult.data.data.list.length, ...listResult.data.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        }
    }, [listResult]);

    // 登记序号
    const registerListResult = useGetCompanyTaxRegisterListQuery(undefined, { refetchOnMountOrArgChange: 60 });
    const handleDjxhidChange = (value: string) => {
        console.log(`selected ${value}`);
        // 缓存
        setGlobalParams({ dispatch, djxhid: value });

        // 部门列表
        departmentTrigger({ djxhid: value });

        let form = {
            ...searchFormRef.current,
            djxhid: value
        }
        setSearchForm(form);
        listTrigger(form);
        setSelectedRowKeys([]);
    }
    // 依赖登记序号初始化列表
    useEffect(() => {
        if (registerListResult.isSuccess && !(searchFormRef.current.djxhid))
            if (registerListResult.data.data.list.length > 0) {
                let djxhid = rootStore.getState().tax.djxhid
                    || registerListResult.data.data.list[0].djxhid;
                handleDjxhidChange(djxhid);
            }
    }, [listTrigger, registerListResult]);

    // 部门列表（筛选）
    const [departmentTrigger, departmentResult] = useLazyGetCompanyTaxRegisterConfigDetailQuery({ refetchOnReconnect: true });
    const [departmentList, setDepartmentList] = useState<TaxCompanyRegisterConfigInfoOption[]>([]);
    useEffect(() => {
        if (departmentResult.isSuccess) {
            setDepartmentList(departmentResult.data!.data.detail.options);
            if (departmentResult.data!.data.detail.options.length > 0) {
                let form = {
                    ...searchFormRef.current,
                    bmbh: departmentResult.data!.data.detail.options[0].bmbh
                }
                setSearchForm(form);
                listTrigger(form);
            }
        }
    }, [departmentResult]);
    // 事件
    const handleDepartmentChange = (value: number) => {
        console.log(`selected ${value}`);
        let form = {
            ...searchFormRef.current,
            bmbh: value
        }
        setSearchForm(form);
        listTrigger(form);
    };

    // 分页
    const [listData, setListData] = useState<TaxCompensationListData[]>([])
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        setSearchForm((form) => {
            let newForm = {
                ...form,
                page,
                size: pageSize,
            };
            listTrigger(newForm);
            return newForm;
        })
    };


    // 税务所属期筛选
    const handleTaxPeriodChange = (value: dayjs.Dayjs | null, dateString: string) => {
        console.log(`date: ${value}`);

        // 缓存
        setGlobalParams({ dispatch, period: value?.format('YYYY-MM') });

        let form = {
            ...searchFormRef.current,
            period: value!.format('YYYY-MM')
        }
        setSearchForm(form)
        listTrigger(form);
        setSelectedRowKeys([]);
    }

    // 下载模板
    const [templateTrigger, templateResult] = useDownloadCompensationTemplateMutation();
    useEffect(() => {
        if (templateResult.isSuccess) {
            setTimeout(() => {
                window.open(templateResult.data.data.full_link, '_blank');
            }, 100);
        }
    }, [templateResult]);
    const download = () => {
        templateTrigger();
    };

    // 导入模板
    const [importTrigger, importResult] = useUploadCompensationDataMutation();
    useEffect(() => {
        if (importResult.isSuccess) {
            if (importResult.data.data.fail) {
                confirm({
                    title: '导入数据失败',
                    icon: <ExclamationCircleOutlined />,
                    content: '是否下载失败结果文档',
                    onOk() {
                        window.open(importResult.data.data.full_link, '_blank');
                    },
                });
            } else {
                message.success(importResult.data.msg);
            }
            listTrigger(searchFormRef.current);
        }
    }, [importResult]);

    // 上传点击框ref
    const inputRef = React.createRef<HTMLInputElement>();
    // 上传文件
    const [uploadTrigger, uploadResult] = useUploadFileMutation();
    useEffect(() => {
        if (uploadResult.isSuccess) {
            // 获取文件id传递给导入接口
            importTrigger({
                file_id: uploadResult.data.data.file_id,
                djxhid: searchFormRef.current.djxhid!,
                period: searchFormRef.current.period!,
                bmbh: searchFormRef.current.bmbh
            });
        }
    }, [uploadResult, importTrigger]);
    const upload = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        // 上传到服务器
        uploadTrigger({ file: event.target.files![0], type: 'salary' });
    };

    // 准予扣除的捐赠额弹窗
    const [donationsModalForm, setDonationsModalForm] = useState<{
        data: {
            id: number
        },
        isModalVisible: boolean,
    }>();
    const toEditDonations = (id: number) => {
        setDonationsModalForm({
            isModalVisible: true,
            data: {
                id: id
            }
        });
    };

    // 减免收入弹窗
    const [reductionModalForm, setReductionModalForm] = useState<{
        data: {
            id: number,
            djxhid: string,
            bmbh?: number
        },
        isModalVisible: boolean,
    }>();
    const toEditReduction = (id: number) => {
        setReductionModalForm({
            isModalVisible: true,
            data: {
                id: id,
                djxhid: searchFormRef.current.djxhid!,
                bmbh: searchFormRef.current.bmbh
            }
        });
    };

    // 免税收入弹窗
    const [dutyFreeModalForm, setDutyFreeModalForm] = useState<{
        data: {
            id: number,
            djxhid: string,
            bmbh?: number
        },
        isModalVisible: boolean,
    }>();
    const toEditDutyFree = (id: number) => {
        setDutyFreeModalForm({
            isModalVisible: true,
            data: {
                id: id,
                djxhid: searchFormRef.current.djxhid!,
                bmbh: searchFormRef.current.bmbh
            }
        });
    };

    // table选中
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // 删除
    const [delTrigger, delResult] = useDelCompensationMutation();
    useEffect(() => {
        if (delResult.isSuccess) {
            message.success(delResult.data.msg);
            // if (delResult.data.data.error_list && delResult.data.data.error_list.length > 0) {
            //     confirm({
            //         width: '50%',
            //         title: '删除数据失败',
            //         icon: <ExclamationCircleOutlined />,
            //         content: <>{delResult.data.data.error_list.map(error => <div>{error}</div>)}</>,
            //         okCancel: false,
            //     });
            // }
            listTrigger(searchFormRef.current);
        }
    }, [delResult])
    const toDelete = (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        if (ids.length === 0) {
            message.warning('请至少选择一项进行操作');
            return;
        }

        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '请谨慎操作，确定删除吗？',
            onOk() {
                delTrigger({ ids: ids });
            },
        });
    }

    return (
        <Spin
            spinning={
                listResult.isLoading
            }
            tip="正在进行耗时操作，请稍等"
        >
            <div className='address menu inner-page'>
                <div className='menu__title'>一次性补偿金</div>

                <div className='search'>
                    <Select
                        className="search__item"
                        placeholder="登记序号"
                        value={searchForm.djxhid}
                        onChange={handleDjxhidChange}
                        optionLabelProp="label"
                    >
                        {
                            registerListResult.data?.data.list.map(item => (
                                <Option value={item.djxhid} label={item.djxhid}>
                                    <div>{item.djxhid}</div>
                                    <div>{item.qymc}（{item.province_city_text}）</div>
                                </Option>
                            ))
                        }
                    </Select>
                    <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="选择部门筛选"
                        value={searchForm.bmbh}
                        onChange={handleDepartmentChange}
                    >
                        {departmentList.map(department => (
                            <Option key={department.bmbh} value={department.bmbh}>{department.bmmc}</Option>
                        ))}
                    </Select>
                    <DatePicker
                        className="search__item"
                        value={searchForm.period ? dayjs(searchForm.period) : null}
                        onChange={handleTaxPeriodChange}
                        placeholder='税务所属期筛选'
                        picker="month" />
                    <Dropdown.Button
                        trigger={['click', 'hover']}
                        className="search__last-button"
                        type="primary"
                        loading={
                            templateResult.isLoading
                        }
                        overlay={
                            <Menu
                                items={selectedRowKeys.length > 0 ? [
                                    {
                                        key: 'delete',
                                        label: (
                                            <div>批量删除</div>
                                        ),
                                        onClick: () => toDelete(),
                                    },
                                ] : [
                                    {
                                        key: 'download',
                                        label: (
                                            <div>下载模板</div>
                                        ),
                                        onClick: () => download(),
                                    },
                                    {
                                        key: 'import',
                                        label: (
                                            <div>导入薪酬</div>
                                        ),
                                        onClick: () => {
                                            if (inputRef.current) {
                                                inputRef.current.value = '';
                                                inputRef.current.click();
                                            }
                                        },
                                    },

                                ]}
                            />
                        }>
                        {selectedRowKeys.length > 0 ? `已选择${selectedRowKeys.length}项` : '更多操作'}
                    </Dropdown.Button>
                </div>
                <Table
                    dataSource={listData}
                    loading={listResult.isLoading}
                    rowKey={(record: TaxCompensationListData) => record?.id}
                    scroll={{ x: '100%' }}
                    rowSelection={
                        {
                            selectedRowKeys,
                            onChange: onSelectChange
                        }
                    }
                    pagination={{
                        position: ['bottomRight'],
                        size: "small",
                        total: listResult.data?.data.total ?? 0,
                        showTotal: total => `共 ${total} 项`,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange: onPageChange
                    }}>
                    <Column width={80} title="姓名" dataIndex="xm" key="xm" />
                    <Column width={180} title="证件号码" dataIndex="zzhm" key="zzhm" />
                    <Column width={120} title="收入额（元）" dataIndex="sre" key="sre" />
                    <Column width={120} title="免税收（元）" dataIndex="mssd" key="mssd" />
                    <Column width={200} title="准予扣除的捐赠额（元）" dataIndex="zykcjze" key="zykcjze" />
                    <Column width={130} title="减免税额（元）" dataIndex="jmse" key="jmse" />
                    <Column width={120} title="其他（元）" dataIndex="qt" key="qt" />
                    <Column width={120} title="状态" dataIndex="status_text" key="status_text" render={
                        (status: string, record: TaxCompensationListData | undefined) => (
                            <span style={{ color: record?.status_color_text }}>{status}</span>
                        )}
                    />
                    <Column width={120} title="备注" dataIndex="bz" key="bz" />
                    <Column width={120} title="反馈信息" dataIndex="remark" key="remark" />
                    <Column
                        width={130}
                        fixed={'right'}
                        title="操作"
                        key="action"
                        render={(_: any, record: TaxCompensationListData | undefined) => (
                            <Space>
                                <Dropdown.Button trigger={['click', 'hover']} type="primary" loading={false} overlay={
                                    <Menu
                                        items={record ? [
                                            {
                                                key: 'editDonations',
                                                disabled: record.is_fill_zykcjze === null,
                                                label: (
                                                    <div>准予扣除的捐赠额</div>
                                                ),
                                                onClick: () => toEditDonations(record.id),
                                            },
                                            {
                                                key: 'editReduction',
                                                disabled: record.is_fill_jmse === null,
                                                label: (
                                                    <div>减免收入明细编辑</div>
                                                ),
                                                onClick: () => toEditReduction(record.id),
                                            },
                                            {
                                                key: 'editDutyFree',
                                                disabled: record.is_fill_mssd === null,
                                                label: (
                                                    <div>免税收入明细编辑</div>
                                                ),
                                                onClick: () => toEditDutyFree(record.id),
                                            },
                                        ] : []}
                                    />
                                }>
                                    操作
                                </Dropdown.Button>
                            </Space>
                        )}
                    />
                </Table>
            </div>

            {/* 导入上传input */}
            {
                <input
                    ref={inputRef}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={upload}
                />
            }

            {/* 准予扣除的捐赠额弹窗 */}
            {
                donationsModalForm
                    ? <EditCompensationDonationsFormModal
                        data={donationsModalForm.data}
                        visible={donationsModalForm.isModalVisible}
                        onFinish={() => {
                            setDonationsModalForm({
                                ...donationsModalForm,
                                isModalVisible: false
                            })
                            listTrigger(searchFormRef.current);
                        }}
                        onCancel={() => {
                            setDonationsModalForm({
                                ...donationsModalForm,
                                isModalVisible: false
                            })
                        }}
                    /> : <></>
            }

            {/* 减免收入弹窗 */}
            {
                reductionModalForm
                    ? <EditCompensationReductionFormModal
                        data={reductionModalForm.data}
                        visible={reductionModalForm.isModalVisible}
                        onFinish={() => {
                            setReductionModalForm({
                                ...reductionModalForm,
                                isModalVisible: false
                            })
                            listTrigger(searchFormRef.current);
                        }}
                        onCancel={() => {
                            setReductionModalForm({
                                ...reductionModalForm,
                                isModalVisible: false
                            })
                        }}
                    /> : <></>
            }

            {/* 免税收入弹窗 */}
            {
                dutyFreeModalForm
                    ? <EditCompensationDutyFreeFormModal
                        data={dutyFreeModalForm.data}
                        visible={dutyFreeModalForm.isModalVisible}
                        onFinish={() => {
                            setDutyFreeModalForm({
                                ...dutyFreeModalForm,
                                isModalVisible: false
                            })
                            listTrigger(searchFormRef.current);
                        }}
                        onCancel={() => {
                            setDutyFreeModalForm({
                                ...dutyFreeModalForm,
                                isModalVisible: false
                            })
                        }}
                    /> : <></>
            }
        </Spin>
    );
}

export default TaxCompensationListPage;