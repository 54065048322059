// antd
import { Result } from "antd";

// assets
import waitingImg from '@assets/waiting.svg';

export function WaitingPage() {
    return (
        <Result
            icon={<img style={{ width: '600px' }} src={waitingImg} alt="等待操作" />}
            title="开始"
            subTitle="在左侧菜单选择一项进行操作"
        />
    );
}