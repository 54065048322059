import { createSlice } from '@reduxjs/toolkit';

interface ITours {
    login: boolean,
    registerWizard: boolean,
    autoAccounting: boolean,
    actualSalary: boolean,
    splitSalary: boolean,
    payout: boolean,
    tax: boolean,
    task: boolean,
    taskList: boolean,
    taskAppointed: boolean,
    addContractTemplate: boolean,
    addContractSet: boolean,
    quit: boolean,
}

interface CommonState {
    timestamp: Record<string, number>;
    tours: ITours
}

const initialState: CommonState = {
    timestamp: {},
    tours: {
        login: true,
        registerWizard: true,
        autoAccounting: true,
        actualSalary: true,
        splitSalary: true,
        payout: true,
        tax: true,
        task: true,
        taskList: true,
        taskAppointed: true,
        addContractTemplate: true,
        addContractSet: true,
        quit: true,
    }
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setTimestamp: (state: CommonState, action) => {
            const [value, key] = action.payload;
            console.log(action.payload);

            state.timestamp = {
                ...state.timestamp,
                [key]: value
            };
        },
        setTours: (state: CommonState, action) => {
            const [value, key] = action.payload;
            console.log(action.payload);

            state.tours = {
                ...state.tours,
                [key]: value
            };
        }
    },
    extraReducers: {},
});

export const { setTimestamp, setTours } = commonSlice.actions;