import React, { useState, useEffect } from 'react';

// redux
import { useAppDispatch } from '../../store/hooks';
import { clearToken } from '../../store/user';

// components
import PageLayout from '../../layout/pageLayout';
import { CustomImage } from '../../components/customImage/customImage';

// router
import { Link, Outlet, useNavigate } from "react-router-dom";

// icons
import aboutIcon from '@assets/icons/mine/about.svg';
import accountIcon from '@assets/icons/mine/account.svg';
import bankIcon from '@assets/icons/mine/bank.svg';
import commonIcon from '@assets/icons/mine/common.svg';
import helpIcon from '@assets/icons/mine/help.svg';
import logoutIcon from '@assets/icons/mine/logout.svg';
import starIcon from '@assets/icons/mine/star.svg';

// style
import './mine.scss';

// apis
import { useGetUserInfoMutation } from '../../store/apis/user';

// interface
import { MenuClickEventHandler } from 'rc-menu/lib/interface';

// antd
import { Menu, MenuProps, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CompanyApplyData } from '../../interfaces/user/user';

// 确认弹框
const { confirm } = Modal;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

function Mine() {
    const left = <MineList></MineList>;
    const right = <Outlet />;

    return (
        <PageLayout leftComponent={left} rightComponent={right} flex={[3, 5]}></PageLayout>
    );
}

function MineList() {
    // redux方法
    const dispatch = useAppDispatch();
    // 路由
    const navigate = useNavigate();

    // 数据
    const [staffData, setStaffData] = useState<CompanyApplyData>();

    // 请求
    const [userInfoTrigger, userInfoResult] = useGetUserInfoMutation();
    useEffect(() => {
        if (userInfoResult.isSuccess) {
            setStaffData(userInfoResult.data.data.company);
        }
    }, [userInfoResult, setStaffData]);

    // 初始化
    useEffect(() => {
        userInfoTrigger();
    }, []);


    // const items = [
    //     {
    //         title: '账号信息',
    //         img: accountIcon,
    //         path: '/mine/account',
    //         display: true,
    //     }, {
    //         title: '银行信息',
    //         img: bankIcon,
    //         path: '/mine/bank',
    //         display: false,
    //     }, {
    //         title: '我的收藏',
    //         img: starIcon,
    //         path: '/mine/star',
    //         display: false,
    //     }, {
    //         title: '通用',
    //         img: commonIcon,
    //         path: '/mine/common',
    //         display: true,
    //     }, {
    //         title: '帮助',
    //         img: helpIcon,
    //         path: '/mine/help',
    //         display: false,
    //     }, {
    //         title: '关于我们',
    //         img: aboutIcon,
    //         path: '/mine/about',
    //         display: true,
    //     }, {
    //         title: '退出登录',
    //         img: logoutIcon,
    //         display: true,
    //         function: () => {
    //             confirm({
    //                 title: '提示',
    //                 icon: <ExclamationCircleOutlined />,
    //                 content: '确定要退出登录吗？',
    //                 onOk() {
    //                     dispatch(clearToken());
    //                     navigate("/login", { replace: true });
    //                 },
    //             });
    //         }
    //     }
    // ];


    // 子菜单根节点数组
    const rootSubmenuKeys = ['account', 'common'];
    const [openKeys, setOpenKeys] = useState(['']);

    const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const items: MenuItem[] = [
        getItem('账号信息', 'account', <img width={20} src={accountIcon} />, [
            getItem('更换手机号码', 'changePhone'),
            getItem('修改密码', 'changePassword'),
            getItem('转移超管', 'transferAdmin'),
            // getItem('账号注销', 'logoff'),
        ]),
        getItem('通用', 'common', <img width={20} src={commonIcon} />, [
            getItem('首页快捷方式', 'lnk'),
        ]),
        getItem('建议反馈', 'feedback', <img width={20} src={helpIcon} />),
        getItem('关于我们', 'about', <img width={20} src={aboutIcon} />),
        getItem('退出登录', 'logout', <img width={20} src={logoutIcon} />),
    ];

    const onMenuClick: MenuClickEventHandler = ({ key, keyPath, domEvent }) => {
        switch (key) {
            case 'changePhone':
                navigate('/mine/changePhone');
                break;
            case 'changePassword':
                navigate('/mine/changePassword');
                break;
            case 'transferAdmin':
                navigate('/mine/transferAdmin');
                break;
            case 'lnk':
                navigate('/mine/shortcut');
                break;
            case 'about':
                navigate('/mine/about');
                break;
            case 'feedback':
                navigate('/mine/feedback');
                break;

            case 'logout':
                confirm({
                    title: '提示',
                    icon: <ExclamationCircleOutlined />,
                    content: '确定要退出登录吗？',
                    onOk() {
                        dispatch(clearToken());
                        navigate("/login", { replace: true });
                    },
                });
                break;

            default:
                break;
        }
    };

    return (
        <div className='menu mine'>
            <div className='menu__box mine__user'>
                <div>
                    <div className='mine__user__name'>{staffData?.name}</div>
                    {/* <div className='mine__user__field'>{staffData?.company?.name}</div>
                    <div className='mine__user__field'>{staffData?.dept_text ?? '无部门'}</div>
                    <div className='mine__user__field'>{staffData?.post_text ?? '无岗位'}</div> */}
                </div>
                <div>
                    <CustomImage
                        style={{ borderRadius: 10 }}
                        width={90}
                        // src={staffData?.avatar_link}
                    />
                </div>
            </div>
            <Menu
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                items={items}
                className="mine__list"
                onClick={onMenuClick}
            />

            {/* <div className='menu__box mine__list'>
                {
                    items.filter(item => item.display).map(item => (
                        item.path ?
                            <Link to={item.path} className="mine__link">
                                <img className='mine__link__img' width={20} src={item.img} alt={item.title} />
                                <div className='mine__link__text'>{item.title}</div>
                            </Link>
                            : <a className="mine__link" onClick={item.function}>
                                <img className='mine__link__img' width={20} src={item.img} alt={item.title} />
                                <div className='mine__link__text'>{item.title}</div>
                            </a>
                    ))
                }
            </div> */}
        </div>
    );
}

export default Mine;